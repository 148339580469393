import { type ReactNode, useId, memo } from 'react';
import _ from 'lodash';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { AnimatePresence, motion } from 'motion/react';
import { useNavigate } from 'react-router-dom';
import {
    Person as PersonIcon,
    ApproximateEquals as CorridorsIcon,
    DotsThree as DotsThreeIcon,
    Exclude as ComparisonIcon,
} from '@phosphor-icons/react';

import { isFiniteNumber } from '@common';
import { ActionButton, colors, typography } from '@common/ui';
import { useToggle } from '../../utils/hooks/useToggle';
import { useGlobalStore } from '../../state/globalStore';
import { Layout } from '../deprecated_ui/icons/Layout';
import Tag from '../deprecated_ui/Tag';

import * as css from './ModuleBase.kiosk.css';

type AvailableIcons = 'comparison' | 'skeleton' | 'dots' | 'corridors';

export type ModuleBaseButton = {
    label: string;
    onClick: () => void;
    icon?: AvailableIcons;
    isActive?: boolean;
};

type ModuleBaseProps = {
    children: ReactNode;
    title?: string;
    subtitle?: string;
    buttons?: ModuleBaseButton[];
    isStatic?: boolean;
    isPending?: boolean;
    key?: string;
};

const getIcon = (iconName: AvailableIcons, color: string) => {
    switch (iconName) {
        case 'comparison': {
            return <ComparisonIcon color={color} size={16} />;
        }
        case 'skeleton': {
            return <PersonIcon color={color} size={16} />;
        }
        case 'dots': {
            return <DotsThreeIcon color={color} size={16} />;
        }
        case 'corridors': {
            return <CorridorsIcon color={color} size={18} />;
        }
    }
};

function ModuleBase({ key, children, title, subtitle, buttons, isStatic, isPending }: ModuleBaseProps) {
    const navigate = useNavigate();
    const id = useId();
    const [isOpen, toggle] = useToggle(false);
    const shouldOpen = (isStatic || isOpen) && !isPending;

    const [comparisonSwingID] = useGlobalStore((state) => [state.comparisonSwingID]);

    const showComparisonButton = isFiniteNumber(comparisonSwingID);

    const isInteractive = !isPending && !isStatic;

    return (
        <div
            key={key}
            className={css.frame({ isInteractive })}
            style={assignInlineVars({
                [css.borderColor]: !isInteractive ? 'transparent' : colors.bluegray[200],
            })}
        >
            <div className={css.top} role="button" onClick={() => isInteractive && toggle()}>
                <div className={css.topLeft}>
                    <div className={css.header}>
                        <div className={css.heading}>
                            <h3
                                className={typography({
                                    variant: 'h3',
                                })}
                            >
                                {title ?? 'My Unnamed Module'}
                            </h3>
                        </div>
                        {subtitle && (
                            <p
                                className={typography({
                                    variant: 'h4',
                                })}
                            >
                                {subtitle}
                            </p>
                        )}
                    </div>

                    <div className={css.topButtons}>
                        {!isPending &&
                            _.isArray(buttons) &&
                            _.map(buttons, (button) => {
                                if (button.label === 'Comparison' && !showComparisonButton) return null;

                                return (
                                    <button
                                        key={button.label}
                                        className={css.topButton({
                                            selected: !!button?.isActive,
                                            variant: 'primary',
                                        })}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            button.onClick();
                                        }}
                                        disabled={!shouldOpen}
                                    >
                                        {button?.icon &&
                                            getIcon(
                                                button?.icon,
                                                button?.isActive ? colors.blue[600] : colors.bluegray[600],
                                            )}
                                        <p
                                            className={typography({
                                                variant: 'h4',
                                            })}
                                        >
                                            {button.label}
                                        </p>
                                    </button>
                                );
                            })}
                    </div>
                </div>

                {isStatic ? (
                    <button
                        className={css.topButton({ selected: false, variant: 'secondary' })}
                        onClick={() => {
                            navigate('/kiosk/swing-foundations-editor');
                        }}
                    >
                        <Layout width={16} />
                        <p
                            className={typography({
                                variant: 'h4',
                            })}
                        >
                            Parameters
                        </p>
                    </button>
                ) : (
                    <div className={css.actions}>
                        {isPending && <Tag value="Full Analysis Pending" variant="pending" />}
                        <ActionButton buttonSize="small" icon={isOpen ? 'minus' : 'plus'} iconSize="large" />
                    </div>
                )}
            </div>

            <AnimatePresence initial={false}>
                {shouldOpen && (
                    <motion.div
                        key={id}
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        style={{ overflow: 'hidden' }}
                        variants={{
                            open: { height: 'auto' },
                            collapsed: { height: 0 },
                        }}
                        transition={{
                            duration: 0.3,
                            type: 'tween',
                        }}
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}

export default memo(ModuleBase);
