import { Suspense, useLayoutEffect } from 'react';
import _ from 'lodash';
import { Canvas, useThree } from '@react-three/fiber';
import { Flex, Box } from '@react-three/flex';
import { Plane, Text, OrthographicCamera } from '@react-three/drei';
import useMeasure from 'react-use-measure';

import { useVideoTexture } from '../../utils/hooks/useVideoTexture';
import { useGlobalStore } from '../../state/globalStore';
import { cameraAngles, type CameraAngles, SimplifiedCameraConfig } from '../../utils/types/camera';
import { localize, type IVideo } from '@common';
import { fallbackCameraConfig } from '../../utils/fallbacks';
import { typography } from '../../styles/typography.css';
import { getStatus } from '../../utils/getStatus';
import ElvaLogoName from '../deprecated_ui/Logo/ElvaLogoName';

import * as css from './VideoReplay.floor.css';

type SceneProps = {
    videos: IVideo[];
    cameraConfig: SimplifiedCameraConfig;
    scale?: number;
    cameraAngle?: CameraAngles;
    onEnded: () => void;
};

function Scene({
    videos,
    scale = 1,
    cameraAngle = 'face_on',
    cameraConfig,
    onEnded = () => void undefined,
}: SceneProps) {
    const { height: videoHeight, width: videoWidth } = cameraConfig.intrinsics[cameraAngle] ?? {};

    const videoTextureUrl = _.find(videos, (video) => video.name === cameraAngle)?.url ?? '';

    const videoTexture = useVideoTexture(videoTextureUrl as string, {
        start: false,
        loop: false,
    });

    useLayoutEffect(() => {
        if (videoTexture.source?.data) {
            videoTexture.source.data.play();
            videoTexture.source.data.onended = () => {
                onEnded();
            };
        }
    }, [videoTexture.source, onEnded]);

    if (!videoTexture?.source?.dataReady) {
        return null;
    }

    return (
        <group scale={scale}>
            <Plane args={[videoWidth, videoHeight, 1, 1]} position={[0, 0, 0]}>
                <Text
                    color="#fff"
                    anchorX="left"
                    anchorY="top"
                    fontSize={64}
                    position={[-videoWidth / 2 + 64, videoHeight / 2 - 64, 0]}
                >
                    {localize(`camera_angle.${cameraAngle}`)}
                </Text>
                <meshBasicMaterial map={videoTexture} />
            </Plane>
        </group>
    );
}

type RendererProps = {
    videos: IVideo[];
    scale: number;
    cameraConfig: SimplifiedCameraConfig;
    onEnded: () => void;
};

function Renderer({ videos, scale = 1, cameraConfig, onEnded }: RendererProps) {
    const { height: canvasHeight, width: canvasWidth } = useThree((state) => state.viewport);

    return (
        <Flex
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="center"
            alignItems="flex-start"
            height={canvasHeight}
            width={canvasWidth}
            position={[-canvasWidth / 2, canvasHeight / 2, -1000]}
            plane="xy"
            flexShrink={0}
            flexGrow={0}
        >
            {_.map(cameraAngles, (cameraAngle, index) => (
                <Box
                    height={canvasHeight / 2}
                    width={canvasWidth / 2}
                    centerAnchor
                    renderOrder={1}
                    key={index}
                    flexGrow={1}
                    flexShrink={0}
                >
                    <Scene
                        key={cameraAngle}
                        videos={videos}
                        cameraAngle={cameraAngle}
                        cameraConfig={cameraConfig}
                        scale={scale}
                        onEnded={onEnded}
                    />
                </Box>
            ))}
        </Flex>
    );
}

const VIDEO_DIMENSIONS = {
    width: 510,
    height: 428,
    sourceWidth: 2464,
    sourceHeight: 2064,
};

export function VideoReplay({
    videos,
    cameraConfig,
    onReplayEnded,
}: {
    videos: IVideo[];
    onReplayEnded: () => void;
    cameraConfig?: SimplifiedCameraConfig;
}) {
    const lastBeastStatus = useGlobalStore((state) => state.lastBeastStatus);
    const status = lastBeastStatus === 'BALL_STEADY' ? 'ready' : 'waiting';

    const [ref, bounds] = useMeasure();
    const measured = bounds.width + bounds.height > 0;
    const videosPerRow = 2;
    const totalHeightNeeded = VIDEO_DIMENSIONS.sourceHeight * videosPerRow;
    const scale = bounds.height / totalHeightNeeded;

    return (
        <Suspense fallback={null}>
            {Array.isArray(videos) && (
                <div className={css.fullscreenLayout}>
                    <Canvas
                        ref={ref}
                        flat
                        dpr={[1, 2]}
                        shadows={false}
                        style={{
                            width: '66.666vw',
                            height: '100vh',
                        }}
                    >
                        <OrthographicCamera makeDefault far={100000} near={0.00001} zoom={1}>
                            {measured && (
                                <Renderer
                                    videos={videos}
                                    scale={scale}
                                    cameraConfig={cameraConfig ?? fallbackCameraConfig}
                                    onEnded={() => {
                                        onReplayEnded();
                                    }}
                                />
                            )}
                        </OrthographicCamera>
                    </Canvas>
                    <div className={css.sideBoxes}>
                        <div className={css.statusBox}>
                            <div className={css.statusDot({ status })}></div>
                            <p
                                className={typography({
                                    size: 'floorH2',
                                    color: 'floorLight',
                                    weight: 'bold',
                                })}
                            >
                                {getStatus(lastBeastStatus)}
                            </p>
                        </div>
                        <div className={css.logoBox}>
                            <ElvaLogoName width={300} textColor="white" />
                        </div>
                    </div>
                </div>
            )}
        </Suspense>
    );
}
