export const getStatus = (status: string) => {
    switch (status) {
        case 'BALL_STEADY':
            return 'Ready';
        case 'IDLE':
            return 'Idle';
        case 'SWING_ANALYSIS_FAILED':
            return 'Analysis failed';
        case 'ANALYZING_SWING':
            return 'Processing';
        case 'FINALIZING':
            return 'Finalizing processing';
        default:
            return 'Waiting for ball';
    }
};
