import { createContext, useCallback, useEffect, useRef } from 'react';
import { GlobalContextProviderProps, GlobalContextType } from './GlobalContext.types';
import { useSession } from '../../hooks/useSession';
import { useGlobalStore } from '../../../state/globalStore';
import { useUserSettingsStore } from '../../../state/userSettingsStore';
import { useBoothSession } from '../../hooks/useBoothSession';
import { SupabaseClient } from '@supabase/supabase-js';
import {
    Club,
    Swing,
    fetchAllUserActivities,
    fetchAllUserFavoriteSwings as _fetchAllUserFavoriteSwings,
    createNewActivity,
    Tag,
    updateActivityClub,
    updateActivityTags,
} from '@common';

// Create GlobalContext with the correct type
export const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

export const GlobalContextProvider = ({
    children,
    supabase,
}: GlobalContextProviderProps & { supabase: SupabaseClient }) => {
    const { user } = useSession();
    const { boothSessionDetails } = useBoothSession();
    const { activityId } = useUserSettingsStore();
    const lastActivityId = useRef(activityId);

    const {
        activityTags,
        activityClub,
        actions: { setActivityClub, setActivityId, setActivityTags },
    } = useUserSettingsStore((state) => state);

    const actions = useGlobalStore((state) => state.actions);
    const swings = useGlobalStore((state) => state.swings);

    const handleFetchingUserActivities = useCallback(async () => {
        if (user?.id) {
            const data = await fetchAllUserActivities(supabase, user?.id);
            if (data) {
                useGlobalStore.setState({
                    loadedActivities: data,
                });
            }
        }
    }, [user]);

    // Used to change the club
    const selectClub = async (club: Club) => {
        if (!user?.id || !boothSessionDetails?.id) {
            return;
        }

        // If current activity has no swings, re-use the activity, don't create a new one and leave the other one empty
        if (swings?.length <= 0) {
            if (activityId) {
                updateActivityClub(supabase, club, activityId);
            }
        } else {
            const activityId = await createNewActivity(supabase, user?.id, boothSessionDetails?.id, club, activityTags);

            if (activityId) {
                setActivityId(activityId);
                actions.setViewingActivityID(activityId);
            }
        }

        setActivityClub(club);
    };

    const changeTags = async (tags: Tag[]) => {
        if (user && boothSessionDetails?.id) {
            // If current activity has no swings, re-use the activity, don't create a new one and leave the other one empty
            if (swings?.length <= 0) {
                if (activityId) {
                    updateActivityTags(supabase, tags, activityId);
                }
            } else {
                const activityId = await createNewActivity(
                    supabase,
                    user.id,
                    boothSessionDetails?.id,
                    activityClub,
                    tags,
                );

                if (activityId) {
                    setActivityId(activityId);
                    actions.setViewingActivityID(activityId);
                }
            }

            setActivityTags(tags);
        }
    };

    const selectSwing = (swing: Swing) => {
        // Check if it's ready to be viewed
        if (swing.quickAnalysis || swing.fullAnalysis) {
            actions.setActiveSwingID(swing?.id);
        }
    };

    useEffect(() => {
        if (!user) {
            return;
        }

        const fetchFavoriteSwings = async () => {
            try {
                const swings = await _fetchAllUserFavoriteSwings(supabase, user?.id);
                actions.setAllUserFavoriteSwings(swings);
            } catch (e) {
                // no-op
            }
        };

        const fetchClubs = async () => {
            const { data: clubs, error } = await supabase.from('club_types').select('type_name, clubs(*)');

            if (error) {
                console.error('Failed to fetch clubs:', error);
                return;
            }

            useGlobalStore.setState({ clubs });
        };

        fetchClubs();
        fetchFavoriteSwings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions, user]);

    /**
     * When activity id is changed, clear swings list and re-fetch activities
     */
    useEffect(() => {
        if (lastActivityId.current !== activityId) {
            // Clear activity
            useGlobalStore.setState({
                activeSwingID: -1,
                swings: [],
                loadedActivities: [],
            });
            // Re-fetch activities to udate activities list
            // TODO: Async ...
            handleFetchingUserActivities();
        }
    }, [activityId, handleFetchingUserActivities]);

    return (
        <GlobalContext.Provider
            value={{
                selectClub,
                changeTags,
                selectSwing,
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};
