import _ from 'lodash';
import { isFiniteNumber, Nil, TagFromAPI } from '@common';
import { HttpException } from './http-exception';
import { SupabaseService } from './services/supabase.service';

export class TagsEndpointPlaceholder {
    constructor(private readonly _supabase: SupabaseService) {}

    /**
     * Endpoint for getting all tags owned by a user
     * and optionally belongs to a specific activity.
     */
    async getTags(userID: string, activityID: number | Nil = null): Promise<TagFromAPI[]> {
        let query = this._supabase
            .from('activity_tags')
            .select('*, activity_link(activity_id)')
            .is('deleted_at', null)
            .eq('user_id', userID);

        if (isFiniteNumber(activityID)) query = query.eq('activity_id', activityID);

        if (!_.isFinite(activityID) && !userID)
            throw new HttpException('Must provide either an activity_id or user_id', 400);
        const { data, error } = await query;

        if (error) throw new HttpException(error.message, 500);

        const tags = _.map(
            data,
            (t) =>
                ({
                    id: t.id,
                    name: t.name,
                    user_id: t.user_id,
                    is_used: t.activity_link.length > 0,
                }) as TagFromAPI,
        );

        return tags;
    }

    /**
     * Endpoint for getting a specific tag owned by a user.
     */
    async getTag(id: number, userID: string): Promise<TagFromAPI> {
        const { data, error } = await this._supabase
            .from('activity_tags')
            .select('*, activity_link(activity_id)')
            .is('deleted_at', null)
            .eq('id', id)
            .eq('user_id', userID);

        if (error) throw new HttpException(error.message, 500);

        if (_.isEmpty(data)) throw new HttpException('Tag not found', 404);

        const tag = data[0];
        return {
            id: tag.id,
            name: tag.name,
            user_id: tag.user_id as string,
            is_used: tag.activity_link.length > 0,
        };
    }

    /**
     * Endpoint for creating new tags
     */
    async createTags(userID: string, tags: TagFromAPI[] | TagFromAPI): Promise<TagFromAPI[] | TagFromAPI> {
        const isArray = Array.isArray(tags);
        const tagsArray = isArray ? tags : [tags];

        const activityTags = _.map(tagsArray, (t) => ({
            name: t.name,
            user_id: userID,
        }));
        const { error } = await this._supabase.from('activity_tags').upsert(activityTags);

        if (error) throw new HttpException(error.message, 500);

        let tagsToReturn = await this.getTags(userID);
        tagsToReturn = _.filter(tagsToReturn, (t) => _.some(tagsArray, (t2) => t2.id === t.id));
        if (isArray) return tagsToReturn;
        return tagsToReturn[0];
    }

    /**
     * Endpoint for updating tags
     */
    async updateTag(id: number, userID: string, tag: TagFromAPI): Promise<TagFromAPI> {
        const { error } = await this._supabase
            .from('activity_tags')
            .update({ name: tag.name })
            .is('deleted_at', null)
            .eq('id', id)
            .eq('user_id', userID);

        if (error) throw new HttpException(error.message, 500);

        return this.getTag(id, userID);
    }

    /**
     * Endpoint for getting ids of all the tags owned by a user
     * and optionally belongs to a specific activity.
     */
    async getTagIDs(userID: string, activityID: number | Nil = null): Promise<number[]> {
        let query = this._supabase
            .from('activity_tag_link')
            .select('tag_id, activity_tags(deleted)')
            .eq('activity_tags.deleted', false)
            .eq('user_id', userID);

        if (isFiniteNumber(activityID)) query = query.eq('activity_id', activityID);

        const { data, error } = await query;

        if (error) throw new HttpException(error.message, 500);

        return _.map(data, (t) => t.tag_id);
    }

    /**
     * Endpoint to set which tags are associated with a given activity
     */
    async setActivityTagIDs(userID: string, tagIDs: number[], activityID: number | Nil = null): Promise<number[]> {
        if (!isFiniteNumber(activityID)) throw new HttpException('Must provide an activity_id', 400);

        const { error: activityError } = await this._supabase
            .from('activities')
            .select('id')
            .eq('deleted', false)
            .eq('user_id', userID)
            .eq('id', activityID);

        if (activityError) throw new HttpException(activityError.message, 404);

        const currentTagIDs = await this.getTagIDs(userID, activityID);
        const tagIDsToInsert = _.difference(tagIDs, currentTagIDs);
        const tagIDsToDelete = _.difference(currentTagIDs, tagIDs);

        const { error: deleteError } = await this._supabase
            .from('activity_tag_link')
            .delete()
            .eq('user_id', userID)
            .eq('activity_id', activityID)
            .in('tag_id', tagIDsToDelete);

        if (deleteError) throw new HttpException(deleteError.message, 500);

        const { error } = await this._supabase.from('activity_tag_link').upsert(
            _.map(tagIDsToInsert, (tagID) => ({
                user_id: userID,
                activity_id: activityID,
                tag_id: tagID,
            })),
        );

        if (error) throw new HttpException(error.message, 500);

        return tagIDs;
    }

    async deleteTag(id: number, userID: string): Promise<void> {
        const { error } = await this._supabase
            .from('activity_tags')
            .update({ deleted: true })
            .eq('id', id)
            .eq('user_id', userID);

        if (error) throw new HttpException(error.message, 500);
    }

    /**
     * Endpoint for adding tags to a specific activity without removing existing tags
     */
    async addActivityTagIDs(userID: string, tagIDs: number[], activityID: number | Nil = null): Promise<number[]> {
        if (!isFiniteNumber(activityID)) throw new HttpException('Must provide an activity_id', 400);

        const { error: activityError } = await this._supabase
            .from('activities')
            .select('id')
            .eq('deleted', false)
            .eq('user_id', userID)
            .eq('id', activityID);

        if (activityError) throw new HttpException(activityError.message, 404);

        const { error } = await this._supabase.from('activity_tag_link').upsert(
            _.map(tagIDs, (tagID) => ({
                user_id: userID,
                activity_id: activityID,
                tag_id: tagID,
            })),
        );

        if (error) throw new HttpException(error.message, 500);

        return tagIDs;
    }

    /**
     * Endpoint for removing tags from a specific activity
     */
    async removeActivityTagIDs(userID: string, tagIDs: number[], activityID: number | Nil = null): Promise<number[]> {
        if (!isFiniteNumber(activityID)) throw new HttpException('Must provide an activity_id', 400);

        const { error: activityError } = await this._supabase
            .from('activities')
            .select('id')
            .eq('deleted', false)
            .eq('user_id', userID)
            .eq('id', activityID);

        if (activityError) throw new HttpException(activityError.message, 404);

        const { error } = await this._supabase
            .from('activity_tag_link')
            .delete()
            .eq('user_id', userID)
            .eq('activity_id', activityID)
            .in('tag_id', tagIDs);

        if (error) throw new HttpException(error.message, 500);

        return tagIDs;
    }
}
