import { Fragment } from 'react';
import _ from 'lodash';
import { Label, ReferenceLine, XAxis } from 'recharts';

import type { Segmentation } from '@common';
import { constants } from '@common';

const style = {
    color: '#94A3B8',
    WebkitFontSmoothing: 'antialiased',
    fontSize: 10,
    fontWeight: 200,
    letterSpacing: '0.05em',
    fontFamily: 'Inter',
    backgroundColor: 'white',
};

export const renderXAxis = ({
    isQuickAnalysis,
    segmentation,
    axisKey,
}: {
    isQuickAnalysis: boolean;
    segmentation: Segmentation;
    axisKey: string;
}) => {
    const frameNumbers = _.values(segmentation);
    const domain = [_.first(frameNumbers) as number, _.last(frameNumbers) as number];

    // TODO: Perhaps calculate a minimum ms distance a label has to have in order to be 'allowed'
    const allowedBottomLabels = ['P1', 'P2', 'P3', 'P4', 'P7', 'P9', 'P10'];

    // Filter out null positions
    const validPositions = _.entries(segmentation)
        .filter(([_, value]) => value !== null)
        .map(([key, value]) => ({ label: key, value: value as number }));

    const referenceLines = _.map(validPositions, ({ label, value }, index) => {
        const visible = _.includes(allowedBottomLabels, label);
        const frameDifference = value - segmentation['P7'];
        const milliseconds = Math.round((frameDifference / constants.CAMERA_FPS) * 1000);
        const sign = milliseconds >= 1 ? '+' : '';

        return (
            <ReferenceLine key={label} x={value} strokeDasharray="3 3" yAxisId={axisKey} stroke={style.color}>
                <Label value={label} offset={16} position="top" style={style} />
                <Label
                    visibility={visible ? 'visible' : 'hidden'}
                    value={`${sign}${milliseconds}ms`}
                    offset={16}
                    position="bottom"
                    style={style}
                />
            </ReferenceLine>
        );
    });

    return (
        <Fragment>
            {referenceLines}
            {isQuickAnalysis && (
                <XAxis
                    visibility="hidden"
                    dataKey="frame"
                    height={0}
                    scale="time"
                    type="number"
                    domain={domain}
                    ticks={frameNumbers}
                    tick={false}
                />
            )}
        </Fragment>
    );
};
