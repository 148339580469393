export function Layout({ width = 20, height }: { width?: number; height?: number }) {
    const w = width;
    const h = height ?? width;
    return (
        <svg width={w} height={h} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.25 0.75H1.75C1.35218 0.75 0.970644 0.908035 0.68934 1.18934C0.408035 1.47064 0.25 1.85218 0.25 2.25V15.75C0.25 16.1478 0.408035 16.5294 0.68934 16.8107C0.970644 17.092 1.35218 17.25 1.75 17.25H18.25C18.6478 17.25 19.0294 17.092 19.3107 16.8107C19.592 16.5294 19.75 16.1478 19.75 15.75V2.25C19.75 1.85218 19.592 1.47064 19.3107 1.18934C19.0294 0.908035 18.6478 0.75 18.25 0.75ZM1.75 2.25H18.25V6H1.75V2.25ZM18.25 15.75H8.5V7.5H18.25V15.75Z"
                fill="#3B82F6"
            />
        </svg>
    );
}
