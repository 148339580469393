import _ from 'lodash';

import UIComponent from '../../UIComponent';
import { ImplementationOf } from '../../UIComponent.types';
import { zipGridStyles } from './Grid.utils';

import * as css from './Grid.css';

export const Grid: ImplementationOf<'grid'> = ({ node: { id }, children }) => {
    const zipped = zipGridStyles(children);
    return (
        <div key={id} className={css.grid}>
            {_.map(zipped, ({ item, style }) => (
                <div key={item.currentNode.id} style={style}>
                    <UIComponent {...item} />
                </div>
            ))}
        </div>
    );
};
