export function ArrowClockwise({
    width = 16,
    height,
    color = '#3B82F6',
}: {
    width?: number;
    height?: number;
    color?: string;
}) {
    const w = width;
    const h = height ?? width;

    return (
        <svg width={w} height={h} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15 3.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.353-.854l1.062-1.063-.66-.603-.015-.015a5 5 0 1 0-.104 7.174.502.502 0 0 1 .8.568.502.502 0 0 1-.113.159A5.965 5.965 0 0 1 8 13.999h-.082a6 6 0 1 1 4.317-10.25l.683.625 1.229-1.23a.5.5 0 0 1 .853.355Z"
                fill={color}
            />
        </svg>
    );
}
