import _ from 'lodash';

import type { UIGraphLine, Segmentation, Analysis, Nil } from '@common';
import { ALL_AXES } from '@common';
import { isPropsForType, type UIComponentProps } from '../../UIComponent.types';
import type { GraphExtras, LinesPerUnit, TransitionPoint } from './Graph.types';
import { inferGraphLineName } from './GraphLine';

export function getMeasurementByName(analysis: Analysis, measurementName: string) {
    return analysis.data.analysis?.measurements.find((m) => m.measurement_name === measurementName);
}

export function getSegmentation(analysis: Analysis): Segmentation | null {
    return analysis.data.analysis?.segmentation ?? null;
}

export function parseAndGroupLines(children: UIComponentProps<UIGraphLine>[]): LinesPerUnit {
    return _(children)
        .filter((props) => isPropsForType<UIGraphLine>(props, 'graph_line'))
        .filter(({ currentNode: { time_series } }) => !!time_series?.unit)
        .map(({ currentNode, metadataOverride }) => ({
            ...currentNode,
            name: { value: inferGraphLineName(currentNode) },
            metadata: { ...currentNode.metadata, ...metadataOverride },
        }))
        .groupBy(({ time_series }) => time_series.unit)
        .value();
}

export function findNearestTransitionToFrame(
    transitions: Record<string, TransitionPoint[]>,
    targetFrame: number,
): Record<string, TransitionPoint> {
    // Initialize result object
    const result: Record<string, TransitionPoint> = {};

    // Process each graph line
    for (const [key, points] of Object.entries(transitions)) {
        // Point frames can't be >targetFrame
        const validPoints = points.filter((point) => point.frame <= targetFrame);

        if (validPoints.length === 0) continue;

        // Find the point with the closest frame to targetFrame
        const closestPoint = validPoints.reduce((closest, current) => {
            const currentDiff = Math.abs(current.frame - targetFrame);
            const closestDiff = Math.abs(closest.frame - targetFrame);
            return currentDiff < closestDiff ? current : closest;
        }, points[0]);

        // Add to result
        result[key] = closestPoint;
    }

    return result;
}

export function graphDataFromLines(
    linesPerUnit: LinesPerUnit,
    analysis: Analysis | Nil,
    segmentation: Segmentation | Nil,
    extras: GraphExtras | Nil,
): {
    dataPoints: Array<Record<string, number | null>>;
    isQuickAnalysis: boolean;
    apex: Record<string, { frame: number; value: number }>;
    transition: Record<string, TransitionPoint>;
} | null {
    if (!analysis || !segmentation) {
        return {
            dataPoints: [],
            isQuickAnalysis: false,
            apex: {},
            transition: {},
        };
    }

    const lines = _.flatMap(linesPerUnit);

    // Validate and get data length
    const dataLength = lines.reduce((length: number | null, line) => {
        if (line.time_series?.id) {
            const axis = line?.axis || 'x';
            const measurement = getMeasurementByName(analysis, line.time_series.id);
            const axisIndex = ALL_AXES.indexOf(axis as (typeof ALL_AXES)[number]);

            if (axisIndex !== -1 && measurement?.value[axisIndex]) {
                const currentLength = measurement.value[axisIndex].length;

                if (length === null) {
                    return currentLength;
                } else if (length !== currentLength) {
                    throw new Error(
                        `[graphDataFromLines]: Inconsistent measurement lengths. Expected ${length}, but found ${currentLength} for line ${line.id}`,
                    );
                }
            }
        }
        return length;
    }, null);

    if (dataLength === null) {
        console.warn('[graphDataFromLines]: No valid measurements found');
        return {
            dataPoints: [],
            isQuickAnalysis: false,
            apex: {},
            transition: {},
        };
    }

    const frameNumbers = _.values(segmentation);
    const isQuickAnalysis = dataLength <= 10;

    // (Optionally) track apex- and transition- points throughout the timeseries
    const apexTracking: Record<string, { frame: number; value: number }> = {};
    const transitionTracking: Record<string, { frame: number; value: number }[]> = {};

    // Construct data points
    const dataPoints = Array.from({ length: dataLength }, (_, index) => {
        return lines.reduce(
            (dataPoint: Record<string, number | null>, line) => {
                const axis = (line.axis || 'x') as (typeof ALL_AXES)[number];

                if (line.time_series?.id) {
                    const measurement = getMeasurementByName(analysis, line.time_series.id);
                    const axisIndex = ALL_AXES.indexOf(axis);
                    const effectiveFrame = isQuickAnalysis ? frameNumbers[index] : index;
                    const value =
                        axisIndex !== -1 && measurement?.value[axisIndex]
                            ? measurement.value[axisIndex][index] ?? null
                            : null;

                    dataPoint[line.id] = value;

                    if (value !== null) {
                        // Track apex points (highest peak of data)
                        if (extras?.includeApexPoints) {
                            if (!apexTracking[line.id] || value > apexTracking[line.id].value) {
                                apexTracking[line.id] = {
                                    frame: effectiveFrame,
                                    value,
                                };
                            }
                        }

                        // Track transitions (negative to positive)
                        if (extras?.includeTransitionPoints) {
                            if (index > 0) {
                                const prevValue = measurement?.value[axisIndex][index - 1] ?? null;

                                if (prevValue !== null && value !== null) {
                                    if (prevValue <= 0 && value > 0) {
                                        // Initialize array if it doesn't exist yet
                                        if (!transitionTracking[line.id]) {
                                            transitionTracking[line.id] = [];
                                        }

                                        transitionTracking[line.id].push({
                                            frame: effectiveFrame,
                                            value,
                                        });
                                    }
                                }
                            }
                        }
                    }
                } else {
                    console.warn(`[graphDataFromLines]: Incomplete data for line ${line.id}:`, line);
                    dataPoint[line.id] = null;
                }

                return dataPoint;
            },
            { frame: frameNumbers[index] },
        );
    });

    const transition = findNearestTransitionToFrame(transitionTracking, segmentation['P7']);

    return {
        dataPoints,
        isQuickAnalysis,
        apex: apexTracking,
        transition,
    };
}
