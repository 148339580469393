import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Container from '../../components/deprecated_ui/Container';
import Form from '../../components/deprecated_ui/Form';
import Input from '../../components/deprecated_ui/Input';
import { useSession } from '../../utils/hooks/useSession';
import Card from '../../components/deprecated_ui/Card';

interface ChangePasswordFormValues {
    newPassword: string;
    confirmPassword: string;
}

type ChangePasswordScreenProps = {
    subtitle?: string;
    onPasswordChanged?(): void;
    returnToKioskOnSuccess?: boolean;
};

export const ChangePasswordScreen = ({
    subtitle,
    onPasswordChanged,
    returnToKioskOnSuccess,
}: ChangePasswordScreenProps) => {
    const location = useLocation();
    const { isChangingPassword, changePassword } = useSession();
    const { register, handleSubmit } = useForm<ChangePasswordFormValues>();

    const [errorFromHash, setErrorFromHash] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [returnToKiosk, setReturnToKiosk] = useState(false);

    useEffect(() => {
        const { hash } = location;

        if (hash) {
            const params = new URLSearchParams(hash.substring(1));
            const error = params.get('error');

            if (error) {
                setErrorFromHash(error);
            }
        }
    }, [location.hash]);

    const handleChangePassword = async (values: ChangePasswordFormValues) => {
        setError(undefined);
        const { newPassword, confirmPassword } = values;
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            await changePassword(newPassword);
            onPasswordChanged?.();
            if (returnToKioskOnSuccess) {
                setReturnToKiosk(true);
            }
        } catch (error) {
            setError((error as Error).message);
        }
    };

    if (errorFromHash) {
        return (
            <Container>
                <Card>
                    <p>The email link is invalid or has expired.</p>
                </Card>
            </Container>
        );
    }

    return (
        <Container>
            <Card>
                {returnToKiosk ? (
                    <p>
                        Your password has been changed. You can close this browser window, and return to the Kiosk to
                        log in.
                    </p>
                ) : (
                    <Form
                        onSubmit={handleSubmit(handleChangePassword)}
                        title="Change your password"
                        actionText="Change password"
                        subtitle={subtitle}
                        isSubmitting={isChangingPassword}
                        error={error}
                    >
                        <Input
                            type="password"
                            label="New password"
                            {...register('newPassword')}
                            placeholder="Enter your new password"
                        />
                        <Input
                            type="password"
                            label="Confirm password"
                            {...register('confirmPassword')}
                            placeholder="Confirm your new password"
                        />
                    </Form>
                )}
            </Card>
        </Container>
    );
};
