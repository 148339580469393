import { typography } from '../../typography';

import * as css from './SystemMessageBanner.css';

type AvailableColors = 'red' | 'blue' | 'white';

type SystemMessageBannerProps = {
    message: string;
    isVisible: boolean;
    color?: AvailableColors;
};

export function SystemMessageBanner({ message, color, isVisible }: SystemMessageBannerProps) {
    return (
        <div
            className={css.banner({ color })}
            style={{ display: isVisible ? 'flex' : 'none' }}
            aria-hidden={!isVisible}
        >
            <span className={typography({ variant: 'h4' })}>{message}</span>
        </div>
    );
}
