export function Club({ width = 24, height, color = '#3B82F6' }: { width?: number; height?: number; color?: string }) {
    const w = width;
    const h = height ?? width;

    return (
        <svg viewBox="0 0 16 16" width={w} height={h} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 9.5H8.181l-5.8-6.824a.5.5 0 1 0-.762.648l8.35 9.824a1 1 0 0 0 .762.352h3.27a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1Zm-4.969 1h2.97v2h-1.27l-1.7-2Zm4.97 2h-1v-2h1v2Z"
                fill={color}
            />
        </svg>
    );
}
