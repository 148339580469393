import { SupabaseClient } from '@supabase/supabase-js';
import { SupabaseService } from './services/supabase.service';
import { UINodesEndpointPlaceholder } from './ui-nodes.endpoint-placeholder';
import { LayoutsEndpointPlaceholder } from './layouts.endpoint-placeholder';
import { CorridorsEndpointPlaceholder } from './corridors.endpoint-placeholder';
import { TagsEndpointPlaceholder } from './tags.endpoint-placeholder';

export class APIPlaceholder {
    private readonly _supabase: SupabaseService;
    constructor(supabaseClient: SupabaseClient) {
        this._supabase = new SupabaseService(supabaseClient);
        this.uiNodes = new UINodesEndpointPlaceholder(this._supabase);
        this.layouts = new LayoutsEndpointPlaceholder(this._supabase);
        this.corridors = new CorridorsEndpointPlaceholder(this._supabase);
        this.tags = new TagsEndpointPlaceholder(this._supabase);
    }

    // endpoints
    public readonly uiNodes: UINodesEndpointPlaceholder;
    public readonly layouts: LayoutsEndpointPlaceholder;
    public readonly corridors: CorridorsEndpointPlaceholder;
    public readonly tags: TagsEndpointPlaceholder;
}
