import _ from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForceUpdate } from 'motion/react';
import { Minus as MinusIcon } from '@phosphor-icons/react';

import { Screen, Button, colors, typography, ToolPanel, ToggleButton, Panel, ParameterSelector } from '@common/ui';
import { UserSettingsContext } from '../../utils/contexts';
import { useUserSettingsStore } from '../../state/userSettingsStore';
import {
    localize,
    AnyCategoryID,
    applyDisplayOrder,
    deferOnChange,
    filterByCategory,
    getCategorizations,
    getDisplayOrder,
    getTogglerTitle,
    isToggledOn,
    Mutable,
    rhsOfFirst,
    toggleOn,
    UIModule,
    type AnyUINode,
    type Device,
    type Layout,
    toggleOff,
    deferAllOnChange,
    DEFAULT_LAYOUT_NAME,
} from '@common';
import { LayoutEditorTabs } from './LayoutEditorTabs/LayoutEditorTabs';
import { KeyParameterSelection } from './KeyParameterSelection/KeyParameterSelection';
import { CustomModuleEditor } from './CustomModuleEditor/CustomModuleEditor';
import { CaretRight } from '../../components/deprecated_ui/icons/CaretRight';
import { KeyParameterEditor } from './KeyParameterEditor/KeyParameterEditor';
import { LayoutListEditorModal } from './LayoutListEditorModal/LayoutListEditorModal';
import { createTogglersForDevice, getMaxTogglers } from './LayoutEditor.utils';

import * as css from './LayoutEditor.css';

type PanelType =
    | 'swing_foundations'
    | 'module'
    | 'key'
    | 'linear_position'
    | 'angular_position'
    | 'linear_displacement_from_p1'
    | 'angular_displacement_from_p1'
    | 'linear_velocity'
    | 'angular_velocity'
    | 'linear_acceleration'
    | 'angular_acceleration'
    | 'angle'
    | 'distance'
    | 'speed'
    | 'ratio';

export default function LayoutEditor() {
    const navigate = useNavigate();
    const location = useLocation();
    const [panelFilter, setPanelFilter] = useState<Set<PanelType>>(new Set());
    const [categoryFilter, setCategoryFilter] = useState<Set<AnyCategoryID>>(new Set());
    const [[device, parentNode], setTarget] = useState<[Device, AnyUINode | null]>(['kiosk', null]);
    const userSettingsContext = useContext(UserSettingsContext);
    const [originalLayout, uiNodeTree] = useUserSettingsStore((state) => [state.activeLayout, state.uiNodeTree]);
    const layoutBeingEdited = useMemo(() => _.cloneDeep(originalLayout) as Mutable<Layout>, [originalLayout]);
    const [isEditingKeyParameter, setIsEditingKeyParameter] = useState(location.state?.isEditingKeyParameter ?? false);
    const [isSelectingLayout, setIsSelectingLayout] = useState(false);
    const [moduleBeingEdited, setModuleBeingEdited] = useState<UIModule | null>(null);

    const [update, forcedUpdateCount] = useForceUpdate();

    const categorizations = useMemo(() => uiNodeTree && getCategorizations(uiNodeTree), [uiNodeTree]);
    const visibleUINodeIDs = useMemo(
        () =>
            (categorizations && categoryFilter && filterByCategory(categorizations, categoryFilter)) ||
            'all_is_visible',
        [categorizations, categoryFilter],
    );

    const togglers = useMemo(
        () => {
            if (!layoutBeingEdited || !uiNodeTree) return [];
            const list = createTogglersForDevice(device, layoutBeingEdited, uiNodeTree, update, parentNode);

            // Apply initial values
            deferOnChange(
                list,
                (t) => _.includes(t.defaultDevices, device) && toggleOn(t),
                _.noop, // we don't actually want to trigger an update here
            );

            return list;
        },
        [device, layoutBeingEdited?.id, moduleBeingEdited, !uiNodeTree, parentNode, forcedUpdateCount], // eslint-disable-line react-hooks/exhaustive-deps
    );

    // When editing the floor screen, we are in fact editing its pages,
    // meaning we need to select the first page as our target if none is selected
    useEffect(() => {
        // TODO: Handle scroll management more elegantly throughout the app.
        window.scrollTo({ top: 0, behavior: 'instant' });

        if (device === 'floor' && !parentNode) {
            const firstPage = _.find(togglers, (t) => t.childNode.type === 'page')?.childNode ?? null;
            if (firstPage) {
                setTarget(['floor', firstPage]);
            } else {
                console.warn('No pages found for floor device');
            }
        }
    }, [device, parentNode, togglers]);

    const orderableTogglers = useMemo(
        () =>
            _(togglers)
                .filter((t) => isToggledOn(t))
                .sortBy((t) => [getDisplayOrder(t), t.childNode.name?.value ?? t.childNode.id])
                .value(),
        [togglers],
    );

    const orderedPanelTypes = _(uiNodeTree?.categories || [])
        .filter((c) => c.id.startsWith('graph_type.'))
        .sortBy((c) => c.display_order)
        .map((c) => rhsOfFirst(c.id, '.'))
        .unshift('swing_foundations', 'module')
        .value() as PanelType[];

    const availableTogglersByPanelType = _(togglers)
        .filter((t) => visibleUINodeIDs === 'all_is_visible' || visibleUINodeIDs.has(t.childNode.id))
        .groupBy((t) =>
            t.childNode.type === 'graph' ? rhsOfFirst(t.childNode.categories.graph_type, '.') : t.childNode.type,
        )
        .pickBy((_, type) => panelFilter.size === 0 || panelFilter.has(type as PanelType))
        .map((togglers, type) => [type as PanelType, togglers] as const)
        .orderBy(([type]) => _.indexOf(orderedPanelTypes, type))
        .value();

    return (
        <div className={css.root}>
            <Screen.Root
                title={localize('layout_editor.title')}
                header={{
                    static: {
                        start: (
                            <Button
                                size="medium"
                                variant="tertiary"
                                icon="pencil-circle"
                                iconSide="after"
                                iconColor={colors.blue[500]}
                                onClick={() => {
                                    setIsSelectingLayout(true);
                                }}
                            >
                                {layoutBeingEdited?.name || DEFAULT_LAYOUT_NAME}
                            </Button>
                        ),
                        end: (
                            <>
                                <Button variant="secondary" onClick={() => navigate(-1)}>
                                    {localize('layout_editor.cancel_button')}
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        console.log('Trying to save:', layoutBeingEdited);
                                        if (!layoutBeingEdited) return;
                                        userSettingsContext?.updateUserLayout(layoutBeingEdited);
                                        navigate('/kiosk');
                                    }}
                                >
                                    {localize('layout_editor.save_button')}
                                </Button>
                            </>
                        ),
                    },
                }}
            >
                <Screen.Column span="4/20">
                    <ToolPanel title="Key parameter">
                        <KeyParameterSelection
                            layout={layoutBeingEdited}
                            uiNodeTree={uiNodeTree}
                            onClick={() => setIsEditingKeyParameter(!isEditingKeyParameter)}
                        />
                        <KeyParameterEditor
                            layout={layoutBeingEdited}
                            uiNodeTree={uiNodeTree}
                            isOpen={isEditingKeyParameter}
                            onClose={() => setIsEditingKeyParameter(false)}
                        />
                    </ToolPanel>
                    <ToolPanel title="Filter">
                        <div className={css.filterGroup}>
                            <h4 className={typography({ variant: 'h4' })}>Types</h4>
                            {_.map(orderedPanelTypes, (type) => (
                                <ToggleButton
                                    key={type}
                                    icon="chart-line"
                                    maxLetters={36}
                                    isActive={panelFilter.has(type)}
                                    onChange={(isActive) => {
                                        if (isActive) {
                                            setPanelFilter((prevFilter) => new Set(prevFilter).add(type));
                                        } else {
                                            setPanelFilter((prevFilter) => {
                                                const newFilter = new Set(prevFilter);
                                                newFilter.delete(type);
                                                return newFilter;
                                            });
                                        }
                                    }}
                                >
                                    {/* The reason why we can't simply use the localized name
                                    of the node/graph type is because we need the label to be plural */}
                                    {localize(`layout_editor.panel.${type}`)}
                                </ToggleButton>
                            ))}
                        </div>
                        <div className={css.filterGroup}>
                            <h4 className={typography({ variant: 'h4' })}>Body Parts</h4>
                            {_(uiNodeTree?.categories)
                                .filter((c) => c.id.startsWith('body_part.'))
                                .sortBy((c) => c.display_order)
                                .map((c) => (
                                    <ToggleButton
                                        key={c.id}
                                        icon="chart-line"
                                        maxLetters={36}
                                        isActive={categoryFilter.has(c.id)}
                                        onChange={(isActive) => {
                                            if (isActive) {
                                                setCategoryFilter((prevFilter) => new Set(prevFilter).add(c.id));
                                            } else {
                                                setCategoryFilter((prevFilter) => {
                                                    const newFilter = new Set(prevFilter);
                                                    newFilter.delete(c.id);
                                                    return newFilter;
                                                });
                                            }
                                        }}
                                    >
                                        {c.name?.value || _.startCase(rhsOfFirst(c.id, '.'))}
                                    </ToggleButton>
                                ))
                                .value()}
                        </div>
                    </ToolPanel>
                </Screen.Column>
                <Screen.Column span="8/20">
                    {_.map(availableTogglersByPanelType, ([type, togglers]) => {
                        return (
                            <Panel
                                key={`panel_${type}`}
                                title={localize(`layout_editor.panel.${type}`)}
                                initialState="open"
                                contentDirection="column"
                                headerContent={
                                    type === 'module' && (
                                        // CREATE button
                                        <Button
                                            variant="secondary"
                                            size="tiny"
                                            icon="plus"
                                            iconColor={css.createCustomModuleIconColor}
                                            onClick={() => {
                                                if (moduleBeingEdited) return;
                                                const nextUnusedNode = _.find(
                                                    uiNodeTree?.nodes,
                                                    (n) =>
                                                        n.type === 'module' &&
                                                        !_.some(
                                                            layoutBeingEdited?.customizations,
                                                            (r) => r.child_ui_node_id === n.id,
                                                        ),
                                                ) as UIModule;
                                                if (!nextUnusedNode) return;
                                                setModuleBeingEdited(nextUnusedNode);
                                            }}
                                        >
                                            {localize('layout_editor.create_module_button')}
                                        </Button>
                                    )
                                }
                            >
                                {_.map(togglers, (toggler) => {
                                    // render each toggler
                                    const isOn = isToggledOn(toggler);

                                    // Custom modules have click handlers, others don't.
                                    const handleClick =
                                        type === 'module'
                                            ? () => setModuleBeingEdited(toggler.childNode as UIModule)
                                            : undefined;

                                    // Default Swing Foundations can't be removed, so the toggler is disabled.
                                    const isDisabled = _.includes(
                                        toggler.childNode.id,
                                        'swing_foundations.default_kiosk',
                                    );

                                    return (
                                        <div key={toggler.childNode.id} className={css.togglerLayout}>
                                            <div className={css.toggler}>
                                                <ParameterSelector
                                                    onClick={handleClick}
                                                    isActive={isOn}
                                                    title={getTogglerTitle(toggler, uiNodeTree)}
                                                />
                                                <Button
                                                    variant="secondary"
                                                    size="icon"
                                                    isDisabled={isDisabled}
                                                    onClick={() =>
                                                        deferAllOnChange(
                                                            isOn ? [toggler] : [...orderableTogglers, toggler],
                                                            (newOrder) => {
                                                                if (isOn) {
                                                                    toggleOff(toggler);
                                                                } else {
                                                                    toggleOn(toggler);
                                                                    applyDisplayOrder(newOrder);
                                                                }
                                                            },
                                                        )
                                                    }
                                                >
                                                    {isOn ? <MinusIcon size={14} /> : <CaretRight />}
                                                </Button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </Panel>
                        );
                    })}
                </Screen.Column>
                <Screen.Column span="8/20">
                    {uiNodeTree && (
                        <LayoutEditorTabs
                            tabs={['kiosk', 'floor']}
                            selectedTab={device}
                            getTabLabel={(d) => localize(`device.${d as Device}`, d)}
                            onSelectTab={(d) => setTarget([d as Device, null])}
                            togglers={orderableTogglers}
                            uiNodeTree={uiNodeTree}
                            maxLength={getMaxTogglers(device)}
                        />
                    )}
                </Screen.Column>
            </Screen.Root>
            <CustomModuleEditor
                layout={layoutBeingEdited}
                uiNodeTree={uiNodeTree}
                moduleBeingEdited={moduleBeingEdited}
                onClose={() => setModuleBeingEdited(null)}
            />
            <LayoutListEditorModal
                isOpen={isSelectingLayout}
                setIsOpen={setIsSelectingLayout}
                layoutBeingEdited={layoutBeingEdited}
            />
        </div>
    );
}
