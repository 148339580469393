import _ from 'lodash';
import { AnimatePresence, motion } from 'motion/react';
import { Star as StarIcon, Exclude as ComparisonIcon, Trash as TrashIcon } from '@phosphor-icons/react';

import { Menu, MenuItem } from '../Menu/Menu';
import { typography } from '../../typography';
import { localize, isSVGElement } from '@common';

import * as css from './Item.css';

interface ActivityNavigationItemProps {
  number: number;
  id: number;
  value: string;
  status: 'waiting' | 'analyzing' | 'ready' | 'error';
  isSelected?: boolean;
  isComparison?: boolean;
  isFavorite?: boolean;
  debug?: boolean;
  onSelect?: (id: number) => void;
  onToggleComparison?: (val: boolean) => void;
  onToggleFavorite?: (val: boolean) => void;
  onDelete?: (id: number) => void;
}

export function ActivityNavigationItem({
  number,
  id,
  value,
  status,
  isSelected,
  isComparison,
  isFavorite,
  debug,
  onSelect,
  onToggleComparison,
  onToggleFavorite,
  onDelete,
}: ActivityNavigationItemProps) {
  const buttonSize = 18;
  const iconSize = 14;

  return (
    <li>
      <div
        role="button"
        className={css.item({ isSelected })}
        onClick={(e) => {
          e.stopPropagation();

          const target = e.target as Element;

          if (isSVGElement(target)) {
            // Prevent selecting the swing underneath this icon
            return;
          }

          onSelect?.(id);
        }}
      >
        <div className={css.itemBefore}>
          <div className={css.itemNumber({ status })}>{number}</div>
          <AnimatePresence>
            {isComparison && (
              <motion.div
                key={`${id}-comparisonIcon`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <ComparisonIcon width={iconSize} color={css.iconColors.comparison} />
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {isFavorite && (
              <motion.div
                key={`${id}-favoriteIcon`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <StarIcon weight="fill" size={iconSize} color={css.iconColors.favorite} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div>
          <p className={typography({ variant: 'h2' })}>{value ?? '—'}</p>

          {debug && (
            <span data-name="DEBUG" className={css.debug}>
              #{id}
            </span>
          )}
        </div>
        <div className={css.menuWrapper}>
          <Menu
            label={localize('activity_navigation.menu_label')}
            align="right"
            buttonSize={buttonSize}
            buttonPadding={28 - buttonSize}
            onClick={(e) => e.stopPropagation()}
          >
            {_.isBoolean(isFavorite) && (
              <MenuItem
                key="toggleFavorite"
                style={{ paddingInline: buttonSize }}
                onClick={(e) => {
                  e.stopPropagation();
                  onToggleFavorite?.(!isFavorite);
                }}
                disabled={!onToggleFavorite}
              >
                <StarIcon
                  size={buttonSize}
                  color={onToggleFavorite ? css.iconColors.favorite : css.iconColors.disabled}
                />
                <span>
                  {isFavorite
                    ? localize('activity_navigation.item_remove_as_favorite')
                    : localize('activity_navigation.item_mark_as_favorite')}
                </span>
              </MenuItem>
            )}
            {_.isBoolean(isComparison) && (
              <MenuItem
                key="toggleComparison"
                style={{ paddingInline: buttonSize }}
                onClick={(e) => {
                  e.stopPropagation();
                  onToggleComparison?.(!isComparison);
                }}
                disabled={!onToggleComparison}
              >
                <ComparisonIcon
                  size={buttonSize}
                  color={onToggleComparison ? css.iconColors.comparison : css.iconColors.disabled}
                />
                <span>
                  {isComparison
                    ? localize('activity_navigation.item_remove_as_comparison')
                    : localize('activity_navigation.item_mark_as_comparison')}
                </span>
              </MenuItem>
            )}
            <MenuItem
              key="delete"
              style={{ paddingInline: buttonSize }}
              onClick={(e) => {
                e.stopPropagation();
                onDelete?.(id);
              }}
              disabled={!onDelete}
            >
              <TrashIcon
                size={buttonSize}
                color={onDelete ? css.iconColors.delete : css.iconColors.disabled}
              />
              <span>{localize('activity_navigation.item_delete')}</span>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </li>
  );
}

export function GhostNavigationItem() {
  return (
    <li>
      <div className={css.item({ isSelected: true })}>
        <div className={css.placeholder}>
          <p className={typography({ variant: 'h4' })}>—</p>
        </div>
      </div>
    </li>
  );
}
