import _ from 'lodash';
import { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion, useScroll } from 'motion/react';
import { CaretDown as CaretDownIcon, CaretUp as CaretUpIcon } from '@phosphor-icons/react';

import {
    Category,
    inferSwingPosition,
    L10n,
    positionNumberToSwingPosition,
    SwingPosition,
    swingPositionToPositionNumber,
    UIParameter,
    ALL_DEVICES,
    getLocalizedSwingPositionName,
    IVideo,
    Nil,
} from '@common';

import { useGlobalStore } from '../../../../state/globalStore';
import { SimplifiedCameraConfig } from '../../../../utils/types/camera';
import { ModuleBaseButton } from '../../../ModuleBase/ModuleBase.kiosk';
import { UIComponentProps } from '../../UIComponent.types';
import ModuleBase from '../../../ModuleBase';
import VideoWithSkeleton from '../../../VideoWithSkeleton';
import UIComponent from '../../UIComponent';
import { useUserSettingsStore } from '../../../../state/userSettingsStore/userSettingsStore';
import { UserSettingsContext } from '../../../../utils/contexts/UserSettingsContext';
import { UserSettingsStore } from '../../../../state/userSettingsStore';
import { colors, typography, useResizeObserver } from '@common/ui';

import * as css from './SwingFoundationsRenderer.kiosk.css';

const SCROLL_HEIGHT = 608;

export type SwingFoundationsRendererProps = {
    readonly cameraConfig: SimplifiedCameraConfig;
    readonly videos: IVideo[] | Nil;
    readonly parameterProps: UIComponentProps<UIParameter>[];
    readonly name: L10n | null;
    readonly swingFoundationGroups: {
        [id in SwingFoundationGroupID]: Category<'swing_foundation_group'>;
    };
};

type ParameterProps = UIComponentProps<UIParameter>;
type SwingFoundationGroupID = `swing_foundation_group.${string}${SwingPosition}${string}`;

export function SwingFoundationsRenderer({
    cameraConfig,
    videos,
    name,
    parameterProps,
    swingFoundationGroups,
}: SwingFoundationsRendererProps) {
    //? I am NOT proud of this ↴
    const activePositionNumber = useGlobalStore((state) => state.activePosition);
    const showComparison = useGlobalStore((state) => state.showComparison);
    const actions = useGlobalStore((state) => state.actions);
    const [skeletonOverlay, setSkeletonOverlay] = useState(false);
    const { settings: settingsStore, setSettingsStore } = useContext(UserSettingsContext);
    const [corridor, userSettings, setUserSettings] = useUserSettingsStore((state) => [
        state.activeCorridor,
        state.userSettings,
        state.actions.setUserSettings,
    ]);

    const buttons: ModuleBaseButton[] = useMemo(
        () => [
            {
                label: 'Comparison',
                icon: 'comparison',
                onClick: () => {
                    actions.setShowComparison(!showComparison);
                },
                isActive: showComparison,
            },
            {
                label: 'Skeleton',
                icon: 'skeleton',
                onClick: () => {
                    setSkeletonOverlay(!skeletonOverlay);
                },
                isActive: skeletonOverlay,
            },
            {
                label: 'Corridors',
                icon: 'corridors',
                onClick: () => {
                    const newSettings = setUserSettings({
                        showCorridorOn: _.isEmpty(userSettings.showCorridorOn) ? [...ALL_DEVICES] : [],
                    });
                    const newSettingsStore = { ...settingsStore, userSettings: newSettings } as UserSettingsStore;
                    setSettingsStore(newSettingsStore);
                },
                isActive: !!corridor && !_.isEmpty(userSettings.showCorridorOn),
            },
        ],
        [
            actions,
            showComparison,
            skeletonOverlay,
            corridor,
            userSettings,
            setUserSettings,
            settingsStore,
            setSettingsStore,
        ],
    );

    const [parameterPropsByGroup, swingFoundationPositionNumbers] = useMemo(() => {
        const parameterPropsByGroup = _(parameterProps)
            .groupBy((prop) => prop.currentNode.categories.swing_foundation_group ?? 'null')
            .defaults(_.mapValues(swingFoundationGroups, () => [] as ParameterProps[]))
            .map((props, id) => {
                const groupID = id as SwingFoundationGroupID;
                return {
                    group: swingFoundationGroups?.[groupID],
                    position: inferSwingPosition(groupID),
                    props,
                };
            })
            .sortBy(({ group, position }) => [position, group?.display_order])
            .value();

        // extract [1, 2, 4, 7] since they aren't hardcoded anymore
        const swingFoundationPositionNumbers = _(swingFoundationGroups)
            .map((group) => swingPositionToPositionNumber(inferSwingPosition(group.id)))
            .compact()
            .uniq()
            .sortBy()
            .value();

        return [parameterPropsByGroup, swingFoundationPositionNumbers] as const;
    }, [parameterProps, swingFoundationGroups]);

    // find if we should show parameters from p1, p2, p4 or p7
    const activeSwingFoundationPosition = useMemo(
        () =>
            positionNumberToSwingPosition(
                _.findLast(swingFoundationPositionNumbers, (pos) => pos <= activePositionNumber),
            ),
        [activePositionNumber, swingFoundationPositionNumbers],
    );

    const renderParameters = useMemo(
        () =>
            _(parameterPropsByGroup)
                .filter(
                    ({ group, position, props }) =>
                        !!group?.id && position === activeSwingFoundationPosition && !_.isEmpty(props),
                )
                .map(({ group, props }) => (
                    <div key={group.id} className={css.row}>
                        <div className={css.header}>
                            <p className={typography({ variant: 'h3' })}>{group?.name?.value}</p>
                        </div>
                        <div className={css.cells}>
                            {_.map(props, (p) => (
                                <UIComponent key={p.currentNode.id} {...p} />
                            ))}
                        </div>
                    </div>
                ))
                .value(),
        [activeSwingFoundationPosition, parameterPropsByGroup],
    );

    const ref = useRef<HTMLDivElement>(null);
    const { height } = useResizeObserver({
        ref,
    });

    const title = getLocalizedSwingPositionName(activeSwingFoundationPosition);
    const subtitle = name?.value || 'Swing foundations';

    return (
        <ModuleBase title={title} subtitle={subtitle} buttons={buttons} isStatic>
            <div className={css.root}>
                <div className={css.videoLayout} ref={ref}>
                    <VideoWithSkeleton
                        skeletonOverlay={skeletonOverlay}
                        layout="stacked"
                        videos={videos}
                        cameraConfig={cameraConfig}
                    />
                </div>
                <ParameterTable
                    referenceHeight={height ?? SCROLL_HEIGHT}
                    dependencies={[activeSwingFoundationPosition]}
                >
                    {renderParameters}
                </ParameterTable>
            </div>
        </ModuleBase>
    );
}

// Parameter table that knows whether it's scrollable or not.
// Displays a 'go down' | 'go up' button to allow the user to see parameters that are out of view.
function ParameterTable({
    referenceHeight,
    dependencies,
    scrollableThresholdPx = 10,
    children,
}: {
    referenceHeight: number;
    dependencies: unknown[];
    scrollableThresholdPx?: number;
    children: ReactNode;
}) {
    const ref = useRef<HTMLDivElement>(null);
    const [scrollDirection, set] = useState<'up' | 'down' | undefined>('down');

    const { height = 0, scrollHeight = 0 } = useResizeObserver({
        ref,
    });

    const isValid = typeof height === 'number' && height > 0 && typeof scrollHeight === 'number' && scrollHeight > 0;
    const isScrollable = isValid && height < scrollHeight && scrollHeight - height > scrollableThresholdPx;

    const { scrollY } = useScroll({
        container: ref,
    });

    useEffect(() => {
        const handler = (progress?: number) => {
            if (typeof progress === 'undefined') {
                return set(undefined);
            }

            if (progress === 0) {
                return set('down');
            }

            if (progress >= 1) {
                return set('up');
            }

            set(undefined);
        };

        if (isScrollable) {
            handler(0);
        } else {
            handler(undefined);
        }

        const unsubscribe = scrollY.on('change', handler);

        return () => {
            if (ref.current) {
                // ref.current might have changed, but either way, we want to scroll instantly to the top.
                // eslint-disable-next-line react-hooks/exhaustive-deps
                ref.current.scrollTo({ top: 0, behavior: 'instant' });
            }

            unsubscribe();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isScrollable, ...dependencies]);

    const onTriggerScroll = useCallback(
        (direction: 'up' | 'down') => {
            if (isScrollable) {
                const scrollDestination = direction === 'down' ? scrollHeight : 0;

                ref.current?.scrollTo({ top: scrollDestination, behavior: 'smooth' });
            }
        },
        [isScrollable, scrollHeight],
    );

    return (
        <motion.div className={css.tableLayout({ scrollDirection })} style={{ minHeight: referenceHeight }}>
            <div className={css.table} style={{ maxHeight: referenceHeight }} ref={ref}>
                {children}
            </div>
            <div className={css.triggerButtonLayout({ direction: 'up' })}>
                <AnimatePresence mode="sync">
                    {scrollDirection === 'up' && (
                        <motion.button
                            className={css.triggerButton}
                            onClick={() => onTriggerScroll('up')}
                            initial={{ y: -32, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -32, opacity: 0 }}
                        >
                            <CaretUpIcon size={24} color={colors.bluegray[600]} />
                        </motion.button>
                    )}
                </AnimatePresence>
            </div>
            <div className={css.triggerButtonLayout({ direction: 'down' })}>
                <AnimatePresence mode="sync">
                    {scrollDirection === 'down' && (
                        <motion.button
                            className={css.triggerButton}
                            onClick={() => onTriggerScroll('down')}
                            initial={{ y: 32, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: 32, opacity: 0 }}
                        >
                            <CaretDownIcon size={24} color={colors.bluegray[600]} />
                        </motion.button>
                    )}
                </AnimatePresence>
            </div>
        </motion.div>
    );
}
