/* eslint-disable */

import * as THREE from 'three';
import { useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import { suspend } from 'suspend-react';

type VideoProps = Partial<Omit<HTMLVideoElement, 'children'>>;

type VideoTextureProps = {
    unsuspend?: 'canplay' | 'canplaythrough' | 'loadstart' | 'loadedmetadata';
    start?: boolean;
    mode?: 'stream' | 'fetch';
} & VideoProps;

async function fetchVideoAsync(url: URL, videoProps: VideoProps) {
    // TODO:
    // Safari does not like this.
    const response = await fetch(url);
    const videoBlob = await response.blob();
    const videoObjectUrl = URL.createObjectURL(videoBlob);

    return Object.assign(document.createElement('video'), {
        crossOrigin: 'anonymous',
        loop: true,
        muted: true,
        src: videoObjectUrl,
        ...videoProps,
    });
}

function streamVideoSync(url: URL, videoProps: VideoProps) {
    return Object.assign(document.createElement('video'), {
        srcObject: (url instanceof MediaStream && url) || undefined,
        crossOrigin: 'anonymous',
        loop: true,
        muted: true,
        src: url,
        ...videoProps,
    });
}

export function useVideoTexture(
    src: string | MediaStream,
    { unsuspend = 'canplaythrough', start = true, mode = 'stream', ...videoProps }: VideoTextureProps = {},
) {
    const url = new URL(typeof src === 'string' ? src : '', window.location.href);
    const gl = useThree((state) => state.gl);

    const texture = suspend(
        () =>
            new Promise(async (res) => {
                const video =
                    mode === 'stream' ? streamVideoSync(url, videoProps) : await fetchVideoAsync(url, videoProps);

                const texture = new THREE.VideoTexture(video);
                if ('colorSpace' in texture) {
                    texture.colorSpace = gl.outputColorSpace;
                } else {
                    // @ts-expect-error I know
                    texture.encoding = gl.outputEncoding;
                }

                video.addEventListener(unsuspend, () => res(texture));
            }),
        [src],
    ) as THREE.VideoTexture;

    useEffect(() => {
        if (start) {
            texture.image.play();
        }
    }, [texture, start]);

    return texture;
}
