import type { IVideo } from '@common';

interface FrameCapture {
    frameBlob: Blob | null;
    error?: string;
}

export async function captureVideoFrame(videoPayload: IVideo, frameNumber: number): Promise<FrameCapture> {
    const video = document.createElement('video');
    video.crossOrigin = 'anonymous';
    video.muted = true;
    video.src = videoPayload.url;
    const totalFrames = videoPayload.metadata.totalFrames;

    return new Promise((resolve) => {
        video.onerror = () => {
            resolve({
                frameBlob: null,
                error: `Failed to load video: ${video.error?.message || 'Unknown error'}`,
            });
        };

        if (!totalFrames) {
            resolve({
                frameBlob: null,
                error: `No video frames`,
            });
        }

        // Wait for both metadata and full data load
        Promise.all([
            new Promise<void>((r) => video.addEventListener('loadedmetadata', () => r())),
            new Promise<void>((r) => video.addEventListener('loadeddata', () => r())),
        ]).then(() => {
            // Calculate the time for the desired frame
            const fps = totalFrames! / video.duration;
            const seekTime = frameNumber / fps;

            // Seek to slightly before the desired frame
            video.currentTime = Math.max(0, seekTime - 0.1);

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (!ctx) {
                resolve({ frameBlob: null, error: 'Could not get canvas context' });
                return;
            }

            let frameFound = false;
            let timeoutId: number | undefined = undefined;

            const cleanup = () => {
                window.clearTimeout(timeoutId);
                video.pause();
                video.remove();
            };

            timeoutId = window.setTimeout(() => {
                cleanup();
                resolve({
                    frameBlob: null,
                    error: `Error: Frame ${frameNumber} not found`,
                });
            }, 15000);

            // Wait for seek to complete
            video.onseeked = () => {
                video.requestVideoFrameCallback(function callback() {
                    const currentTime = video.currentTime;
                    const currentFrame = Math.round(currentTime * fps);

                    if (currentFrame >= frameNumber) {
                        frameFound = true;
                        // Set canvas dimensions to match video's intrinsic size
                        canvas.width = video.videoWidth;
                        canvas.height = video.videoHeight;
                        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

                        canvas.toBlob((blob) => {
                            cleanup();
                            if (blob) {
                                resolve({ frameBlob: blob });
                            } else {
                                resolve({
                                    frameBlob: null,
                                    error: 'Failed to create blob',
                                });
                            }
                        }, 'image/jpeg');

                        return;
                    }

                    if (!frameFound) {
                        video.requestVideoFrameCallback(callback);
                    }
                });

                video.play().catch((err) => {
                    cleanup();
                    resolve({
                        frameBlob: null,
                        error: `Failed to play video: ${err.message}`,
                    });
                });
            };
        });

        // Load the video
        video.load();
    });
}
