import '../styles/fonts.css';
import '../styles/reset.css';

import { useRef } from 'react';
import _ from 'lodash';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';

import ConnectionPendingScreen from '../screens/ConnectionPendingScreen';
import KioskScreen from '../screens/KioskScreen';
import FloorScreen from '../screens/FloorScreen';
import FloorConnectionPendingScreen from '../screens/FloorConnectionPendingScreen';
import ResetPasswordScreen from '../screens/ResetPasswordScreen';
import MessageOverlay from '../components/MessageOverlay/MessageOverlay';
import { AuthProtectedRoute } from './protectedRoutes';
import { EDeviceType } from '../utils/types/environment';
import { useUserSettingsStore } from '../state/userSettingsStore';
import { UserSettingsProvider } from '../utils/contexts/UserSettingsContext';
import ClubSelection from '../screens/ClubSelection/ClubSelection';
import TagSelection from '../screens/TagSelection/TagSelection';
import UserSettings from '../screens/UserSettings/UserSettings';
import { SessionProvider } from '../utils/contexts/SessionContext';
import { useSession } from '../utils/hooks/useSession';
import { useGlobalStore } from '../state/globalStore';
import { ActivityOverview } from '../screens/ActivityOverview';
import { BoothLoginScreen } from '../screens/BoothLoginScreen/BoothLoginScreen';
import Spinner, { SpinnerWrapper } from '../components/Spinner/Spinner';
import { ChangePasswordScreen } from '../screens/ChangePasswordScreen';
import { BoothSessionProvider } from '../utils/contexts/BoothSessionContext';
import { useBeastSocketConnection } from '../utils/hooks/useBeastSocketConnection';
import { useBoothSession } from '../utils/hooks/useBoothSession';
import { GlobalContextProvider } from '../utils/contexts/GlobalContext';
import LayoutEditor from '../screens/LayoutEditor';
import SwingFoundationsEditor from '../screens/SwingFoundationsEditor';
import PlaybackScreen from '../screens/PlaybackScreen';

import * as css from './app.css';

const supabase = createClient(
    import.meta.env['VITE_PUBLIC_SUPABASE_URL'],
    import.meta.env['VITE_PUBLIC_SUPABASE_ANON_KEY'],
    {
        auth: {
            storageKey: 'elva-user.session',
        },
    },
);

const supabaseBoothClient = createClient(
    import.meta.env['VITE_PUBLIC_SUPABASE_URL'],
    import.meta.env['VITE_PUBLIC_SUPABASE_ANON_KEY'],
    {
        auth: {
            storageKey: 'elva-booth.session',
        },
    },
);

export default function App() {
    const location = useLocation();

    if (location.pathname === '/change-password') {
        return (
            <SessionProvider supabaseClient={supabase}>
                <div className={css.app}>
                    <ChangePasswordScreen
                        subtitle="In order to continue, please update your password."
                        returnToKioskOnSuccess
                    />
                </div>
            </SessionProvider>
        );
    }

    return (
        <BoothSessionProvider supabaseBoothClient={supabaseBoothClient}>
            <GlobalContextProvider supabase={supabase}>
                <div className={css.app}>
                    <AppWithUserSession />
                </div>
            </GlobalContextProvider>
        </BoothSessionProvider>
    );
}

export function AppWithUserSession() {
    const { boothSessionDetails, isLoading, isResettingPassword } = useBoothSession();
    const location = useLocation();

    const isQrLogin = location.pathname === '/login' && _.includes(location.search, 'uid=');
    const currentDeviceType = import.meta.env['VITE_PUBLIC_DEVICE_TYPE'];
    const returnTo = String(currentDeviceType).toUpperCase() === EDeviceType.KIOSK ? '/kiosk' : '/floor';

    if (isLoading) {
        return (
            <SpinnerWrapper>
                <Spinner size="l" />
            </SpinnerWrapper>
        );
    }

    if (!boothSessionDetails && !isQrLogin && !isResettingPassword) {
        return <BoothLoginScreen returnTo={returnTo} />;
    }

    return (
        <SessionProvider supabaseClient={supabase}>
            <GlobalContextProvider supabase={supabase}>
                <BoothApp returnTo={returnTo} />
            </GlobalContextProvider>
        </SessionProvider>
    );
}

function BoothApp({ returnTo }: { returnTo: string }) {
    const location = useLocation();
    const { cameraConfig, isLoggingOut, isLoading, user } = useSession();
    useBeastSocketConnection();

    const state = location.state as { backgroundLocation?: Location };
    const userSettingsStore = useRef(useUserSettingsStore()).current;
    const showSpinner = useGlobalStore((state) => state.loadingSpinnerVisible);

    if (isLoading) {
        return (
            <SpinnerWrapper>
                <Spinner size="l" />
            </SpinnerWrapper>
        );
    }

    if (
        (user?.shouldChangePassword && location.pathname !== '/reset-password') ||
        location.pathname === '/change-password'
    ) {
        return <ChangePasswordScreen subtitle="In order to continue, please update your password." />;
    }

    return (
        <>
            {isLoggingOut && <MessageOverlay message="Ending session..." />}
            {showSpinner && <MessageOverlay message="Loading activity..." />}
            <UserSettingsProvider store={userSettingsStore} supabaseClient={supabase}>
                <Routes location={state?.backgroundLocation || location}>
                    <Route path="/floor" element={user ? <FloorScreen /> : <FloorConnectionPendingScreen />} />
                    <Route
                        index
                        path="/kiosk"
                        element={
                            user ? (
                                cameraConfig ? (
                                    <KioskScreen supabase={supabase} />
                                ) : (
                                    <SpinnerWrapper>
                                        <Spinner size="l" />
                                    </SpinnerWrapper>
                                )
                            ) : (
                                <ConnectionPendingScreen />
                            )
                        }
                    />

                    <Route path="kiosk" element={<AuthProtectedRoute redirectPath="/" />}>
                        <Route path="playback">
                            <Route index element={<PlaybackScreen display="playback" />} />
                            <Route path="comparison" element={<PlaybackScreen display="comparison" />} />
                        </Route>
                        <Route path="layout-editor" element={<LayoutEditor />} />
                        <Route index path="user-settings" element={<UserSettings />} />
                        <Route index path="clubs" element={<ClubSelection />} />
                        <Route index path="tags" element={<TagSelection supabase={supabase} />} />
                        <Route index path="swing-foundations-editor" element={<SwingFoundationsEditor />} />
                        {/* Temp placement, will be moved to be home screen for the home experience */}
                        <Route index path="activity-overview" element={<ActivityOverview />} />
                    </Route>

                    <Route path="/login" element={user ? <Navigate to="/" /> : <ConnectionPendingScreen />} />

                    <Route path="/reset-password" element={<ResetPasswordScreen />} />

                    <Route path="*" element={<Navigate to={returnTo} />} />
                </Routes>
            </UserSettingsProvider>
        </>
    );
}
