// TODO: @common
/**
 * A "Reconnecting..." message overlay covering the whole screen
 */

import { motion } from 'motion/react';

import * as css from './MessageOverlay.css';

const MessageOverlay = ({ message }: { message: string }) => {
    return (
        <motion.div className={css.container} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <span>{message}</span>
        </motion.div>
    );
};

export default MessageOverlay;
