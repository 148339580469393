export function BackArrowIcon({ width = 25, height }: { width?: number; height?: number }) {
    const w = width;
    const h = height ?? width;

    return (
        <svg width={w} height={h} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.4996 12.4999C21.4996 12.6988 21.4206 12.8896 21.2799 13.0303C21.1393 13.1709 20.9485 13.2499 20.7496 13.2499H6.05993L11.5302 18.7193C11.5999 18.789 11.6552 18.8717 11.6929 18.9628C11.7306 19.0538 11.75 19.1514 11.75 19.2499C11.75 19.3485 11.7306 19.4461 11.6929 19.5371C11.6552 19.6281 11.5999 19.7109 11.5302 19.7806C11.4606 19.8502 11.3778 19.9055 11.2868 19.9432C11.1957 19.9809 11.0982 20.0003 10.9996 20.0003C10.9011 20.0003 10.8035 19.9809 10.7124 19.9432C10.6214 19.9055 10.5387 19.8502 10.469 19.7806L3.71899 13.0306C3.64926 12.9609 3.59394 12.8782 3.55619 12.7871C3.51845 12.6961 3.49902 12.5985 3.49902 12.4999C3.49902 12.4014 3.51845 12.3038 3.55619 12.2127C3.59394 12.1217 3.64926 12.039 3.71899 11.9693L10.469 5.2193C10.6097 5.07857 10.8006 4.99951 10.9996 4.99951C11.1986 4.99951 11.3895 5.07857 11.5302 5.2193C11.671 5.36003 11.75 5.55091 11.75 5.74993C11.75 5.94895 11.671 6.13982 11.5302 6.28055L6.05993 11.7499H20.7496C20.9485 11.7499 21.1393 11.8289 21.2799 11.9696C21.4206 12.1103 21.4996 12.301 21.4996 12.4999Z"
                fill="#475569"
            />
        </svg>
    );
}
