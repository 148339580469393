import _ from 'lodash';
import { useState, useEffect, useMemo, DependencyList } from 'react';
import { first, Observable, skip } from 'rxjs';
/**
 * Abstracts away the boilerplate of using observables in React components.
 * Fetches the first value from the observable and uses it as the initial value, then subscribes to the observable.
 * @param createObservable The function to call to generate the observable to be subscribed to.
 * @param deps The dependencies that determine when to re-create the observable and re-subscribe to it.
 * @returns The latest value of the observable.
 */
export function useObservable<T>(createObservable: () => Observable<T>, deps?: DependencyList): T {
    // memoize the observable, only re-create it when the dependencies change
    const [observableToBeSubscribeTo$, initialValue] = useMemo(
        () => {
            const observable$ = createObservable();

            // fetch the initial value from the observable
            let initValue: T = undefined as T;
            observable$
                .pipe(first())
                .subscribe((value) => (initValue = value))
                .unsubscribe();

            // if we successfully got the initial value
            return initValue !== undefined
                ? // then we skip it and subscribe to the rest of the values
                  [observable$.pipe(skip(1)), initValue]
                : // otherwise we subscribe to the observable as is
                  [observable$, initValue];
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps,
    );

    // set up the state to hold the latest value of the observable
    const [value, setValue] = useState(initialValue);

    // subscribe to the observable, unsubscribe when dependencies change or the component unmounts
    useEffect(() => {
        const subscription = observableToBeSubscribeTo$.subscribe((newValue) => setValue(newValue));
        return () => subscription.unsubscribe();
    }, [observableToBeSubscribeTo$]);

    return value;
}
