import { PlusMinus } from '@phosphor-icons/react';
import React, { useMemo, type MouseEvent, CSSProperties } from 'react';
import _ from 'lodash';

import type { CorridorParameterMargins, Device, Nil, Unit } from '@common';
import { isFiniteNumber, unitConversionMap } from '@common';
import { SelectBox } from '../SelectBox/SelectBox';
import { CorridorRenderer, corridorRendererCSS } from './CorridorRenderer';
import { colors } from '../../colors';

import * as defaultCSS from './ParameterRenderer.css';

export const parameterRendererCSS: Partial<typeof defaultCSS> = defaultCSS;

export type ParameterRendererProps = {
    title: string;
    unit: Unit;
    value: number | Nil;
    comparisonValue?: number | Nil;
    corridorParameterMargins?: CorridorParameterMargins | Nil;
    statistics?: { mean: number | Nil; std: number | Nil };
    isSelected?: boolean;
    currentDevice?: Device;
    onClick?: (event: MouseEvent) => void;
    cssOverrides?: typeof parameterRendererCSS & {
        corridor?: typeof corridorRendererCSS;
    };
    rootStyle?: React.CSSProperties;
};

export function ParameterRenderer({
    title,
    unit,
    value,
    comparisonValue,
    corridorParameterMargins,
    statistics,
    isSelected,
    currentDevice,
    onClick,
    cssOverrides,
    rootStyle,
}: ParameterRendererProps) {
    const converter = unitConversionMap[unit];

    const converted = converter(value);
    const comparisonConverted = converter(comparisonValue);
    const displayValue = isFiniteNumber(converted.value) ? String(converted.value) : '— ';
    const displayUnit = converted.symbol;

    const comparisonDisplayValue = isFiniteNumber(comparisonConverted.value)
        ? String(comparisonConverted.value)
        : undefined;

    const statusColor = getStatusIndicatorColor(converted.value, corridorParameterMargins);

    const isSelectable = typeof isSelected !== 'undefined'; // if isSelected is present, it is selectable
    const isClickable = typeof onClick === 'function'; // if onClick is a function, parameter is a clickable button

    const css = { ...defaultCSS, ...cssOverrides };

    const renderStatistics = useMemo(() => {
        const [convertedMean, convertedStd] = _.values(statistics).map((stat) => {
            const converted = converter(stat).value;
            return _.isNumber(converted) ? converted : '-';
        });

        return (
            <div className={css.stats({ isSelectable })}>
                <span className={css.statsText}>{convertedMean}</span>
                <span className={css.std}>
                    <PlusMinus className={css.statsIcon} width={12} height={10} color={colors.bluegray[600]} />
                    <span className={css.statsText}>{convertedStd}</span>
                </span>
            </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statistics, isSelected]);

    const floorScale = useMemo(() => {
        if (currentDevice !== 'floor') {
            return 1;
        }

        const BASE_FZ = 28;
        const MIN_FZ = 22;
        const MIN_CHARS_TRESHOLD = 4;
        const MAX_CHARS_THRESHOLD = 8;

        const formattedDisplayValue = String(`${displayValue}${displayUnit}`);
        const totalChars = formattedDisplayValue.length;

        // If length is below threshold, return full scale
        if (totalChars <= MIN_CHARS_TRESHOLD) {
            return 1;
        }

        // If length is above max threshold, return minimum scale
        if (totalChars >= MAX_CHARS_THRESHOLD) {
            return MIN_FZ / BASE_FZ;
        }

        const stepsFromThreshold = totalChars - MIN_CHARS_TRESHOLD;
        const totalSteps = MAX_CHARS_THRESHOLD - MIN_CHARS_TRESHOLD;
        const totalScaleRange = 1 - MIN_FZ / BASE_FZ;
        const reductionPerStep = totalScaleRange / totalSteps;
        const scale = 1 - reductionPerStep * stepsFromThreshold;

        // Round to 2 decimal places
        return Math.round(scale * 100) / 100;
    }, [currentDevice, displayValue, displayUnit]);

    return (
        <div
            className={css.root({ isSelectable, isFloor: currentDevice === 'floor' })}
            style={rootStyle}
            role={isClickable ? 'button' : undefined}
            onClick={(event) => onClick?.(event)}
        >
            <div className={css.cell({ isSelectable })}>
                {isSelectable && (
                    <div className={css.selectArea}>
                        <SelectBox selected={isSelected} />
                    </div>
                )}
                <div className={css.cellLayout}>
                    <div className={css.label}>
                        <span className={css.labelText}>{title}</span>
                        <div className={css.indicator({ status: statusColor })} />
                    </div>
                    <div className={css.content}>
                        <span
                            className={css.value({ status: statusColor })}
                            style={{ '--floor-scale': floorScale } as CSSProperties}
                        >
                            <span className={css.displayValue}>{displayValue}</span>
                            <span className={css.displayUnit}>{displayUnit}</span>
                            {comparisonDisplayValue && (
                                <span className={css.comparisonDisplayValue}>
                                    {COMPARISON_VALUE_PREFIX}
                                    {comparisonDisplayValue}
                                    {COMPARISON_VALUE_POSTFIX}
                                    <span className={css.displayUnit}>{displayUnit}</span>
                                </span>
                            )}
                        </span>

                        {statistics && renderStatistics}
                    </div>
                </div>
            </div>

            {corridorParameterMargins && (
                <CorridorRenderer
                    corridorParameterMargins={corridorParameterMargins}
                    value={converted.value}
                    cssOverrides={css.corridor}
                />
            )}
        </div>
    );
}

function getStatusIndicatorColor(
    value: number | Nil,
    corridorParameterMargins: CorridorParameterMargins | Nil,
): 'red' | 'yellow' | 'green' | 'white' {
    if (!isFiniteNumber(value) || !corridorParameterMargins) return 'white';

    if (corridorParameterMargins.inner_min < value && value < corridorParameterMargins.inner_max) return 'green';
    else if (corridorParameterMargins.outer_min < value && value < corridorParameterMargins.outer_max) return 'yellow';
    else return 'red';
}

const COMPARISON_VALUE_PREFIX = '';
const COMPARISON_VALUE_POSTFIX = '';
