interface DividerProps {
    length?: number;
    orientation?: 'horizontal' | 'vertical';
    color?: string;
}

function Divider({ length, orientation = 'horizontal', color = 'rgb(238, 238, 238)' }: DividerProps) {
    const isVertical = orientation === 'vertical';
    const style = {
        border: `0.5px solid ${color}`,
        width: isVertical ? '0.5px' : `${length}px`,
        height: isVertical ? `${length}px` : '0.5px',
    };

    return <hr style={style} />;
}

export default Divider;
