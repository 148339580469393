import _ from 'lodash';
import { useLayoutEffect, useState } from 'react';
import useMeasure from 'react-use-measure';
import UIComponent from '../../UIComponent';
import ModuleBaseKiosk from '../../../ModuleBase/ModuleBase.kiosk';
import ModuleBaseFloor from '../../../ModuleBase/ModuleBase.floor';
import { type AnyUINode, localize } from '@common';
import type { ImplementationOf, UIComponentProps } from '../../UIComponent.types';

import * as css from './Module.css';

export const Module: ImplementationOf<'module'> = ({ node, children, currentDevice }) => {
    if (children.length === 0) {
        console.error("[UIComponent('module')]: No children");
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }

    const title = node.metadata?.title || node.name?.value || node.short_name?.value || _.startCase(node.id);

    const subtitle = localize('module_type.custom');

    switch (currentDevice) {
        case 'kiosk':
            return (
                <ModuleBaseKiosk title={title}>
                    <div className={css.kioskModuleLayout}>
                        {_.map(children, (props) => (
                            <UIComponent key={props.currentNode.id} {...props} />
                        ))}
                    </div>
                </ModuleBaseKiosk>
            );
        case 'floor':
            return (
                <FloorModuleLayout title={title} subtitle={subtitle}>
                    {children}
                </FloorModuleLayout>
            );
        default:
            return <div />;
    }
};

function FloorModuleLayout({
    title,
    subtitle,
    children,
}: {
    title: string;
    subtitle: string;
    children: UIComponentProps<AnyUINode>[];
}) {
    // Ideal height for a custom module parameter
    const MODULE_HEIGHT = 80;
    // The whole width of the page
    const PAGE_WIDTH = 1690;

    const [dimensions, setDimensions] = useState({ columns: 3, rows: 1, maxItems: 3 });
    const [ref, { width, height }] = useMeasure();

    useLayoutEffect(() => {
        const columns = width >= PAGE_WIDTH / 2 ? 4 : 3;
        const rowHeight = MODULE_HEIGHT;
        const rows = Math.min(Math.floor(height / rowHeight), 4);
        const maxItems = rows * columns;

        console.log({ width, height, columns, rows, maxItems });
        setDimensions({ columns, rows, maxItems });
    }, [width, height]);

    return (
        <ModuleBaseFloor title={title} subtitle={subtitle} ref={ref}>
            <div
                className={css.floorModuleLayout({
                    columns: dimensions.columns as 3 | 4,
                    rows: dimensions.rows as 1 | 2 | 3 | 4 | 5,
                })}
            >
                {_.take(children, dimensions.maxItems).map((props) => (
                    <UIComponent key={props.currentNode.id} {...props} />
                ))}
            </div>
        </ModuleBaseFloor>
    );
}
