import _ from 'lodash';
import { Suspense } from 'react';

import {
    DEFAULT_KEY_PARAMETER_ID,
    hasChildOfType,
    hasParentOfType,
    inferSwingPosition,
    Layout,
    Nil,
    removeSwingPosition,
    UINodeTree,
    UIParameter,
} from '@common';

import * as css from './KeyParameterSelection.css';

interface KeyParameterSelectionProps {
    layout: Layout;
    uiNodeTree: UINodeTree | Nil;
    onClick?: () => void;
}

const DEFAULT_KEY_PARAMETER_NODE_ID = `parameter.${DEFAULT_KEY_PARAMETER_ID}` as const;

export function KeyParameterSelection({ layout, uiNodeTree, onClick }: KeyParameterSelectionProps) {
    if (!uiNodeTree || _.isEmpty(uiNodeTree?.nodes)) return <Suspense fallback={null} />;

    const parameterNodeID = _.find(
        layout.customizations,
        (c) => hasParentOfType(c, 'activity_navigation') && hasChildOfType(c, 'parameter'),
    )?.child_ui_node_id as UIParameter['id'] | Nil;

    const parameterNode: UIParameter | Nil =
        (parameterNodeID && (_.find(uiNodeTree.nodes, (n) => n.id === parameterNodeID) as UIParameter)) ||
        (_.find(uiNodeTree.nodes, (n) => n.id === DEFAULT_KEY_PARAMETER_NODE_ID) as UIParameter);

    const position = inferSwingPosition(parameterNode?.id || DEFAULT_KEY_PARAMETER_ID)?.toUpperCase();
    const metricCategoryID = parameterNode?.categories.metric;
    const metric = metricCategoryID && _.find(uiNodeTree.categories, (c) => c.id === metricCategoryID);
    const metricName = metric?.name?.value;
    const name = removeSwingPosition(
        parameterNode?.name?.value ||
            parameterNode?.short_name?.value ||
            (parameterNode?.id && _.startCase(parameterNode?.id)) ||
            _.startCase(DEFAULT_KEY_PARAMETER_ID),
    );

    return (
        <div className={css.keyParameterSelection({ isClickable: !!onClick })} onClick={onClick}>
            <div className={css.positionIndicator}>{position}</div>
            <div className={css.content}>
                {metricName && <span className={css.parameterMetric}>{metricName}</span>}
                <h4 className={css.parameterName}>{name}</h4>
            </div>
        </div>
    );
}
