import { QRCodeCanvas } from 'qrcode.react';

import VStack from '../../components/deprecated_ui/VStack';
import HStack from '../../components/deprecated_ui/HStack';
import Divider from '../../components/deprecated_ui/Divider';
import ElvaLogoNameWhite from '../../components/deprecated_ui/Logo/ElvaLogoNameWhite';
import { useBoothSession } from '../../utils/hooks/useBoothSession';

import * as css from './FloorConnectionPendingScreen.css';

function FloorConnectionPendingScreen() {
    const { boothSessionDetails } = useBoothSession();

    const qrValue = `${import.meta.env['VITE_PUBLIC_URL']}/login${
        boothSessionDetails ? `?uid=${boothSessionDetails.uid}` : ''
    }`;

    return (
        <div className={css.container}>
            <span
                style={{
                    color: 'white',
                    position: 'absolute',
                    top: 10,
                    fontWeight: 'bold',
                }}
            >
                <ElvaLogoNameWhite />
            </span>
            <div>
                <HStack
                    style={{
                        backgroundColor: '#0E0E0E',
                        padding: '20px 40px',
                    }}
                    center
                    spacing={40}
                >
                    <div>
                        <QRCodeCanvas size={90} value={qrValue} bgColor="transparent" fgColor="white" />
                    </div>
                    <Divider orientation="vertical" length={80} color={'#313131'} />
                    <VStack>
                        <h2 style={{ color: 'white', fontSize: '1.5em' }}>SCAN TO CONTINUE</h2>
                        <p style={{ color: 'white', fontSize: '1em' }}>
                            For more information, see the control device nearby
                        </p>
                    </VStack>
                </HStack>
            </div>
        </div>
    );
}

export default FloorConnectionPendingScreen;
