import { createWithEqualityFn } from 'zustand/traditional';
import { subscribeWithSelector } from 'zustand/middleware';

import type { UserSettingsStoreState } from './userSettingsStore.types';
import type { Layout, UINodeTree } from '@common';

export const useUserSettingsStore = createWithEqualityFn<UserSettingsStoreState>()(
    subscribeWithSelector((set, get) => ({
        userSettings: {
            language: 'English',
            golfBag: [],
        },

        availableTags: [],

        playbackSettings: {
            playbackAngle: undefined,
            skeletonOverlay: false,
            instantReplay: true,
            playbackSpeed: 1,
        },

        activeCorridor: undefined,

        // Layout
        layouts: [],
        activeLayout: undefined,
        uiNodeTree: undefined,

        actions: {
            setUserSettings: (userSettingsToUpdate) => {
                const merged: UserSettingsStoreState['userSettings'] = {
                    ...get().userSettings,
                    ...userSettingsToUpdate,
                };
                set({ userSettings: merged });
                return merged;
            },
            setAvailableTags: (availableTags) => set({ availableTags }),
            setPlaybackSettings: (playbackSettings) => set({ playbackSettings }),

            // Activity
            setActivityId: (activityId) => set({ activityId }),
            setActivityClub: (activityClub) => set({ activityClub }),
            setActivityTags: (activityTags) => set({ activityTags }),

            // Corridor
            setActiveCorridor: (corridor) => set({ activeCorridor: corridor }),

            // Layout
            setUINodeTree(uiNodes?: UINodeTree) {
                set({ uiNodeTree: uiNodes });
            },
            setLayouts: (layouts) => {
                set({ layouts });
            },
            setActiveLayout: (layout?: Layout) => {
                if (!layout) {
                    return;
                }

                set({
                    activeLayout: layout,
                });
            },
        },
    })),
);
