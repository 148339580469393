// NOTE: This is the Beast "Communication" Websocket handler.
// It handles sending and receiving status messages from the Beast.
// e.g.
// SWING_VIDEOS_AVAILABLE
// ANALYSIS_AVAILABLE
// ANALYSIS_STATE
// BEAST_STATUS
// CURRENT_ACTIVITY_ID

// We are using 'react-use-websocket', but are wrapping it here because of its thread blocking behavior
// see e.g. https://github.com/robtaussig/react-use-websocket/issues/234

import { create } from 'zustand';
import useWebSocket, { type SendMessage } from 'react-use-websocket';
import type { WebSocketMessage } from 'react-use-websocket/dist/lib/types';
import { useEffect, useRef } from 'react';

export const useCommunicationSocket = (serverUrl: string) => {
    const [setSendMessage, setIsConnected, onMessage] = useCommunicationSocketStore((state) => [
        state.actions.setSenderFn,
        state.actions.setConnected,
        state.actions.onMessage,
    ]);

    const onOpen = () => {
        setIsConnected(true);
        setSendMessage(sendJsonMessage);
    };

    const onClose = () => setIsConnected(false);

    // A simple queue to hold incoming websocket messages
    const messageQueue = useRef<MessageEvent[]>([]);

    // NOTE: This queuing mechanism was put in place because Beast would send us multiple messages
    // at once, and we were missing some of them. I'm curious if we can remove this queue now.
    // If it's still a problem we have to refactor this away from holding only the 'last message' to holding a history of messages.
    useEffect(() => {
        const intervalId = setInterval(() => {
            // If there are any items in the queue, we pop one!
            if (messageQueue.current.length > 0) {
                const message = messageQueue.current.shift(); // Remove the first message from the queue
                if (message) {
                    messageHandler(message);
                }
            }
        }, 600); // Pop one message from queue every 600ms

        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const messageHandler = (message: MessageEvent) => {
        if (message.type === 'message' && typeof onMessage === 'function') {
            try {
                const data = JSON.parse(message.data);
                onMessage(data);
            } catch (e) {
                console.error('WebSocket data parsing error:', e);
                return;
            }
        }
    };

    const { sendJsonMessage } = useWebSocket(serverUrl, {
        onOpen: onOpen,
        onClose: onClose,
        onMessage: (message: MessageEvent) => {
            messageQueue.current = [...messageQueue.current, message];
        },
        filter: (message) => {
            const status = JSON.parse(message.data).type;

            if (status === 'BEAST_STATUS') {
                return false; // Not forwarding BEAST_STATUS messages for NOW.
            }

            return true;
        },
        // Should always reconnect
        shouldReconnect: () => true,
        reconnectAttempts: 1000, // ~ 30 minute reconnection window
        reconnectInterval: 2000, // Try reconnecting every two secs
    });
};

export type BeastCommunicationState = {
    senderFn: SendMessage | null;
    isConnected: boolean;
    payload: unknown | null;
    actions: {
        setSenderFn: (fn: SendMessage) => void;
        setConnected: (connected: boolean) => void;
        sendCommunicationMessage: (payload: unknown) => void;
        onMessage: (payload: unknown) => void;
    };
};

export const useCommunicationSocketStore = create<BeastCommunicationState>((set, get) => {
    return {
        senderFn: null,
        receiverFn: () => void 0,
        isConnected: false,
        payload: null,
        actions: {
            setSenderFn: (fn) => {
                set({ senderFn: fn });
            },

            setConnected: (connected) => set({ isConnected: connected }),
            sendCommunicationMessage: (payload) => {
                const senderFn = get().senderFn;
                if (typeof senderFn === 'function') {
                    senderFn(payload as unknown as WebSocketMessage);
                }
            },
            onMessage: (payload) => {
                set({ payload });
            },
        },
    };
});
