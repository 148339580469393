import _ from 'lodash';
import { SupabaseClient } from '@supabase/supabase-js';

import { transformSkeletonToFramesFirst } from '../utils/skeletonUtils';
import { convertToSimplifiedConfig } from '../utils/hooks/useSupabaseCameraConfig/utils';
import type { CameraConfig, SimplifiedCameraConfig } from '../utils/types/camera';
import type { AnalysisCloudStatus, IVideo, Analysis, Supabase } from '@common';
import {
    ANALYSIS_TYPES,
    CommunicationMessage,
    fetchAllSwingsForActivity,
    isFiniteNumber,
    fetchAllUserActivites,
    createNewActivity,
} from '@common';
import { useCommunicationSocketStore } from '../state/communicationSocketStore';
import { useGlobalStore } from '../state/globalStore';
import { useUserSettingsStore } from '../state/userSettingsStore';
import type { SocketPayload } from '../state/relaySocketStore';
import {
    AnalysisFailedMessage,
    AnalysisMessage,
    AnalysisResponse,
    AnalysisStateMessage,
    BeastState,
    BeastStatusMessage,
    VideoResponse,
    VideosMessage,
} from './DataManager.types';

const CAMERA_NAMES = ['back', 'down_the_line', 'face_on', 'trail_front'];

// TODO #_#
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type PartialData = any;

interface DataManagerConfig {
    supabase: SupabaseClient;
    userId: string;
    boothSessionId: number;
}

interface AnalysisCache {
    analysisData: {
        id: number;
        data: PartialData;
    } | null;
    videoData: {
        id: number;
        data: PartialData;
    } | null;
}

class DataManager {
    private static instance: DataManager | null = null;

    private readonly userId: string;
    private readonly boothSessionId: number;
    private readonly supabase: Supabase;
    private isConnected = false;
    private communicationSubscription: (() => void) | null = null;
    private partialAnalysesCache: Record<string, AnalysisCache> = {};
    private activityId: number;

    public cameraConfig?: SimplifiedCameraConfig;

    private constructor(config: DataManagerConfig) {
        this.userId = config.userId;
        this.boothSessionId = config.boothSessionId;
        this.supabase = config.supabase;
        this.activityId = -1;
    }

    private async handleBeastCommunication(state: BeastState): Promise<void> {
        if (state.isConnected && !this.isConnected) {
            await this.handleBeastConnection(state);
        }

        this.isConnected = state.isConnected;

        if (state.payload) {
            await this.handleBeastPayload(state.payload);
        }
    }

    private async init(): Promise<void> {
        try {
            const activityId = await this.getActivityId(this.boothSessionId, (payload: unknown) => {
                if (_.isPlainObject(payload)) {
                    useCommunicationSocketStore.getState().actions.sendCommunicationMessage(payload as SocketPayload);
                }
            });

            if (!isFiniteNumber(activityId)) {
                throw new Error('Invalid activity ID received');
            }

            const { setActivityId } = useUserSettingsStore.getState().actions;
            setActivityId(activityId);

            this.activityId = activityId;
            this.cameraConfig = await this.getCameraConfig();

            this.communicationSubscription = useCommunicationSocketStore.subscribe(
                this.handleBeastCommunication.bind(this) as any,
            );

            this.subscribeToFullAnalysis();

            // Background tasks
            // TODO: Refactor into something async ...
            void this.loadActivity(activityId);
            void fetchAllUserActivites(this.supabase as SupabaseClient, this.userId).then((data) => {
                if (data) {
                    useGlobalStore.setState({
                        loadedActivities: data,
                    });
                }
            });
        } catch (error) {
            if (error instanceof Error) {
                throw new Error(`Initialization failed: ${error.message}`);
            }
            throw error;
        }
    }

    private async getAnalysisJsonDumpById(id: number) {
        const { data, error } = await this.supabase.rpc('get_analysis_json_dump', {
            _analysis_id: id,
        });

        if (error) {
            return null;
        }
        return data;
    }

    private subscribeToFullAnalysis() {
        type SupabaseChangePayload = {
            commit_timestamp: string;
            eventType: 'UPDATE';
            new: {
                swing_id: number;
                type_id: 1 | 2; // 1 = quick analysis, 2 = full analysis
                created_at: string;
                status: AnalysisCloudStatus;
            };
        };

        const onChange = async (payload: SupabaseChangePayload) => {
            const id = payload.new.swing_id;
            const status = payload.new.status;
            const typeId = payload.new.type_id;

            if (status !== 'completed') {
                return;
            }

            // We are only interested in completed full analysis data
            if (typeId === ANALYSIS_TYPES['QUICK_ANALYSIS']) {
                return;
            }

            const existingSwingAnalysis = _.find(useGlobalStore.getState().swings, (analysis) => analysis.id === id);

            // Bail early if analysis doesn't exist and there's nothing to update.
            if (!existingSwingAnalysis) {
                return;
            }

            // Bail early if we already have this swing's full analysis
            if (existingSwingAnalysis.fullAnalysis) {
                return;
            }

            const analysisId = await this.getAnalysisId(id);

            // At this stage we have a quick analysis that's ready to be upgraded.
            const analysisJsonResponse = await this.getAnalysisJsonDumpById(analysisId);

            if (!analysisJsonResponse) {
                return;
            }

            const { skeleton, bones, measurements, parameter_values, segmentation } = analysisJsonResponse;

            // Keep using existing videos from the Quick Analysis if they exist.
            const existingVideos = existingSwingAnalysis.quickAnalysis?.data.videos;

            const timestamp = new Date(payload.new.created_at).getTime();
            const frames = transformSkeletonToFramesFirst(skeleton);
            const videos = existingVideos ?? (await this.getVideos(id));

            const analysis: Analysis = {
                id,
                activityID: this.activityId,
                data: {
                    analysis: {
                        timestamp,
                        isQuickAnalysis: false,
                        frames,
                        bones,
                        measurements,
                        parameter_values,
                        segmentation,
                        skeleton,
                    },
                    videos,
                },
            };

            // Save to store
            this.saveSwingToStore(id, payload.new.type_id, analysis);
        };

        return this.supabase
            .channel('channel')
            .on(
                'postgres_changes',
                {
                    event: 'UPDATE',
                    schema: 'public',
                    table: 'full_analysis_job_queue',
                },
                async (payload) => {
                    try {
                        await onChange(payload as unknown as SupabaseChangePayload);
                    } catch (error) {
                        console.error('Error processing change:', error);
                    }
                },
            )
            .subscribe((status) => {
                if (status === 'CLOSED' || status === 'CHANNEL_ERROR') {
                    console.error('Subscription error:', status);
                }
            });
    }

    private async saveSwingToStore(swingId: number, type: ANALYSIS_TYPES, swing: Analysis) {
        return useGlobalStore.getState().actions.addAnalysisToSwing(swingId, type, swing);
    }

    private async processPartialSwing(
        id: number,
        type: 'VIDEOS' | 'ANALYSIS',
        partialSwing: VideoResponse | AnalysisResponse,
    ) {
        // Initialize a partial analysis if none exists.
        if (!this.partialAnalysesCache[id]) {
            this.partialAnalysesCache[id] = {
                videoData: null,
                analysisData: null,
            };
        }

        switch (type) {
            case 'VIDEOS': {
                const { total_frames, urls, dimensions } = partialSwing as VideoResponse;

                const videos = _.map(CAMERA_NAMES, (name) => ({
                    name,
                    url: urls[name],
                    metadata: {
                        totalFrames: total_frames,
                        sourceWidth: dimensions?.sourceWidth ?? 2464,
                        sourceHeight: dimensions?.sourceHeight ?? 2064,
                    },
                }));

                this.partialAnalysesCache[id].videoData = {
                    id,
                    data: {
                        videos,
                        analysis: this.partialAnalysesCache[id].analysisData?.data.analysis || null,
                    },
                };

                this.triggerInstantReplay(id, videos);

                break;
            }
            case 'ANALYSIS': {
                const { analysis_url } = partialSwing as AnalysisResponse;

                const data = await fetch(analysis_url);
                const json = await data.json();

                const timestamp = Date.now();
                const frames = transformSkeletonToFramesFirst(json.skeleton);

                this.partialAnalysesCache[id].analysisData = {
                    id,
                    data: {
                        analysis: {
                            timestamp,
                            isQuickAnalysis: frames.length <= 10,
                            frames,
                            bones: json.bones,
                            measurements: json.measurements,
                            parameter_values: json.parameter_values,
                            segmentation: json.segmentation,
                            skeleton: json.skeleton,
                        },
                        videos: this.partialAnalysesCache[id].videoData?.data.videos || null,
                    },
                };
            }
        }

        const { videoData, analysisData } = this.partialAnalysesCache[id];

        // Once we have both analysis and videos, save to store
        if (videoData && analysisData) {
            // A complete swing
            const analysis: Analysis = {
                id,
                activityID: this.activityId,
                data: {
                    videos: videoData.data.videos,
                    analysis: analysisData.data.analysis,
                },
            };

            console.log('WEBSOCKET: %c%s', 'color: white; background: rebeccapurple;', `Adding swing ${id} from WS.`);
            this.saveSwingToStore(id, ANALYSIS_TYPES['QUICK_ANALYSIS'], analysis);

            delete this.partialAnalysesCache[id];
        }
    }

    private async triggerInstantReplay(id: number, videos: IVideo[]) {
        return useGlobalStore.getState().actions.triggerReplay(id, videos);
    }

    private reflectBeastStatus(status: string) {
        if (
            // Statuses we want reflected
            status === 'WAITING_FOR_BALL' ||
            status === 'ANALYZING_SWING' ||
            status === 'BALL_DETECTED' ||
            status === 'BALL_STEADY' ||
            status === 'SWING_ANALYSIS_FAILED' ||
            status === 'IDLE' ||
            status === 'FINALIZING'
        ) {
            useGlobalStore.setState({
                lastBeastStatus: status,
            });
        }
    }

    private async handleBeastPayload(payload: any): Promise<void> {
        const { type, content } = payload;

        const handlers: Record<string, (content: any) => Promise<void>> = {
            BEAST_STATUS: async (content: BeastStatusMessage) => {
                this.reflectBeastStatus(content.status);
            },
            SWING_VIDEOS_AVAILABLE: async (content: VideosMessage) => {
                const { swing_id, ...rest } = content;
                await this.processPartialSwing(swing_id, 'VIDEOS', rest);
            },
            ANALYSIS_AVAILABLE: async (content: AnalysisMessage) => {
                const { swing_id, ...rest } = content;
                await this.processPartialSwing(swing_id, 'ANALYSIS', rest);
            },
            ANALYSIS_STATE: async (content: AnalysisStateMessage) => {
                console.log('TODO: Handle analysis state', content.state);
            },
            SWING_ANALYSIS_FAILED: async (content: AnalysisFailedMessage) => {
                this.reflectBeastStatus('SWING_ANALYSIS_FAILED');
                if (content.swing_id === null) return;
            },
        };

        const handler = handlers[type];
        if (handler) {
            await handler(content);
        }
    }

    private async getCameraConfig(): Promise<SimplifiedCameraConfig> {
        const { data, error } = await this.supabase
            .from('booth_sessions')
            .select('camera_calibrations (*)')
            .eq('id', this.boothSessionId)
            .order('id', { ascending: false })
            .limit(1)
            .single();

        if (error) {
            throw error;
        }

        return convertToSimplifiedConfig(
            (
                data.camera_calibrations as unknown as {
                    calibration: CameraConfig;
                }
            ).calibration,
        );
    }

    private async handleBeastConnection(state: BeastState): Promise<void> {
        const messages = [
            {
                type: CommunicationMessage.CURRENT_ACTIVITY_ID,
                content: { activity_id: this.activityId },
            },
            {
                type: CommunicationMessage.INSTRUCTION,
                content: { action: 'ACTIVATE' },
            },
        ];

        for (const message of messages) {
            state.actions.sendCommunicationMessage(message as unknown as SocketPayload);
        }
    }

    private async getAnalysisId(swingId: number): Promise<number | null> {
        const { data, error } = await this.supabase
            .from('swing_analysis_link_view')
            .select('full_analysis_id')
            .eq('swing_id', swingId)
            .single();

        if (error || !data.full_analysis_id) {
            return null;
        }

        return data.full_analysis_id;
    }
    private async createNewActivity(sendCommunicationMessage?: (message: unknown) => void): Promise<number> {
        const activityId = await createNewActivity(this.supabase as SupabaseClient, this.userId, this.boothSessionId);

        if (typeof activityId === 'number' && sendCommunicationMessage) {
            const messages = [
                {
                    type: CommunicationMessage.INSTRUCTION,
                    content: { action: 'ACTIVATE' },
                },
                {
                    type: CommunicationMessage.CURRENT_ACTIVITY_ID,
                    content: { activity_id: activityId },
                },
            ];

            messages.forEach((message) => sendCommunicationMessage(message));
        }

        return activityId ?? -1;
    }

    private async getActivityId(
        boothSessionId: number,
        sendCommunicationMessage?: (message: unknown) => void,
    ): Promise<number> {
        const { data, error } = await this.supabase
            .from('activities')
            .select('*')
            .eq('booth_session_id', boothSessionId)
            .order('start_time', { ascending: false })
            .limit(1)
            .single();

        if (error || !data?.id) {
            console.error(error);
            // TODO: Should probably be handled differently
            return this.createNewActivity(sendCommunicationMessage);
        }

        return data.id;
    }

    private async getVideos(swingId: number): Promise<IVideo[]> {
        if (!this.cameraConfig) {
            return [];
        }

        const storageBaseUrl = (this.supabase.storage as any).url;

        const getVideoUrl = (cameraId: string): string => {
            return `${storageBaseUrl}/object/public/swings/${swingId}/frontend_recording/${cameraId}.mp4`;
        };

        const getMetadata = async (): Promise<IVideo['metadata']> => {
            const response = await fetch(
                `${storageBaseUrl}/object/public/swings/${swingId}/frontend_recording/metadata.json`,
            );
            const { frame_count, height, width } = await response.json();

            return {
                totalFrames: frame_count,
                sourceWidth: width,
                sourceHeight: height,
            };
        };

        const metadata = await getMetadata();

        return _(this.cameraConfig.camera_id_to_name)
            .toPairs()
            .filter(([, cameraName]) => _.includes(CAMERA_NAMES, cameraName))
            .map(([cameraId, cameraName]) => ({
                name: cameraName,
                url: getVideoUrl(cameraId),
                metadata,
            }))
            .value();
    }

    public static getInstance(): DataManager {
        if (!DataManager.instance) {
            throw new Error('DataManager must be initialized first');
        }
        return DataManager.instance;
    }

    public static async initialize(config: DataManagerConfig): Promise<DataManager> {
        try {
            const {
                data: { user },
            } = await config.supabase.auth.getUser();

            if (!user) {
                throw new Error('No authenticated user found');
            }

            if (DataManager.instance && DataManager.instance.boothSessionId !== config.boothSessionId) {
                await DataManager.disconnect();
                DataManager.instance = null;
            }

            if (!DataManager.instance) {
                DataManager.instance = new DataManager(config);
                await DataManager.instance.init();
            }

            return DataManager.instance;
        } catch (error) {
            if (error instanceof Error) {
                throw new Error(`Failed to initialize DataManager: ${error.message}`);
            }
            throw error;
        }
    }

    public static async disconnect(): Promise<void> {
        if (!DataManager.instance) {
            return;
        }

        try {
            // Cleanup subscriptions
            if (DataManager.instance.communicationSubscription) {
                DataManager.instance.communicationSubscription();
            }

            // Reset beast state
            useCommunicationSocketStore.getState().actions.sendCommunicationMessage({
                type: CommunicationMessage.INSTRUCTION,
                content: { action: 'GO_IDLE' },
            });

            // Clear instance
            DataManager.instance = null;
        } catch (error) {
            console.error('Error during DataManager disconnect:', error);
            throw error;
        }
    }

    /**
     * Loads an activity
     */
    public async loadActivity(activityID: number) {
        try {
            // Show a loading screen while swings are loading
            useGlobalStore.setState({
                loadingSpinnerVisible: true,
            });

            // Empty the list of swings
            useGlobalStore.setState({
                activeSwingID: -1,
                swings: [],
                viewingActivityId: activityID,
            });

            const swings = await fetchAllSwingsForActivity(this.supabase, activityID);

            // TODO: Does this really work?
            swings.forEach((swing) => {
                const analysisData = swing.fullAnalysis?.data.analysis ?? swing.quickAnalysis?.data.analysis;

                if (analysisData?.skeleton) {
                    analysisData.frames = transformSkeletonToFramesFirst(analysisData.skeleton);
                }
            });

            useGlobalStore.getState().actions.setSwings(swings);

            // Select the newest swing
            useGlobalStore.getState().actions.selectTheLatestSwing();
        } catch {
            // no-op
        } finally {
            // Loading done - hide the loader
            useGlobalStore.setState({
                loadingSpinnerVisible: false,
            });
        }
    }

    public static reset(): void {
        DataManager.instance = null;
    }
}

export default DataManager;
