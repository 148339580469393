export function Star({ width = 8, height, color = '#475569' }: { width?: number; height?: number; color?: string }) {
    const w = width;
    const h = height ?? width;

    return (
        <svg viewBox="0 0 8 9" width={w} height={h} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.322 4.089 5.915 5.302l.429 1.815a.513.513 0 0 1-.766.557L4 6.702l-1.58.972a.512.512 0 0 1-.764-.557l.43-1.815L.68 4.09a.514.514 0 0 1 .291-.902l1.844-.149.711-1.72a.511.511 0 0 1 .946 0l.711 1.72 1.844.15a.514.514 0 0 1 .293.901h.002Z"
                fill={color}
            />
        </svg>
    );
}
