import { ReactNode } from 'react';
import { Pencil as EditIcon } from '@phosphor-icons/react';

import { typography } from '../../typography';
import { colors } from '../../colors';

import * as css from './EditableTag.css';

type EditableTagProps = {
    children: ReactNode;
    isSelected?: boolean;
    isEditable?: boolean;
    onClick?: () => void;
};

export function EditableTag({
    isSelected: _isSelected = false,
    isEditable: _isEditable = false,
    onClick,
    children,
}: EditableTagProps) {
    const isSelected = _isSelected && !_isEditable;
    const isClickable = typeof onClick === 'function';
    const isEditable = isClickable && _isEditable;

    return (
        <div
            role={isClickable ? 'button' : undefined}
            onClick={onClick}
            className={css.tag({
                selected: isSelected,
            })}
        >
            <span className={typography({ variant: 'h3' })}>{children}</span>
            {isEditable && <EditIcon size={18} color={colors.blue[500]} weight="fill" />}
        </div>
    );
}
