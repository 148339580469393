import { type ReactNode, Children, useState } from 'react';
import { AnimatePresence, motion } from 'motion/react';
import { Lego as LegoIcon } from '@phosphor-icons/react';

import { typography } from '../../typography';
import { ActionButton } from '../buttons/ActionButton/ActionButton';
import { colors } from '../../colors';

import * as css from './Panel.css';

const availableIcons = {
    lego: { component: LegoIcon, width: 24, height: 24, color: colors.blue[600] },
};

export function Panel({
    title,
    collapsible,
    initialState,
    countChildren,
    contentDirection = 'row',
    headerContent,
    icon,
    children,
}: {
    title: string;
    collapsible?: boolean;
    initialState?: 'open' | 'closed';
    countChildren?: boolean;
    contentDirection?: 'row' | 'column';
    headerContent?: ReactNode;
    icon?: keyof typeof availableIcons;
    children: ReactNode;
}) {
    const [isOpen, setIsOpen] = useState(collapsible ? initialState === 'open' : true);

    const Icon = icon && availableIcons[icon].component;

    const toggle = () => setIsOpen((isOpen) => !isOpen);

    return (
        <div
            className={css.panel({
                type: collapsible ? 'accordion' : 'static',
            })}
        >
            <div
                className={css.header}
                role={collapsible ? 'button' : undefined}
                onClick={collapsible ? () => toggle() : undefined}
            >
                <div className={css.title}>
                    {Icon && (
                        <Icon
                            color={availableIcons[icon].color}
                            width={availableIcons[icon].width}
                            height={availableIcons[icon].height}
                        />
                    )}
                    <h3 className={typography({ variant: 'h3' })}>{title}</h3>
                    {countChildren && (
                        <div className={css.count}>
                            <span className={typography({ variant: 'h3' })}>{Children.count(children)}</span>
                        </div>
                    )}
                </div>
                {headerContent}
                {collapsible && (
                    <ActionButton
                        icon={isOpen ? 'collapse' : 'expand'}
                        onClick={(e) => {
                            e.stopPropagation();
                            toggle();
                        }}
                    />
                )}
            </div>
            <AnimatePresence initial={false}>
                {isOpen && (
                    <motion.div
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        style={{ overflow: 'hidden' }}
                        variants={{
                            open: { height: 'auto' },
                            collapsed: { height: 0 },
                        }}
                        transition={{
                            duration: 0.3,
                            type: 'tween',
                        }}
                    >
                        <div
                            className={css.content({
                                collapsible: Boolean(collapsible),
                                direction: contentDirection,
                            })}
                        >
                            {children}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}
