import { useCallback, useState } from 'react';
import _ from 'lodash';
import { CaretRight as CaretRightIcon, PencilCircle as PencilCircleIcon } from '@phosphor-icons/react';

import { colors } from '../../colors';
import { ToggleButton } from '../buttons';

import * as css from './ParameterSelector.css';

export type ParameterSelectorState = {
    selectedPosition: string | null;
    selectedAxis: string | null;
    validSelection: boolean;
};

export function ParameterSelector({
    title,
    positions = [],
    axes,
    isActive,
    onClick,
    onChange = () => {},
}: {
    title: string;
    positions?: string[];
    axes?: string[];
    isActive?: boolean;
    onClick?: () => void;
    onChange?: (state: ParameterSelectorState) => void;
}) {
    const [state, setState] = useState<ParameterSelectorState>({
        selectedPosition: null,
        selectedAxis: null,
        validSelection: false,
    });

    const hasAxes = Array.isArray(axes) && axes.length > 0;

    const updateState = useCallback(
        (updates: Partial<ParameterSelectorState>) => {
            const newState = {
                ...state,
                ...updates,
            };

            // Compute validSelection
            newState.validSelection =
                newState.selectedPosition !== null && (!hasAxes || newState.selectedAxis !== null);

            setState(newState);
            onChange(newState);
        },
        [onChange, state, hasAxes],
    );

    const isClickable = typeof onClick === 'function';

    return (
        <div
            className={css.parameterSelector({
                isClickable,
                isActive,
            })}
            onClick={onClick}
        >
            <div className={css.title}>
                <h3 className={css.titleText}>{title}</h3>
                {isClickable && (
                    <PencilCircleIcon
                        size={24}
                        color={css.IS_ACTIVE_DESIGN_VERSION === 3 && isActive ? colors.white : colors.blue[500]}
                    />
                )}
            </div>
            <div className={css.buttons}>
                {_.map(positions, (position) => (
                    <ToggleButton
                        key={position}
                        variant="darker"
                        isActive={state.selectedPosition === position}
                        onChange={(isActive) => {
                            updateState({
                                selectedPosition: isActive ? position : null,
                            });
                        }}
                    >
                        {position}
                    </ToggleButton>
                ))}

                {hasAxes && (
                    <>
                        <div className={css.icon}>
                            <CaretRightIcon width={16} height={16} />
                        </div>
                        {_.map(axes, (axis) => (
                            <ToggleButton
                                key={axis}
                                variant="darker"
                                isActive={state.selectedAxis === axis}
                                onChange={(isActive) => {
                                    updateState({
                                        selectedAxis: isActive ? axis : null,
                                    });
                                }}
                            >
                                {axis}
                            </ToggleButton>
                        ))}
                    </>
                )}
            </div>
        </div>
    );
}
