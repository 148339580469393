import { useNavigate } from 'react-router-dom';
import Container from '../../components/deprecated_ui/Container';
import Card from '../../components/deprecated_ui/Card';
import Button from '../../components/deprecated_ui/Button';
import VStack from '../../components/deprecated_ui/VStack';

type EmailSentScreenProps = {
    email: string;
};

const EmailSentScreen = ({ email }: EmailSentScreenProps) => {
    const navigate = useNavigate();

    return (
        <Container>
            <Card>
                <VStack spacing={20} style={{ width: '350px' }}>
                    <h3>Check Your Email</h3>
                    <p>
                        An email has been sent to <strong>{email}</strong> with detailed instructions to reset your
                        password. Please follow the steps provided to complete the process.
                    </p>
                    <p>Once your password has been reset, you can return to the home screen and log in as usual.</p>
                    <Button type="button" style={{ width: '100%' }} onClick={() => navigate('/')}>
                        <span style={{ fontSize: '0.8em' }}>Return to login</span>
                    </Button>
                </VStack>
            </Card>
        </Container>
    );
};

export default EmailSentScreen;
