import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { AnimatePresence, motion } from 'motion/react';
import _ from 'lodash';

import { PlaybackSettings } from '../../components/VideoPlayback/PlaybackSettings';
import { VideoPlayback } from '../../components/VideoPlayback/VideoPlayback';
import { BackArrowIcon } from '../../components/deprecated_ui/icons/BackArrow';
import { useGlobalStore } from '../../state/globalStore';
import { typography } from '../../styles/typography.css';
import { fallbackCameraConfig } from '../../utils/fallbacks';
import { ComparisonSidebar } from './components/ComparisonSidebar';
import { Switch } from '../../components/Switch';
import { useVideoPlaybackStore } from '../../components/VideoPlayback/VideoPlayback.state';
import type { PlaybackDisplay, PlaybackProps } from './PlaybackScreen.types';

import * as css from './PlaybackScreen.css';

export default function Playback({ display }: PlaybackProps) {
    const navigate = useNavigate();
    const [displayMode, setDisplayMode] = useState<PlaybackDisplay>(display ?? 'playback');
    const [
        activityID,
        swings,
        activeSwingID,
        comparisonSwingID,
        setComparisonSwingID,
        allUserFavoriteSwings,
        loadedActivities,
        activeSwing,
        comparisonSwing,
    ] = useGlobalStore((state) => [
        state.actions.getViewingActivity()?.id,
        state.swings,
        state.activeSwingID,
        state.comparisonSwingID,
        state.actions.setComparisonSwingID,
        state.allUserFavoriteSwings,
        state.loadedActivities,
        state.actions.getActiveSwing(),
        state.actions.getComparisonSwing(),
    ]);

    const activeSwingAnalysis = activeSwing?.fullAnalysis ?? activeSwing?.quickAnalysis;

    const comparisonSwingAnalysis = comparisonSwing?.fullAnalysis ?? comparisonSwing?.quickAnalysis;

    // Get the favorite swings in the current activity
    const currentActivityFavoriteSwings = _.filter(swings, (s) => s.isFavorite);

    // Get all favorite swings that are not already in `currentActivityFavoriteSwings`
    const allFavoriteSwingsFavorites = _.filter(
        allUserFavoriteSwings,
        (s) => s.isFavorite && !currentActivityFavoriteSwings.some((current) => current.id === s.id),
    );

    const reset = () => {
        useVideoPlaybackStore.setState({ autoPlaying: false, activeCameraAngle: null, playbackSpeed: 1 });
    };

    const onBack = () => {
        reset();
        navigate('/kiosk');
    };

    const handleSwitchDisplayMode = (displayMode: 'playback' | 'comparison') => {
        setDisplayMode(displayMode);
        reset();
    };

    return (
        <div className={css.root}>
            <div className={css.backNav}>
                <div className={css.backNavLeft}>
                    <button onClick={onBack} className={css.arrowButton}>
                        <BackArrowIcon />
                    </button>
                    <p
                        className={typography({
                            size: 'h2',
                            weight: 'bold',
                            color: 'dark',
                        })}
                    >
                        Playback
                    </p>
                </div>
                <Switch
                    switchKey="playback-display-switch"
                    items={[
                        {
                            title: 'Playback',
                            onClick: () => handleSwitchDisplayMode('playback'),
                            isSelected: displayMode === 'playback',
                            isDisabled: false,
                        },
                        {
                            title: 'Comparison',
                            onClick: () => handleSwitchDisplayMode('comparison'),
                            isSelected: displayMode === 'comparison',
                            isDisabled: false,
                        },
                    ]}
                />
            </div>
            <div className={css.container}>
                <div className={css.replay}>
                    {activeSwingAnalysis ? (
                        <VideoPlayback
                            activeSwingAnalysis={activeSwingAnalysis}
                            comparisonSwingAnalysis={comparisonSwingAnalysis}
                            cameraConfig={fallbackCameraConfig}
                            displayMode={displayMode}
                        />
                    ) : (
                        <div className={css.videoPlaceholder}>
                            <p
                                className={typography({
                                    size: 'h2',
                                    weight: 'bold',
                                    color: 'dark',
                                })}
                            >
                                No video found
                            </p>
                        </div>
                    )}
                </div>
                <AnimatePresence mode="wait">
                    {displayMode === 'playback' ? (
                        <motion.div
                            key="playbackSetting"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            style={{ width: '100%' }}
                        >
                            <PlaybackSettings />
                        </motion.div>
                    ) : (
                        <motion.div
                            key="comparisonSidebar"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <ComparisonSidebar
                                currentActivityId={activityID}
                                currentActivityFavoriteSwings={currentActivityFavoriteSwings}
                                otherActivitiesFavoriteSwings={allFavoriteSwingsFavorites}
                                onNewSwingSelected={(swingId) => {
                                    setComparisonSwingID(swingId);
                                }}
                                activities={loadedActivities}
                                activeSwingId={activeSwingID}
                                initialSelectedComparisonSwingId={comparisonSwingID}
                            />
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
}
