import { Nil } from './type-utils';
import { Unit } from './ui-node.types';
import _ from 'lodash';

export const unitConversionMap: {
    [unit in Unit]: (value: number | Nil) => { value: number | null; unit: string; symbol: string };
} = {
    m: (value: number | Nil) => ({
        value: roundNumberTo(convertMetersToCm(value)),
        unit: 'cm',
        symbol: 'cm',
    }),
    'm/s': (value: number | Nil) => ({
        value: roundNumberTo(convertMetersToCm(value)),
        unit: 'cm/s',
        symbol: 'cm/s',
    }),
    'm/s^2': (value: number | Nil) => ({
        value: roundNumberTo(convertMetersToCm(value)),
        unit: 'cm/s^2',
        symbol: 'cm/s²',
    }),
    rad: (value: number | Nil) => ({
        value: roundNumberTo(radToDeg(value)),
        unit: 'deg',
        symbol: '°',
    }),
    'rad/s': (value: number | Nil) => ({
        value: roundNumberTo(radToDeg(value)),
        unit: 'deg/s',
        symbol: '°/s',
    }),
    'rad/s^2': (value: number | Nil) => ({
        value: roundNumberTo(radToDeg(value)),
        unit: 'deg/s^2',
        symbol: '°/s²',
    }),
    ratio: (value: number | Nil) => ({
        value: roundNumberTo(convertRatioToPercentage(value)),
        unit: 'percent',
        symbol: '%',
    }),
};
// Convert from degrees to radians.
export const degToRad = (degrees: number | Nil) => (isFiniteNumber(degrees) ? (degrees * Math.PI) / 180 : null);

// Convert from radians to degrees.
export const radToDeg = (radians: number | Nil) => (isFiniteNumber(radians) ? ((radians || 0) * 180) / Math.PI : null);

// Converts meters to centimeters
export const convertMetersToCm = (meters: number | Nil) => (isFiniteNumber(meters) ? meters * 100 : null);

export const convertRatioToPercentage: (numberBetween0And1: number | Nil) => number | null = convertMetersToCm; // GIFLENS-https://media1.giphy.com/media/26his6ZeMjoxEKhlS/200.gif

// ATH!
export const roundNumberTo = (val: number | Nil, places = 1) =>
    isFiniteNumber(val) && isFiniteNumber(places) ? parseFloat(val.toFixed(places)) : null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isFiniteNumber:(val:any) => val is number = _.isFinite as any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function asFinitePositiveNumberOrNull(val:any):number|null {
    return isFiniteNumber(val) && val > 0
        ? val
        : null;
}
