export function ScrubberHandle({ width = 34, height }: { width?: number; height?: number }) {
    const w = width;
    const h = height ?? width;

    return (
        <svg width={w} height={h} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="17" cy="17" r="14" fill="#FFFFFF" />
            <path
                d="M0.749999 17C0.749999 20.2139 1.70305 23.3557 3.48862 26.028C5.27419 28.7003 7.81209 30.7831 10.7814 32.013C13.7507 33.243 17.018 33.5648 20.1702 32.9378C23.3224 32.3107 26.2179 30.7631 28.4905 28.4905C30.7631 26.2179 32.3107 23.3224 32.9378 20.1702C33.5648 17.018 33.243 13.7507 32.013 10.7814C30.7831 7.81209 28.7003 5.27419 26.028 3.48861C23.3557 1.70304 20.2139 0.749995 17 0.749995C12.6916 0.754545 8.56102 2.46806 5.51454 5.51454C2.46806 8.56102 0.754549 12.6916 0.749999 17ZM21.6344 11.1156L26.6344 16.1156C26.7506 16.2317 26.8428 16.3696 26.9057 16.5213C26.9686 16.6731 27.001 16.8357 27.001 17C27.001 17.1643 26.9686 17.3269 26.9057 17.4787C26.8428 17.6304 26.7506 17.7683 26.6344 17.8844L21.6344 22.8844C21.3998 23.1189 21.0817 23.2507 20.75 23.2507C20.4183 23.2507 20.1002 23.1189 19.8656 22.8844C19.6311 22.6498 19.4993 22.3317 19.4993 22C19.4993 21.6683 19.6311 21.3502 19.8656 21.1156L23.9828 17L19.8656 12.8844C19.6311 12.6498 19.4993 12.3317 19.4993 12C19.4993 11.6683 19.6311 11.3502 19.8656 11.1156C20.1002 10.8811 20.4183 10.7493 20.75 10.7493C21.0817 10.7493 21.3998 10.8811 21.6344 11.1156ZM14.1344 11.1156C14.2506 11.2317 14.3428 11.3696 14.4057 11.5213C14.4686 11.6731 14.501 11.8357 14.501 12C14.501 12.1643 14.4686 12.3269 14.4057 12.4787C14.3428 12.6304 14.2506 12.7683 14.1344 12.8844L10.0172 17L14.1344 21.1156C14.3689 21.3502 14.5007 21.6683 14.5007 22C14.5007 22.3317 14.3689 22.6498 14.1344 22.8844C13.8998 23.1189 13.5817 23.2507 13.25 23.2507C12.9183 23.2507 12.6002 23.1189 12.3656 22.8844L7.36562 17.8844C7.2494 17.7683 7.1572 17.6304 7.0943 17.4787C7.03139 17.3269 6.99902 17.1643 6.99902 17C6.99902 16.8357 7.03139 16.6731 7.0943 16.5213C7.1572 16.3696 7.2494 16.2317 7.36562 16.1156L12.3656 11.1156C12.4817 10.9994 12.6196 10.9072 12.7713 10.8443C12.9231 10.7814 13.0857 10.749 13.25 10.749C13.4143 10.749 13.5769 10.7814 13.7287 10.8443C13.8804 10.9072 14.0183 10.9994 14.1344 11.1156Z"
                fill="#3B82F6"
            />
        </svg>
    );
}
