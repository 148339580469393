import { useEffect } from 'react';
import type { SupabaseClient } from '@supabase/supabase-js';
import { shallow } from 'zustand/shallow';

import { getActivityClub, getActivityTags } from '@common';
import { SystemMessageBanner } from '@common/ui';
import Navigation from '../components/Navigation';
import { useUIComponentValues, useGlobalStore } from '../state/globalStore';
import UIComponentsLayout from '../components/UIComponentsLayout';
import Scrubber from '../components/Scrubber/Scrubber';
import { useCommunicationSocketStore } from '../state/communicationSocketStore';
import { useUserSettingsStore } from '../state/userSettingsStore';
import OldActivityTopBar from '../components/OldActivityTopBar';
import { ScreenTitle } from '../components/ScreenTitle';
import { type SocketPayload, useRelaySocketStore } from '../state/relaySocketStore';

import * as css from './screens.css';

type SyncMessage = [
    SocketPayload['activeLayout'],
    SocketPayload['userSettings'],
    SocketPayload['playbackSettings'],
    SocketPayload['activityClub'],
    SocketPayload['activityTags'],
    SocketPayload['activityId'],
];

type KioskProps = {
    supabase: SupabaseClient;
};

export default function KioskScreen({ supabase }: KioskProps) {
    const activityId = useUserSettingsStore((state) => state.activityId);
    const loadedActivities = useGlobalStore((s) => s.loadedActivities);
    const viewingActivityId = useGlobalStore((s) => s.viewingActivityId);

    const [setActivityClub, setActivityTags] = useUserSettingsStore((state) => [
        state.actions.setActivityClub,
        state.actions.setActivityTags,
    ]);

    const [activeLayout, uiNodes] = useUserSettingsStore((state) => [state.activeLayout, state.uiNodeTree]);

    const userSettingsLoaded = activeLayout && uiNodes;

    const currentActivity = loadedActivities?.find((a) => a.id === activityId);

    const isViewingOldActivity = viewingActivityId !== activityId;

    useEffect(() => {
        // TODO: Move this from effect.

        const getClubAndTags = async () => {
            if (activityId) {
                const activityClub = await getActivityClub(supabase, activityId);
                if (activityClub) {
                    setActivityClub(activityClub);
                }
                const activityTags = await getActivityTags(supabase, activityId);
                if (activityTags) {
                    setActivityTags(activityTags);
                }
            }
        };

        getClubAndTags();
    }, [activityId, setActivityClub, setActivityTags, supabase]);

    const sendSyncMessage = useRelaySocketStore((state) => state.actions.sendSyncMessage);

    /**
     * Used here to be able to display the "Reconnecting... " message for the user
     */
    const isConnected = useCommunicationSocketStore((state) => state.isConnected);

    // Every piece of Kiosk state that should be synced with the floor.
    useEffect(() => {
        const unsubscribe = useUserSettingsStore.subscribe(
            (state) =>
                [
                    state.activeLayout,
                    state.userSettings,
                    state.playbackSettings,
                    state.activityClub,
                    state.activityTags,
                    state.activityId,
                ] as SyncMessage,
            ([activeLayout, userSettings, playbackSettings, activityClub, activityTags, activityId]: SyncMessage) => {
                if (typeof sendSyncMessage === 'function') {
                    sendSyncMessage({
                        activeLayout,
                        userSettings,
                        playbackSettings,
                        activityClub,
                        activityTags,
                        activityId,
                    });
                }
            },
            {
                fireImmediately: true,
                equalityFn: shallow,
            },
        );

        return () => {
            unsubscribe();
        };
    }, [sendSyncMessage]);

    const values$ = useUIComponentValues();

    return (
        <div className={css.root}>
            <ScreenTitle title="Elva Golf — Kiosk" />
            <SystemMessageBanner message="Disconnected, trying to reconnect..." isVisible={!isConnected} />

            {isViewingOldActivity && <OldActivityTopBar activity={currentActivity} />}

            <div className={css.screen}>
                <Navigation />
                {userSettingsLoaded && (
                    <UIComponentsLayout
                        uiNodeTree={uiNodes}
                        layout={activeLayout}
                        currentDevice="kiosk"
                        values$={values$}
                    />
                )}
                <Scrubber />
            </div>
        </div>
    );
}
