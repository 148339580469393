import _ from 'lodash';

export type GridCssStyle = {
    gridColumn: string;
    gridRow: string;
    scale: string;
    size: string;
};

export function getGridStyles(moduleCount: number): GridCssStyle[] {
    switch (moduleCount) {
        case 0:
            return [];
        case 1:
            return [
                {
                    gridColumn: '1 / span 2',
                    gridRow: ' 1 / span 2',
                    scale: '1',
                    size: 'large',
                },
            ];
        case 2:
            return [
                {
                    gridColumn: '1 / span 2',
                    gridRow: ' 1 / span 1',
                    scale: '0.8',
                    size: 'medium',
                },
                {
                    gridColumn: '1 / span 2',
                    gridRow: ' 2 / span 1',
                    scale: '0.8',
                    size: 'medium',
                },
            ];
        case 3:
            return [
                {
                    gridColumn: '1 / span 2',
                    gridRow: ' 1 / span 1',
                    scale: '0.8',
                    size: 'medium',
                },
                {
                    gridColumn: '1 / span 1',
                    gridRow: ' 2 / span 1',
                    scale: '0.65',
                    size: 'small',
                },
                {
                    gridColumn: '2 / span 1',
                    gridRow: ' 2 / span 1',
                    scale: '0.65',
                    size: 'small',
                },
            ];
        case 4:
            return [
                {
                    gridColumn: '1 / span 1',
                    gridRow: ' 1 / span 1',
                    scale: '0.65',
                    size: 'small',
                },
                {
                    gridColumn: '1 / span 1',
                    gridRow: ' 2 / span 1',
                    scale: '0.65',
                    size: 'small',
                },
                {
                    gridColumn: '2 / span 1',
                    gridRow: ' 1 / span 1',
                    scale: '0.65',
                    size: 'small',
                },
                {
                    gridColumn: '2 / span 1',
                    gridRow: ' 2 / span 1',
                    scale: '0.65',
                    size: 'small',
                },
            ];

        default:
            return [
                {
                    gridColumn: '1 / span 2',
                    gridRow: ' 1 / span 2',
                    scale: '0.9',
                    size: 'large',
                },
            ];
    }
}

export function zipGridStyles<T>(items: T[]): { item: T; style: Record<string, string> }[] {
    const childCount = _.size(items);
    const layoutConfigurations = getGridStyles(childCount);
    return _.map(items, (item, index) => ({
        item,
        style: layoutConfigurations[index % layoutConfigurations.length],
    }));
}
