// TODO: @common
import * as css from './Spinner.css';

function Spinner({ size = 's' }: { size: 's' | 'm' | 'l' }) {
    return <div className={css.spinner({ size })}></div>;
}

export function SpinnerWrapper({ children }: { children: React.ReactNode }) {
    return <div className={css.spinnerWrapper}>{children}</div>;
}

export default Spinner;
