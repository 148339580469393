import { motion } from 'motion/react';
import { Dialog, DialogDismiss, DialogHeading } from '@ariakit/react';
import { type ReactNode } from 'react';
import { XCircle as XCircleIcon } from '@phosphor-icons/react';

import { colors } from '../../colors';

import * as css from './Modal.css';

export interface ModalProps {
    title: string;
    children: ReactNode;
    footer: { primary?: ReactNode; secondary?: ReactNode } | null;
    onDismiss: () => void;
}

export function Modal({ title, children, footer, onDismiss }: ModalProps) {
    return (
        <Dialog
            open
            onClose={onDismiss}
            backdrop={
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    style={{
                        backgroundColor: 'rgb(0,0,0,0.3)',
                    }}
                />
            }
        >
            <div className={css.modal}>
                <div className={css.header}>
                    <DialogHeading className={css.title}>{title}</DialogHeading>
                    <DialogDismiss className={css.dismiss}>
                        <XCircleIcon width={20} height={20} weight="fill" color={colors.blue[500]} />
                    </DialogDismiss>
                </div>
                <div className={css.body}>{children}</div>
                <div className={css.footer}>
                    <div className={css.footerPartition}>{footer?.secondary}</div>
                    <div className={css.footerPartition}>{footer?.primary}</div>
                </div>
            </div>
        </Dialog>
    );
}
