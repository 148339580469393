export function Clock({
    width = 24,
    height,
    variant = 'filled',
}: {
    width?: number;
    height?: number;
    variant?: 'border' | 'filled';
}) {
    const w = width;
    const h = height ?? width;

    return variant === 'filled' ? (
        <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 2.25A9.75 9.75 0 1 0 21.75 12 9.76 9.76 0 0 0 12 2.25Zm5.25 10.5H12a.75.75 0 0 1-.75-.75V6.75a.75.75 0 1 1 1.5 0v4.5h4.5a.75.75 0 1 1 0 1.5Z"
                fill="#3B82F6"
            />
        </svg>
    ) : (
        <svg viewBox="0 0 16 17" width={w} height={h} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 2a6.5 6.5 0 1 0 6.5 6.5A6.507 6.507 0 0 0 8 2Zm0 12a5.5 5.5 0 1 1 5.5-5.5A5.506 5.506 0 0 1 8 14Zm4-5.5a.5.5 0 0 1-.5.5H8a.5.5 0 0 1-.5-.5V5a.5.5 0 1 1 1 0v3h3a.5.5 0 0 1 .5.5Z"
                fill="#475569"
            />
        </svg>
    );
}
