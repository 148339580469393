import 'react-datepicker/dist/react-datepicker.css';
import { useState } from 'react';
import { sub } from 'date-fns';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';

import ElvaLogoName from '../../components/deprecated_ui/Logo/ElvaLogoName';
import { ArrowClockwise } from '../../components/deprecated_ui/icons/ArrowClockwise';
import { FolderStar } from '../../components/deprecated_ui/icons/FolderStar';
import { Club as ClubIcon } from '../../components/deprecated_ui/icons/Club';
import { SearchInput } from '../../components/SearchInput/SearchInput';
import { Star } from '../../components/deprecated_ui/icons/Star';
import { Clock } from '../../components/deprecated_ui/icons/Clock';
import { useGlobalStore } from '../../state/globalStore';
import { ClubTableModal } from '../../components/modals/ClubTableModal';
import Button from '../../components/deprecated_ui/Button';
import type { ActivitiesListProps, ActivityOverViewItemProps } from './ActivityOverview.types';
import Spinner from '../../components/Spinner/Spinner';
import DataManager from '../../DataManager';
import type { Activity, Club } from '@common';
import { formatDate, formatTime } from '@common';
import { typography } from '@common/ui';
import { useUserSettingsStore } from '../../state/userSettingsStore';

import * as css from './ActivityOverview.css';

export function ActivityOverview() {
    const [filter, setFilter] = useState('');
    const initialStartDate = sub(new Date().setHours(0, 0, 0), { years: 1 });
    const [startDate, setStartDate] = useState<Date | null>(initialStartDate);
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [favoritesOn, setFavoritesOn] = useState(false);
    const [clubFilter, setClubFilter] = useState<Club | undefined>(undefined);
    const [modalOpen, setModalOpen] = useState(false);
    const userSettingsStore = useUserSettingsStore();

    const [swings, activities, currentActivity, numberOfFavoriteSwings] = useGlobalStore((s) => [
        s.swings,
        s.loadedActivities,
        s.actions.getViewingActivity(),
        s.actions.getNumberOfFavoriteSwings(),
    ]);

    const navigate = useNavigate();

    // Filters by date
    const dateRangeActivities = activities?.filter((activity) => {
        const activityDate = new Date(activity.start_time);
        return (!startDate || activityDate >= startDate) && (!endDate || activityDate <= endDate);
    });

    // This replaces the currency activity in the list to reflect the activity data e.g. swings count, tags and club
    const updatedActivities = dateRangeActivities?.map((activity) => {
        const numberOfFavoriteSwings = swings.filter((s) => s.isFavorite).length;
        if (activity.id === currentActivity?.id) {
            return {
                ...currentActivity,
                tags: userSettingsStore.activityTags,
                club: userSettingsStore.activityClub,
                swing_count: swings.length,
                favorite_swings_count: numberOfFavoriteSwings,
            };
        }
        return activity;
    });

    // Filters by search
    const filteredActivities =
        updatedActivities?.filter((activity) => {
            const hasFavorites = favoritesOn ? (activity?.favorite_swings_count ?? 0) > 0 : true;

            const matchesClubFilter = clubFilter ? activity.club_id === clubFilter.id : true;

            const matchesFilter =
                filter.length > 0
                    ? activity.tags?.some((tag) => tag.name.toLowerCase().includes(filter.toLowerCase()))
                    : true;

            return hasFavorites && matchesClubFilter && matchesFilter;
        }) || []; // Default to an empty array if undefined

    const showNoResultsMessage = filteredActivities?.length <= 0;

    const resetFilter = () => {
        setClubFilter(undefined);
        setFavoritesOn(false);
        setFilter('');
        setStartDate(initialStartDate);
        setEndDate(new Date());
    };

    const handleActivityClick = async (activity: Activity) => {
        if (activity) {
            const dm = DataManager.getInstance();
            if (!dm) {
                return;
            }

            dm.loadActivity(activity.id);
        }
        navigate('/kiosk');
    };

    const handleCloseClick = () => {
        navigate('/kiosk');
    };

    return (
        <div className={css.root}>
            <div className={css.header}>
                <ElvaLogoName />
                <div className={css.rightSide}>
                    <Button variant="secondary" onClick={handleCloseClick}>
                        Close
                    </Button>
                </div>
            </div>
            <div className={css.content}>
                <div className={css.sidebar}>
                    <div className={css.filterBox}>
                        <div className={css.filterBoxTopBottom}>
                            <p
                                className={typography({
                                    variant: 'h2',
                                })}
                            >
                                Activities
                            </p>
                            <p>{activities?.length ?? '-'}</p>
                        </div>
                        <div className={css.datesRange}>
                            <p
                                className={typography({
                                    variant: 'h4',
                                })}
                            >
                                Date range
                            </p>
                            <div className={css.dates}>
                                <div className={css.dateBox}>
                                    <p
                                        className={typography({
                                            variant: 'h4',
                                        })}
                                    >
                                        From
                                    </p>
                                    <DatePicker
                                        className={css.date}
                                        selected={startDate}
                                        onChange={(date) => {
                                            date?.setHours(0, 0, 0);
                                            setStartDate(date);
                                        }}
                                    />
                                </div>
                                <div className={css.dateBox}>
                                    <p
                                        className={typography({
                                            variant: 'h4',
                                        })}
                                    >
                                        To
                                    </p>
                                    <DatePicker
                                        className={css.date}
                                        selected={endDate}
                                        onChange={(date) => {
                                            date?.setHours(23, 59, 59);
                                            setEndDate(date);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <SearchInput value={filter} onChange={setFilter} placeholder="Filter by keywords" />
                        <div className={css.filterBoxTopBottom}>
                            <button
                                onClick={() => setFavoritesOn((val) => !val)}
                                className={css.toggleIconButton({ active: favoritesOn })}
                            >
                                <FolderStar color="inherit" />
                                <p
                                    className={typography({
                                        variant: 'h4',
                                    })}
                                >
                                    Favorites
                                </p>
                            </button>
                            <button onClick={() => setModalOpen(true)} className={css.borderIconButton}>
                                <ClubIcon />
                                <p
                                    className={typography({
                                        variant: 'h3',
                                    })}
                                >
                                    {clubFilter ? clubFilter.long_name : 'Filter by club'}
                                </p>
                            </button>
                        </div>
                    </div>
                    <button onClick={resetFilter} className={css.borderIconButton}>
                        <ArrowClockwise />
                        <p
                            className={typography({
                                variant: 'h4',
                            })}
                        >
                            Reset Filters
                        </p>
                    </button>
                </div>
                <div className={css.activities}>
                    <div className={css.favorites}>
                        <div className={css.favoriteCard}>
                            <p
                                className={typography({
                                    variant: 'h3',
                                })}
                            >
                                All favorite swings
                            </p>
                            <div className={css.tags}>
                                <div className={css.tag}>
                                    <p>Swing</p>
                                </div>
                                <div className={css.tag}>
                                    <p>{numberOfFavoriteSwings}</p>
                                    <Star />
                                </div>
                            </div>
                        </div>
                        <div className={css.favoriteCard}>
                            <p
                                className={typography({
                                    variant: 'h3',
                                })}
                            >
                                ELVA comparison swings
                            </p>
                            <div className={css.tag}>
                                <p>0</p>
                                <Star />
                            </div>
                        </div>
                    </div>

                    <ActivitiesList
                        isLoading={activities === null}
                        showNoResultsMessage={showNoResultsMessage}
                        activities={filteredActivities}
                        onActivityClicked={handleActivityClick}
                    />
                </div>
            </div>
            <ClubTableModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                onClubClick={(club) => {
                    if (clubFilter?.id === club.id) {
                        setClubFilter(undefined);
                    } else {
                        setClubFilter(club);
                    }
                    setModalOpen(false);
                }}
                selectedClub={clubFilter}
            />
        </div>
    );
}

/**
 * The list of activities
 */
export const ActivitiesList = ({
    activities,
    onActivityClicked,
    showNoResultsMessage,
    isLoading,
}: ActivitiesListProps) => {
    return (
        <div className={css.activitiesList}>
            {isLoading && (
                <div className={css.centered}>
                    <Spinner size="m" />
                </div>
            )}
            {!isLoading && showNoResultsMessage && (
                <div className={css.centered}>
                    <p
                        className={typography({
                            variant: 'p',
                        })}
                    >
                        No results
                    </p>
                </div>
            )}

            {activities.map((activity, index) => {
                const isCurrentActivity = index === 0 ? true : false;
                return (
                    <ActivityOverViewItem
                        key={activity.id}
                        isCurrentActivity={isCurrentActivity}
                        activity={activity}
                        index={index}
                        onClick={() => {
                            onActivityClicked(activity);
                        }}
                        starredSwingsCount={activity.favorite_swings_count ?? 0}
                        noOfSwings={activity.swing_count ?? 0}
                    />
                );
            })}
        </div>
    );
};

const ActivityOverViewItem = ({
    isCurrentActivity = false,
    activity,
    index,
    starredSwingsCount,
    onClick,
    noOfSwings,
}: ActivityOverViewItemProps) => {
    return (
        <div
            key={activity.id}
            className={`${css.activity} ${css.activityItem}`}
            style={{ animationDelay: `${index * 0.03}s` }}
            onClick={onClick}
        >
            <div className={css.activityHeader}>
                <p
                    className={typography({
                        variant: 'h3',
                    })}
                >
                    {formatDate(activity.start_time)}
                </p>
                <Clock width={16} variant="border" />
                <p
                    className={typography({
                        variant: 'h3',
                    })}
                >
                    {formatTime(activity.start_time)}
                </p>
            </div>
            <div className={css.tags}>
                {isCurrentActivity && <div className={css.greenTag}>CURRENT ACTIVITY</div>}
                {activity.club_id && <div className={css.tag}>{activity?.club?.short_name}</div>}
                <div className={css.swingCountTag}>
                    <div className={css.numberOfSwings}>{noOfSwings}</div>
                    <div className={css.numberOfStarredSwings}>
                        {starredSwingsCount}
                        <Star />
                    </div>
                </div>
                {activity.tags?.map((tag) => {
                    return (
                        <div key={tag.id} className={css.tag}>
                            {tag.name}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
