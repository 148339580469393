import { AnyUINode, Device, Layout, LayoutCustomization, MetadataOf, UINodeTree, UINodeType } from './ui-node.types';
import { Mutable, Nil } from './type-utils';
import _ from 'lodash';
import { interpolateChildMetadata } from './ui-node.utils';

export interface UINodeToggler<T extends UINodeType = UINodeType> {
    layout: Layout;
    parentNode?: AnyUINode | Nil;
    childNode: Extract<AnyUINode, { type: T }>;
    currentDevices: Device[];
    defaultDevices?: Device[];
    defaultChildMetadata?: LayoutCustomization['child_metadata'] | Nil;
    onChange?: () => void;
}

export function getLayoutCustomization<T extends UINodeType = UINodeType>(
    toggler: UINodeToggler<T>,
): LayoutCustomization | null {
    return (
        _.find(
            toggler.layout.customizations,
            (c) =>
                c.child_ui_node_id === toggler.childNode?.id &&
                (c.parent_ui_node_id ?? null) === (toggler.parentNode?.id ?? null),
        ) ?? null
    );
}

export function isShown(toggler: UINodeToggler): boolean | null {
    const isShownOn = getLayoutCustomization(toggler)?.show_child_on;
    if (!isShownOn) return null;
    return !!isShownOn.length && _.every(toggler.currentDevices, (d) => _.includes(isShownOn, d));
}

export function isToggledOn(toggler: UINodeToggler): boolean {
    // if there's layout customization
    // and its show_child_on is assigned
    // then we return that value
    return (
        isShown(toggler) ??
        // else we return the default value
        isToggledOnByDefault(toggler)
    );
}

export function isToggledOnByDefault(toggler: UINodeToggler): boolean {
    return _.some(toggler.defaultDevices, (d) => _.includes(toggler.currentDevices, d));
}

export function toggle(toggler: UINodeToggler) {
    return isToggledOn(toggler) ? toggleOff(toggler) : toggleOn(toggler);
}

export function setToggle(toggler: UINodeToggler, isOn: boolean) {
    if (isOn === isToggledOn(toggler)) return;

    return isOn ? toggleOn(toggler) : toggleOff(toggler);
}

export function toggleOn(toggler: UINodeToggler) {
    const layoutCustomization = getLayoutCustomization(toggler);
    // if layoutCustomization exists
    // we make sure it's visible on all devices
    if (layoutCustomization) {
        (layoutCustomization as Mutable<LayoutCustomization>).show_child_on = _.uniq([
            ...(toggler.defaultDevices || []),
            ...toggler.currentDevices,
        ]);
    }
    // else if there is no relation that is visible on all devices
    // i.e. if it's toggled off by default
    else if (!isShown(toggler)) {
        createLayoutCustomization(toggler, true);
    }

    toggler.onChange?.();
}

export function toggleOff(toggler: UINodeToggler) {
    // if there is no relation that is visible on current devices
    // i.e. if it's toggled off by default
    if (!isToggledOnByDefault(toggler)) {
        // then we first find the layout customization
        const layoutCustomization = getLayoutCustomization(toggler);

        // check if we should just hide it (which also hides the subtree)
        if (toggler.childNode.type === 'module') {
            (layoutCustomization as Mutable<LayoutCustomization>).show_child_on = [...(toggler.defaultDevices || [])];
            toggler.onChange?.();
            return;
        }

        // else we find the entire subtree customizations (if any)
        const getSubtree = (parent: LayoutCustomization | Nil): LayoutCustomization[] =>
            parent
                ? _(toggler.layout.customizations)
                      .filter((c) => c.parent_ui_node_id === parent.child_ui_node_id)
                      .flatMap(getSubtree)
                      .concat([parent])
                      .value()
                : [];

        // and simply remove the layout customization along with its subtree
        _.pullAll(toggler.layout.customizations, getSubtree(layoutCustomization));

        toggler.onChange?.();
        return;
    }

    // else we'll need to override the default behavior
    // and make sure it's not visible on any device

    // by either modifying the existing layout customization
    const layoutCustomization = getLayoutCustomization(toggler);
    if (layoutCustomization) {
        if (layoutCustomization.show_child_on) {
            _.pullAll(layoutCustomization.show_child_on, toggler.currentDevices);
        } else {
            (layoutCustomization as Mutable<LayoutCustomization>).show_child_on = [...(toggler.defaultDevices || [])];
        }
        toggler.onChange?.();
        return;
    }

    // or creating a new one that is not visible on any device
    createLayoutCustomization(toggler, false);
    toggler.onChange?.();
}

export function createLayoutCustomization<T extends UINodeType = UINodeType>(
    toggler: UINodeToggler<T>,
    show: boolean,
): Mutable<LayoutCustomization> {
    const layoutCustomization = {
        parent_ui_node_id: toggler.parentNode?.id ?? null,
        child_ui_node_id: toggler.childNode?.id,
        child_display_order: null as number | null,
        child_metadata: _.cloneDeep(toggler.defaultChildMetadata || {}),
        show_child_on: show
            ? _.union(toggler.defaultDevices, toggler.currentDevices)
            : _.without(toggler.defaultDevices, ...toggler.currentDevices),
    };
    toggler.layout.customizations.push(layoutCustomization);
    return layoutCustomization;
}

export function getDisplayOrder(toggler: UINodeToggler): number | null {
    const layoutCustomization = getLayoutCustomization(toggler);
    return layoutCustomization?.child_display_order ?? null;
}

export function applyDisplayOrder(togglers: UINodeToggler[]): void {
    if (_.isEmpty(togglers)) return;

    _.each(togglers, (t, i) => {
        const layoutCustomization = getLayoutCustomization(t);
        if (layoutCustomization) (layoutCustomization as Mutable<LayoutCustomization>).child_display_order = i;
    });
    togglers[0].onChange?.();
}

export function deferOnChange(
    togglers: UINodeToggler[],
    action: (toggler: UINodeToggler) => void,
    onChange?: () => void,
) {
    deferAllOnChange(
        togglers,
        (list) => {
            for (const t of list) action(t);
        },
        onChange,
    );
}

export function deferAllOnChange(
    togglers: UINodeToggler[],
    action: (togglers: UINodeToggler[]) => void,
    onChange?: () => void,
) {
    if (_.isEmpty(togglers) || typeof action !== 'function') return;

    // temporary onChange function to track if onChanged was called
    let didChange = false;
    const tempOnChange = () => {
        didChange = true;
    };

    // copy the array just in case
    const togglersCopy = [...togglers];

    // swap onChange functions with the temporary one
    const originals = _.map(togglersCopy, (t) => {
        const original = t.onChange;
        t.onChange = tempOnChange;
        return original;
    });

    try {
        // perform the action without the side effect of onChange
        action(togglersCopy);
    } catch (e) {
        console.error(e);
    } finally {
        // restore the onChange functions as they were before
        _.forEach(togglersCopy, (t, i) => {
            t.onChange = originals[i];
        });

        // call one of the onChange function if something changed
        if (didChange) (onChange ?? _.find(originals, (c) => c))?.();
    }
}

export function getMetadata<T extends UINodeType = UINodeType>(
    toggler: UINodeToggler<T>,
    uiNodeTree: UINodeTree,
): MetadataOf<T> {
    return interpolateChildMetadata(
        getLayoutCustomization(toggler) ?? ({ child_metadata: {} } as LayoutCustomization),
        uiNodeTree.relations,
        uiNodeTree.nodes,
    ) as MetadataOf<T>;
}

export function setMetadata<T extends UINodeType = UINodeType>(
    toggler: UINodeToggler<T>,
    metadata: MetadataOf<T>,
): void {
    const layoutCustomization = getLayoutCustomization(toggler);
    if (layoutCustomization) {
        (layoutCustomization as Mutable<LayoutCustomization>).child_metadata = metadata;
    } else {
        createLayoutCustomization(toggler, true).child_metadata = metadata;
    }
    toggler.onChange?.();
}

export function getTogglerTitle(toggler: UINodeToggler, uiNodeTree: UINodeTree | Nil): string {
    return (
        toggler.childNode.name?.value ||
        (uiNodeTree &&
            toggler.childNode.type === 'module' &&
            getMetadata(toggler as UINodeToggler<'module'>, uiNodeTree)?.title) ||
        _.startCase(toggler.childNode.id)
    );
}
