import { useEffect, useLayoutEffect, useState } from 'react';
import useMeasure from 'react-use-measure';
import _ from 'lodash';

import {
    type SwingPosition,
    type UIParameter,
    getLocalizedSwingPositionName,
    localize,
    type IVideo,
    type Nil,
    Segmentation,
} from '@common';
import type { UIComponentProps } from '../../UIComponent.types';
import UIComponent from '../../UIComponent';
import { captureVideoFrame } from './SwingFoundationsRenderer.utils';
import { typography } from '@common/ui';
import type { CameraAngles } from '../../../../utils/types/camera';

import * as css from './SwingFoundationsRenderer.floor.css';

interface SwingFoundationsPositionRendererProps {
    readonly position: SwingPosition;
    readonly videos: IVideo[] | Nil;
    readonly segmentation: Segmentation | Nil;
    readonly parameterProps: UIComponentProps<UIParameter>[];
}

export function SwingFoundationsRenderer({
    position,
    videos,
    segmentation,
    parameterProps,
}: SwingFoundationsPositionRendererProps) {
    const title = getLocalizedSwingPositionName(position);
    const subtitle = localize('module_type.swing_foundations');
    const angle: CameraAngles = position === 'p2' ? 'down_the_line' : 'face_on';

    // Ideal height for a custom module parameter
    const MODULE_HEIGHT = 80;
    // The whole width of the page
    const PAGE_WIDTH = 1690;

    const [dimensions, setDimensions] = useState({ columns: 3, rows: 1, maxItems: 3 });
    const [ref, { width, height }] = useMeasure();

    useLayoutEffect(() => {
        const columns = width >= PAGE_WIDTH ? 4 : width >= PAGE_WIDTH / 2 ? 3 : 2;
        const rowHeight = MODULE_HEIGHT;
        const rows = Math.min(Math.floor(height / rowHeight), 4);
        const maxItems = rows * columns;

        console.log({ width, height, columns, rows, maxItems });
        setDimensions({ columns, rows, maxItems });
    }, [width, height]);

    return (
        <div className={css.root} ref={ref}>
            <div className={css.top}>
                <h3 className={css.title}>{title}</h3>
                <p className={css.subtitle}>{subtitle}</p>
            </div>
            <div className={css.body}>
                <VideoFrame videos={videos} angle={angle} position={position} segmentation={segmentation} />
                <FloorParametersLayout parameters={parameterProps} dimensions={dimensions} />
            </div>
        </div>
    );
}

function FloorParametersLayout({
    parameters,
    dimensions,
}: {
    parameters: UIComponentProps<UIParameter>[];
    dimensions: { columns: number; rows: number; maxItems: number };
}) {
    return (
        <div
            className={css.parametersLayout({
                columns: dimensions.columns as 3 | 4,
                rows: dimensions.rows as 1 | 2 | 3 | 4 | 5,
            })}
        >
            {_(parameters)
                .take(dimensions.maxItems)
                .map((props) => (
                    /** We need to properly unify how parameters are rendered before finishing this part */
                    <UIComponent key={props.currentNode.id} {...props} />
                ))
                .value()}
        </div>
    );
}

function VideoFrame({
    videos,
    angle = 'down_the_line',
    segmentation,
    position,
}: {
    videos: IVideo[] | Nil;
    segmentation: Segmentation | Nil;
    angle?: CameraAngles;
    position: SwingPosition;
}) {
    const [frameUrl, setFrameUrl] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    // Gross data fetching effect as a workaround while we wait for these
    // images to be part of the backend payload.
    useEffect(() => {
        let mounted = true;

        async function loadFrame() {
            if (!Array.isArray(videos) || !(videos.length >= 0)) return;

            const video = _.find(videos, (v) => v.name === angle);
            if (!video) return;

            setLoading(true);
            setError(null);

            try {
                const pos = position.toUpperCase() as keyof Segmentation;
                const frame = segmentation?.[pos];

                if (typeof frame !== 'number') {
                    return setError(localize('error_message.no_video_available'));
                }

                const result = await captureVideoFrame(video, frame);

                if (!mounted) return;

                if (result.error) {
                    setError(result.error);
                    setFrameUrl(null);
                } else if (result.frameBlob) {
                    const url = URL.createObjectURL(result.frameBlob);
                    setFrameUrl(url);
                }
            } catch (err) {
                if (!mounted) return;
                setError(err instanceof Error ? err.message : 'Failed to capture frame');
                setFrameUrl(null);
            } finally {
                if (mounted) {
                    setLoading(false);
                }
            }
        }

        loadFrame();

        return () => {
            mounted = false;
            if (frameUrl) {
                URL.revokeObjectURL(frameUrl);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videos, angle]);

    if (!Array.isArray(videos) || !(videos.length >= 0)) {
        return <div className={css.frame({ showFallback: true })} />;
    }

    const video = _.find(videos, (v) => v.name === angle);

    if (!video) {
        return (
            <div className={css.frame({ showFallback: true })}>
                <span
                    className={typography({
                        variant: 'h2',
                    })}
                >
                    {localize('error_message.no_video_available')}
                </span>
            </div>
        );
    }

    return (
        <div className={css.frame({ showFallback: false })}>
            {loading && !error && (
                <span
                    className={typography({
                        variant: 'h3',
                    })}
                >
                    {error}
                </span>
            )}
            {error && (
                <span
                    className={typography({
                        variant: 'h3',
                    })}
                >
                    {error}
                </span>
            )}
            {!loading && !error && frameUrl && (
                <img src={frameUrl} alt={`Frame from ${angle} video`} className={css.frameImg} />
            )}
        </div>
    );
}
