import _ from 'lodash';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Gear as GearIcon,
    ClockCounterClockwise as ClockIcon,
    XCircle as XCircleIcon,
    SignOut as SignOutIcon,
} from '@phosphor-icons/react';

import { localize } from '@common';
import { Menu, MenuItem, PropertyTrigger, typography } from '@common/ui';
import { useGlobalStore } from '../../state/globalStore';
import ActionButton from '../deprecated_ui/ActionButton/ActionButton';
import { useUserSettingsStore } from '../../state/userSettingsStore';
import ElvaLogoName from '../deprecated_ui/Logo/ElvaLogoName';
import { useSession } from '../../utils/hooks/useSession';
import { useBoothSession } from '../../utils/hooks/useBoothSession';

import * as css from './Navigation.css';

export const NavigationMenu = () => {
    const navigate = useNavigate();
    const { user, logout } = useSession();
    const { logout: logoutBooth } = useBoothSession();

    return (
        <Menu label="Actions" align="left" buttonSize={24} menuType="main" className={css.menu}>
            <MenuItem onClick={() => navigate('/kiosk/user-settings')} style={{ justifyContent: 'flex-start' }}>
                <GearIcon size={18} />
                <span
                    className={typography({
                        variant: 'p',
                    })}
                >
                    {localize('menu.user_settings')}
                </span>
            </MenuItem>
            <MenuItem onClick={() => navigate('/kiosk/activity-overview')} style={{ justifyContent: 'flex-start' }}>
                <ClockIcon size={18} />
                <span
                    className={typography({
                        variant: 'p',
                    })}
                >
                    {localize('menu.activity_overview')}
                </span>
            </MenuItem>

            <MenuItem onClick={logout} style={{ justifyContent: 'flex-start' }}>
                <SignOutIcon size={18} />
                <span
                    className={typography({
                        variant: 'p',
                    })}
                >
                    {localize('menu.end_session')}
                </span>
            </MenuItem>

            {user?.isAdmin && (
                <MenuItem
                    onClick={() => {
                        logoutBooth();
                        logout();
                    }}
                    style={{ justifyContent: 'flex-start' }}
                >
                    <XCircleIcon weight="fill" size={18} />
                    <span
                        className={typography({
                            variant: 'p',
                        })}
                    >
                        {localize('menu.end_booth_session')}
                    </span>
                </MenuItem>
            )}

            <MenuItem onClick={() => window.location.reload()} style={{ justifyContent: 'flex-start' }}>
                <XCircleIcon size={18} />
                <span
                    className={typography({
                        variant: 'p',
                    })}
                >
                    {localize('menu.reboot')}
                </span>
            </MenuItem>
        </Menu>
    );
};

export default function Navigation() {
    const navigate = useNavigate();
    const { user } = useSession();

    const comparisonSwingID = useGlobalStore((state) => state.comparisonSwingID);
    const { activeLayout, activityClub, activityTags, userSettings } = useUserSettingsStore(
        ({ activeLayout, activityClub, activityTags, userSettings }) => ({
            activeLayout,
            activityClub,
            activityTags,
            userSettings,
        }),
    );
    const activeLayoutName = activeLayout?.name ?? 'No Layout';

    const currentTags = useMemo(() => {
        if (!Array.isArray(activityTags) || activityTags.length === 0) {
            return [{ label: 'Select' }];
        }

        // Limit tags to
        const MAX_TAGS = 3;

        if (activityTags.length <= MAX_TAGS) {
            return activityTags.map((tag) => ({ label: tag.name }));
        }

        return [
            ...activityTags.slice(0, MAX_TAGS).map((tag) => ({ label: tag.name })),
            { label: `+${activityTags.length - MAX_TAGS}` },
        ];
    }, [activityTags]);

    const currentClub = activityClub ? { label: activityClub.short_name } : { label: 'Select' };

    return (
        <div className={css.root}>
            <ElvaLogoName />

            <div className={css.actionGroupLayout}>
                <PropertyTrigger
                    label="Club"
                    values={[currentClub]}
                    onClick={() => navigate(`/kiosk/clubs?display=${_.isEmpty(userSettings.golfBag) ? 'all' : 'bag'}`)}
                />
                <PropertyTrigger
                    label="Tags"
                    values={currentTags}
                    onClick={() => {
                        navigate('/kiosk/tags');
                    }}
                />
                <PropertyTrigger
                    label="Layout"
                    values={[{ label: activeLayoutName, icon: 'layout' }]}
                    onClick={() => {
                        // TODO: We need to add the layout's ID to the route
                        navigate('/kiosk/layout-editor');
                    }}
                />
                {false && typeof comparisonSwingID === 'number' && (
                    <PropertyTrigger
                        label="Comp."
                        values={[{ label: `#${comparisonSwingID}`, icon: 'comparison' }]}
                        onClick={() => {
                            // TODO: There's no palce to link to.
                        }}
                    />
                )}
            </div>
            <nav>
                <ul className={css.actionButtons}>
                    <li className={css.action}>
                        <ActionButton
                            text="Playback"
                            icon="camera"
                            onClick={() => {
                                navigate('/kiosk/playback');
                            }}
                        />
                    </li>

                    <li>
                        <div className={css.actionsButton}>
                            <p
                                className={typography({
                                    variant: 'h3',
                                })}
                            >
                                {user?.name ? _.startCase(user.name) : localize('menu.actions')}
                            </p>
                            <NavigationMenu />
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
