import { Navigate, Outlet } from 'react-router-dom';

import { useSession } from '../utils/hooks/useSession';

export function AuthProtectedRoute({ redirectPath }: { redirectPath: string }) {
    const { user, isLoading } = useSession();

    if (isLoading && !user) {
        return null;
    }

    if (user) {
        return <Outlet />;
    }

    return <Navigate to={redirectPath} replace />;
}
