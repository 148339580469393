import type { MouseEvent } from 'react';
import {
    Minus as MinusIcon,
    Plus as PlusIcon,
    ArrowsOutSimple as ExpandIcon,
    ArrowsInSimple as CollapseIcon,
} from '@phosphor-icons/react';

import { type ActionButtonVariants } from './ActionButton.css';
import * as css from './ActionButton.css';

const availableIcons = {
    minus: { component: MinusIcon, defaultSize: 14 },
    plus: { component: PlusIcon, defaultSize: 14 },
    expand: { component: ExpandIcon, defaultSize: 16 },
    collapse: { component: CollapseIcon, defaultSize: 16 },
};

const availableIconSizes = {
    tiny: 12,
    small: 14,
    medium: 16,
    large: 18,
};

interface ActionButtonProps {
    onClick?: (event: MouseEvent) => void;
    isDisabled?: boolean;
    buttonSize?: ActionButtonVariants['size'];
    icon?: keyof typeof availableIcons;
    iconSize?: keyof typeof availableIconSizes;
}

export function ActionButton({
    icon = 'plus',
    buttonSize = 'medium',
    onClick = () => {},
    isDisabled = false,
    iconSize,
}: ActionButtonProps) {
    const Icon = availableIcons[icon].component;
    const size = availableIconSizes[iconSize!] || availableIcons[icon].defaultSize;

    return (
        <button className={css.actionButton({ size: buttonSize })} onClick={(e) => onClick(e)} disabled={isDisabled}>
            <Icon width={size} height={size} />
        </button>
    );
}
