import type { SizeVariants } from './SelectBox.css';
import * as css from './SelectBox.css';

interface ISelectBox {
    selected?: boolean;
    size?: SizeVariants;
}

export function SelectBox({ selected, size = 'small' }: ISelectBox) {
    return (
        <div className={css.selectBox({ size })} role="checkbox" aria-checked={selected} tabIndex={0}>
            {selected && <span className={css.fill} />}
        </div>
    );
}
