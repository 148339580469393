import { Fragment, useEffect, useRef } from 'react';
import _ from 'lodash';

import TopBar from '../components/TopBar';
import { useUIComponentValues, useGlobalStore } from '../state/globalStore';
import { VideoReplay } from '../components/VideoReplay/VideoReplay.floor';
import { useUserSettingsStore } from '../state/userSettingsStore';
import { IS_DEV } from '../utils/consts';
import UIComponentsLayout from '../components/UIComponentsLayout';
import { ScreenTitle } from '../components/ScreenTitle';
import { useRelaySocketStore } from '../state/relaySocketStore';

import * as css from './FloorScreen.css';

export default function FloorScreen() {
    const userSettings = useUserSettingsStore(
        (state) =>
            [state.activeLayout, state.uiNodeTree, state.playbackSettings, state.actions.setUserSettings] as const,
    );

    const syncPayload = useRelaySocketStore(
        (state) =>
            [
                state.payload?.activeLayout,
                state.payload?.activityClub,
                state.payload?.activityTags,
                state.payload?.playbackSettings,
                state.payload?.userSettings,
                state.payload?.activityId,
            ] as const,
    );

    const [cloudLayout, uiNodeTree, userPlaybackSettings, setDeprecatedUserJSONSettings] = userSettings;
    const [layout, club, tags, playbackSettings, deprecatedUserJSONSettings, activityId] = syncPayload;
    const lastKnownActivityId = useRef(activityId);

    const shouldInstantReplay = IS_DEV
        ? false
        : Boolean(playbackSettings?.instantReplay ?? userPlaybackSettings?.instantReplay);

    const instantReplay = useGlobalStore((state) => state.instantReplay);
    const values$ = useUIComponentValues();

    const activeLayout = layout || cloudLayout;

    useEffect(() => {
        // NOTE:
        // This effect syncs incoming `userSettings` websocket message with the local `userSettings` store.
        // That's not cool.
        // TODO:
        // Save `showCorridorOn`, `language`, `golfBag`, and such in a _user layout_.
        if (deprecatedUserJSONSettings) {
            setDeprecatedUserJSONSettings({ showCorridorOn: deprecatedUserJSONSettings.showCorridorOn });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layout, deprecatedUserJSONSettings]);

    useEffect(() => {
        if (lastKnownActivityId.current !== activityId) {
            lastKnownActivityId.current = activityId;
            // Reset
            useGlobalStore.setState({
                activeSwingID: -1,
                swings: [],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityId]);

    return (
        <Fragment key="floor-screen">
            <ScreenTitle title="Elva Golf — Floor" />
            {shouldInstantReplay && instantReplay.activeId && (
                <VideoReplay
                    videos={instantReplay.videos}
                    onReplayEnded={() => {
                        instantReplay.onReplayEnded();
                    }}
                />
            )}
            <div className={css.root}>
                <TopBar
                    club={club?.short_name || ''}
                    tags={_.map(tags, (t) => t.name) || []}
                    selectedLayout={layout?.name ?? 'No layout'}
                    progressBar={<> </>}
                />
                {uiNodeTree && activeLayout && (
                    <UIComponentsLayout
                        uiNodeTree={uiNodeTree}
                        layout={activeLayout}
                        currentDevice="floor"
                        values$={values$}
                    />
                )}
            </div>
        </Fragment>
    );
}
