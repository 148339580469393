import { useContext, useEffect, useState } from 'react';
import { AnimatePresence } from 'motion/react';
import { useNavigate } from 'react-router-dom';

import { Button, Menu, MenuItem, Screen, typography } from '@common/ui';
import { Club, localize } from '@common';
import { Language, useUserSettingsStore } from '../../state/userSettingsStore';
import { UserSettingsContext } from '../../utils/contexts';
import { ClubTable } from '../../components/ClubTable';
import { useGlobalStore } from '../../state/globalStore';
import { useSession } from '../../utils/hooks/useSession';
import { PasswordModal } from './PasswordModal';

import * as css from './UserSettings.css';

const languages: Language[] = ['English', 'Íslenska', 'Deutch'];

export default function UserSettings() {
    const { userSettings, actions, availableTags, activeLayout, playbackSettings } = useUserSettingsStore();
    const userSettingsContext = useContext(UserSettingsContext);

    const { setShouldChangePassword, user, updateUser } = useSession();

    // user info before changes
    const [modalOpen, setModalOpen] = useState(false);

    // input values
    const [name, setName] = useState(user?.name || '');
    const [email, setEmail] = useState(user?.email || '');
    const [changePasswordError, setChangePasswordError] = useState<string | undefined>(undefined);
    const clubs = useGlobalStore((state) => state.clubs);

    const [selectedLanguage, setSelectedLanguage] = useState<Language>(userSettings.language);
    const [selectedClubs, setSelectedClubs] = useState<Club[]>([]);

    useEffect(() => {
        setSelectedClubs(userSettings.golfBag);
    }, [userSettings.golfBag]);

    const handleSetShouldChangePassword = async () => {
        setChangePasswordError(undefined);

        try {
            await setShouldChangePassword();
        } catch (e) {
            setChangePasswordError(e as string);
        }
    };

    const handleSave = async () => {
        actions.setUserSettings({
            language: selectedLanguage,
            golfBag: selectedClubs,
        });

        if (name !== user?.name || email !== user?.email) {
            updateUser({ name, email });
        }

        userSettingsContext?.setSettingsStore({
            availableTags,
            activeLayout,
            userSettings: {
                language: selectedLanguage,
                golfBag: selectedClubs,
            },
            playbackSettings,
        });
    };

    const navigate = useNavigate();

    return (
        <Screen.Root
            title="User settings"
            header={{
                static: {
                    end: (
                        <>
                            <Button variant="secondary" onClick={() => navigate(-1)}>
                                {localize('generic_ui.cancel')}
                            </Button>
                            <Button
                                variant="primary"
                                onClick={async () => {
                                    // TODO: Save async, display `isLoading` on the button.
                                    await handleSave();
                                    navigate(-1);
                                }}
                                // (layoutBeingEdited && updateUserLayout(layoutBeingEdited)) || Promise.resolve()
                            >
                                {localize('generic_ui.save')}
                            </Button>
                        </>
                    ),
                },
            }}
        >
            <Screen.Column span="20/20">
                <div className={css.content}>
                    <div className={css.settingsGroupsWrapper}>
                        <div className={css.accountSettings}>
                            <div className={css.inputField}>
                                <label htmlFor="input_name" className={typography({ variant: 'h4' })}>
                                    Name
                                </label>
                                <input
                                    id="input_name"
                                    className={css.input}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className={css.inputField}>
                                <label htmlFor="input_name" className={typography({ variant: 'h4' })}>
                                    Your email
                                </label>
                                <input
                                    id="input_email"
                                    className={css.input}
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            <Button variant="secondary" size="medium" onClick={() => setModalOpen(true)}>
                                Change password
                            </Button>
                        </div>
                        <div className={css.languageSettings}>
                            <p
                                className={typography({
                                    variant: 'h4',
                                })}
                            >
                                Language
                            </p>
                            <div className={css.languageButton}>
                                <p
                                    className={typography({
                                        variant: 'h3',
                                    })}
                                >
                                    {selectedLanguage}
                                </p>
                                <Menu label="" align="left" buttonSize={24} menuType="main" className={css.menu}>
                                    {languages.map((language) => {
                                        return (
                                            <MenuItem
                                                key={language}
                                                onClick={() => setSelectedLanguage(language)}
                                                style={{ minWidth: css.MENU_WIDTH + 10 }}
                                            >
                                                <span
                                                    className={typography({
                                                        variant: 'h3',
                                                    })}
                                                >
                                                    {language}
                                                </span>
                                            </MenuItem>
                                        );
                                    })}
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <div className={css.bagSettings}>
                        <p
                            className={typography({
                                variant: 'h2',
                            })}
                        >
                            My golf bag
                        </p>
                        <ClubTable
                            clubs={clubs}
                            selectedClubs={selectedClubs}
                            onClubClick={(club) =>
                                setSelectedClubs(() =>
                                    selectedClubs.find((c) => c.id === club.id)
                                        ? selectedClubs.filter((c) => c.id !== club.id)
                                        : [...selectedClubs, club],
                                )
                            }
                        />
                    </div>
                </div>
                <AnimatePresence>
                    {modalOpen && (
                        <PasswordModal
                            modalOpen={modalOpen}
                            setModalOpen={setModalOpen}
                            onConfirm={handleSetShouldChangePassword}
                            error={changePasswordError}
                        />
                    )}
                </AnimatePresence>
            </Screen.Column>
        </Screen.Root>
    );
}
