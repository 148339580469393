/* eslint-disable */
import * as THREE from 'three';

import type { CameraAngles, SimplifiedCameraConfig } from '../../utils/types/camera';

function invertCameraTransforms(cameraConfig: SimplifiedCameraConfig) {
    const inverseMatrix: Record<string, THREE.Matrix4> = {};

    for (const [camId, transf] of Object.entries(cameraConfig.cameras_in_world)) {
        const matrix = new THREE.Matrix4();
        const ndArray = transf.__ndarray__;

        // threejs expects 16 values, not a 4x4 array
        // @ts-expect-error This is ok
        matrix.set(...ndArray.flat(), 1);

        inverseMatrix[camId] = matrix.invert();
    }

    return inverseMatrix;
}

function transformPoints(transformationMatrix: THREE.Matrix4, points: number[][]) {
    return points.map((point) => {
        // Convert to a homogeneous (4D) coordinate
        const vec4 = new THREE.Vector4(point[0], point[1], point[2], 1);

        // Apply the transformation matrix
        vec4.applyMatrix4(transformationMatrix);

        // Convert back to 3D
        return [vec4.x, vec4.y, vec4.z];
    });
}

function projectPoints(points: number[][], cameraConfig: SimplifiedCameraConfig, cameraAngle: CameraAngles) {
    const cameraIntrinsics = cameraConfig.intrinsics[cameraAngle];
    return points.map(([x, y, z]) => {
        return [
            (x * cameraIntrinsics.fx) / z + cameraIntrinsics.cx,
            (y * cameraIntrinsics.fy) / z + cameraIntrinsics.cy,
        ];
    });
}

function undistortPoints(points: number[][], cameraConfig: SimplifiedCameraConfig, cameraAngle: CameraAngles) {
    const cameraIntrinsics = cameraConfig.intrinsics[cameraAngle];
    const { fx, fy, cx, cy, distortion_coeffs } = cameraIntrinsics;

    const coeffs = distortion_coeffs.__ndarray__;

    const [k1, k2, p1, p2, k3] = coeffs;

    return points.map(([x, y]) => {
        const normalizedX = (x - cx) / fx;
        const normalizedY = (y - cy) / fy;

        // Radial distortion correction calculation.
        const r2 = normalizedX ** 2 + normalizedY ** 2;
        const radialDistortion = 1 + k1 * r2 + k2 * r2 ** 2 + k3 * r2 ** 3;

        // Tangential distortion correction calculation.
        const xDistorted =
            normalizedX * radialDistortion + 2 * p1 * normalizedX * normalizedY + p2 * (r2 + 2 * normalizedX ** 2);
        const yDistorted =
            normalizedY * radialDistortion + p1 * (r2 + 2 * normalizedY ** 2) + 2 * p2 * normalizedX * normalizedY;

        // Convert back to image coordinates.
        return [xDistorted * fx + cx, yDistorted * fy + cy];
    });
}

// Function that transforms, projects and undistorts points
export const computePoints = (
    points: number[][] = [],
    cameraAngle: CameraAngles = 'face_on',
    cameraConfig: SimplifiedCameraConfig,
) => {
    const inverseCameraMatrix = invertCameraTransforms(cameraConfig)[cameraAngle];

    // Transform 3D points from world space to camera space
    const transformedPoints = transformPoints(inverseCameraMatrix, points);

    // Project transformed 3D points onto the 2D image plane
    const projectedPoints = projectPoints(transformedPoints, cameraConfig, cameraAngle);

    // Correct for lens distortion
    const undistortedPoints = undistortPoints(projectedPoints, cameraConfig, cameraAngle);

    return undistortedPoints;
};
