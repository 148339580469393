import _ from 'lodash';

import { localize } from '@common';
import { typography } from '@common/ui';
import { useVideoPlaybackStore } from '../VideoPlayback.state';
import { CameraAngles, cameraAngles } from '../../../utils/types/camera';

import * as css from './PlaybackSettings.css';

const availablePlaybackSpeeds = [0.25, 0.5, 1];

export function PlaybackSettings() {
    const [activeCameraAngle, playbackSpeed] = useVideoPlaybackStore((state) => [
        state.activeCameraAngle,
        state.playbackSpeed,
    ]);

    const handleChangingCameraAngle = (isSelected: boolean, to: CameraAngles) => {
        useVideoPlaybackStore.setState({
            activeCameraAngle: isSelected ? null : to,
            autoPlaying: false,
        });
    };

    const handleChangingSpeed = (speed: number) => {
        if (speed === playbackSpeed) {
            return;
        }

        useVideoPlaybackStore.setState({
            playbackSpeed: speed,
        });
    };

    return (
        <div className={css.root}>
            <div className={css.setting}>
                <p
                    className={typography({
                        variant: 'h4',
                    })}
                >
                    {localize('playback_settings.active_camera_angle')}
                </p>
                <div className={css.swingReplay}>
                    {_.map(cameraAngles, (angle, index) => {
                        const isSelected = angle === activeCameraAngle;
                        return (
                            <button
                                key={angle}
                                className={css.swingReplayOption({
                                    selected: isSelected,
                                })}
                                onClick={() => {
                                    handleChangingCameraAngle(isSelected, angle);
                                }}
                            >
                                <p className={css.swingReplayOptionIcon({ selected: false })}>{index + 1}</p>
                                <p className={typography({ variant: 'h3' })}>{localize(`camera_angle.${angle}`)}</p>
                            </button>
                        );
                    })}
                </div>
            </div>
            <div className={css.setting}>
                <p
                    className={typography({
                        variant: 'h4',
                    })}
                >
                    {localize('playback_settings.replay_speed')}
                </p>
                <div className={css.speedSettings}>
                    {_.map(availablePlaybackSpeeds, (speed) => {
                        const isSelected = speed === playbackSpeed;
                        return (
                            <button
                                key={speed}
                                className={css.speedOption({
                                    selected: isSelected,
                                })}
                                onClick={() => {
                                    handleChangingSpeed(speed);
                                }}
                            >
                                {speed}x
                            </button>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
