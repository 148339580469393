export function PlayPause({ width = 24, height }: { width?: number; height?: number }) {
    const w = width;
    const h = height ?? width;
    return (
        <svg viewBox="0 0 25 25" width={w} height={h} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.75 6.5v12a.75.75 0 1 1-1.5 0v-12a.75.75 0 1 1 1.5 0Zm3.75-.75a.75.75 0 0 0-.75.75v12a.75.75 0 1 0 1.5 0v-12a.75.75 0 0 0-.75-.75ZM14 12.5a1.477 1.477 0 0 1-.687 1.25l-8.268 5.265a1.493 1.493 0 0 1-2.295-1.25V7.234a1.491 1.491 0 0 1 2.295-1.25l8.268 5.264A1.477 1.477 0 0 1 14 12.5Zm-1.517 0L4.25 7.258v10.485l8.233-5.243Z"
                fill="#475569"
            />
        </svg>
    );
}

export function PlayPausePaused({ width = 42, height }: { width?: number; height?: number }) {
    const w = width;
    const h = height ?? width;
    return (
        <svg width={w} height={h} viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="43" height="43" rx="21.5" fill="#FFF" />
            <path
                d="M23 21.5001C23.0004 21.7496 22.9376 21.9952 22.8174 22.2138C22.6972 22.4325 22.5236 22.6172 22.3128 22.7507L14.045 28.0148C13.82 28.1586 13.5605 28.2394 13.2937 28.2487C13.0268 28.2581 12.7623 28.1956 12.5278 28.0678C12.2934 27.94 12.0975 27.7516 11.9607 27.5223C11.8238 27.293 11.7511 27.0312 11.75 26.7641V16.236C11.7511 15.969 11.8238 15.7071 11.9607 15.4778C12.0975 15.2485 12.2934 15.0601 12.5278 14.9323C12.7623 14.8046 13.0268 14.7421 13.2937 14.7514C13.5605 14.7607 13.82 14.8415 14.045 14.9854L22.3128 20.2494C22.5236 20.3829 22.6972 20.5676 22.8174 20.7863C22.9376 21.005 23.0004 21.2505 23 21.5001ZM21.4831 21.5001L13.25 16.2576V26.7435L21.4831 21.5001Z"
                fill="#475569"
            />
            <path
                d="M26.75 27.5V15.5C26.75 15.3011 26.671 15.1103 26.5303 14.9697C26.3897 14.829 26.1989 14.75 26 14.75C25.8011 14.75 25.6103 14.829 25.4697 14.9697C25.329 15.1103 25.25 15.3011 25.25 15.5V27.5C25.25 27.6989 25.329 27.8897 25.4697 28.0303C25.6103 28.171 25.8011 28.25 26 28.25C26.1989 28.25 26.3897 28.171 26.5303 28.0303C26.671 27.8897 26.75 27.6989 26.75 27.5Z"
                fill="#9CA5B1"
            />
            <path
                d="M29.9697 14.9697C30.1103 14.829 30.3011 14.75 30.5 14.75C30.6989 14.75 30.8897 14.829 31.0303 14.9697C31.171 15.1103 31.25 15.3011 31.25 15.5V27.5C31.25 27.6989 31.171 27.8897 31.0303 28.0303C30.8897 28.171 30.6989 28.25 30.5 28.25C30.3011 28.25 30.1103 28.171 29.9697 28.0303C29.829 27.8897 29.75 27.6989 29.75 27.5V15.5C29.75 15.3011 29.829 15.1103 29.9697 14.9697Z"
                fill="#9CA5B1"
            />
        </svg>
    );
}

export function PlayPausePlaying({ width = 42, height }: { width?: number; height?: number }) {
    const w = width;
    const h = height ?? width;
    return (
        <svg width={w} height={h} viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="43" height="43" rx="21.5" fill="#FFF" />
            <path
                d="M23 21.5001C23.0004 21.7496 22.9376 21.9952 22.8174 22.2138C22.6972 22.4325 22.5236 22.6172 22.3128 22.7507L14.045 28.0148C13.82 28.1586 13.5605 28.2394 13.2937 28.2487C13.0268 28.2581 12.7623 28.1956 12.5278 28.0678C12.2934 27.94 12.0975 27.7516 11.9607 27.5223C11.8238 27.293 11.7511 27.0312 11.75 26.7641V16.236C11.7511 15.969 11.8238 15.7071 11.9607 15.4778C12.0975 15.2485 12.2934 15.0601 12.5278 14.9323C12.7623 14.8046 13.0268 14.7421 13.2937 14.7514C13.5605 14.7607 13.82 14.8415 14.045 14.9854L22.3128 20.2494C22.5236 20.3829 22.6972 20.5676 22.8174 20.7863C22.9376 21.005 23.0004 21.2505 23 21.5001ZM21.4831 21.5001L13.25 16.2576V26.7435L21.4831 21.5001Z"
                fill="#9CA5B1"
            />
            <path
                d="M26.75 27.5V15.5C26.75 15.3011 26.671 15.1103 26.5303 14.9697C26.3897 14.829 26.1989 14.75 26 14.75C25.8011 14.75 25.6103 14.829 25.4697 14.9697C25.329 15.1103 25.25 15.3011 25.25 15.5V27.5C25.25 27.6989 25.329 27.8897 25.4697 28.0303C25.6103 28.171 25.8011 28.25 26 28.25C26.1989 28.25 26.3897 28.171 26.5303 28.0303C26.671 27.8897 26.75 27.6989 26.75 27.5Z"
                fill="#475569"
            />
            <path
                d="M29.9697 14.9697C30.1103 14.829 30.3011 14.75 30.5 14.75C30.6989 14.75 30.8897 14.829 31.0303 14.9697C31.171 15.1103 31.25 15.3011 31.25 15.5V27.5C31.25 27.6989 31.171 27.8897 31.0303 28.0303C30.8897 28.171 30.6989 28.25 30.5 28.25C30.3011 28.25 30.1103 28.171 29.9697 28.0303C29.829 27.8897 29.75 27.6989 29.75 27.5V15.5C29.75 15.3011 29.829 15.1103 29.9697 14.9697Z"
                fill="#475569"
            />
        </svg>
    );
}

export function Replay({ width = 42, height }: { width?: number; height?: number }) {
    const w = width;
    const h = height ?? width;
    return (
        <svg width={w} height={h} viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="43" height="43" rx="21.5" fill="#FFF" />
            <path
                d="M30.5 21.5001C30.5002 23.8663 29.5686 26.1373 27.9069 27.8217C26.2451 29.5061 23.9869 30.4683 21.6209 30.5001H21.5C19.2015 30.5059 16.989 29.6263 15.3219 28.0439C15.2503 27.9762 15.1927 27.895 15.1525 27.8051C15.1123 27.7151 15.0902 27.6181 15.0874 27.5196C15.0847 27.4211 15.1014 27.323 15.1365 27.231C15.1717 27.1389 15.2246 27.0547 15.2923 26.9831C15.3601 26.9115 15.4412 26.854 15.5312 26.8137C15.6211 26.7735 15.7181 26.7514 15.8166 26.7486C15.9151 26.7459 16.0132 26.7626 16.1053 26.7977C16.1973 26.8329 16.2815 26.8859 16.3531 26.9536C17.4254 27.9648 18.772 28.6377 20.2245 28.8882C21.677 29.1386 23.1711 28.9555 24.5202 28.3618C25.8692 27.768 27.0134 26.7899 27.8098 25.5496C28.6062 24.3094 29.0195 22.8619 28.9981 21.3881C28.9766 19.9144 28.5214 18.4795 27.6892 17.263C26.8571 16.0464 25.6849 15.102 24.3192 14.5477C22.9534 13.9935 21.4546 13.8539 20.01 14.1465C18.5654 14.4391 17.239 15.1509 16.1966 16.1929C16.1889 16.2012 16.1808 16.2091 16.1722 16.2164L13.6803 18.5001H16.25C16.4489 18.5001 16.6397 18.5792 16.7803 18.7198C16.921 18.8605 17 19.0512 17 19.2501C17 19.449 16.921 19.6398 16.7803 19.7805C16.6397 19.9211 16.4489 20.0001 16.25 20.0001H11.75C11.5511 20.0001 11.3603 19.9211 11.2197 19.7805C11.079 19.6398 11 19.449 11 19.2501V14.7501C11 14.5512 11.079 14.3605 11.2197 14.2198C11.3603 14.0792 11.5511 14.0001 11.75 14.0001C11.9489 14.0001 12.1397 14.0792 12.2803 14.2198C12.421 14.3605 12.5 14.5512 12.5 14.7501V17.5439L15.1484 15.1251C16.4084 13.87 18.012 13.0164 19.7567 12.6719C21.5015 12.3274 23.3092 12.5076 24.9516 13.1897C26.5941 13.8717 27.9976 15.0251 28.9851 16.5041C29.9726 17.9832 30.4997 19.7217 30.5 21.5001Z"
                fill="#475569"
            />
        </svg>
    );
}
