export const fallbackCameraConfig = {
    camera_ids: ['40329074', '40355842', '40355841', '40436943'],
    camera_id_to_name: {
        '40329074': 'face_on',
        '40355842': 'down_the_line',
        '40355841': 'trail_front',
        '40436943': 'back',
    },
    intrinsics: {
        face_on: {
            fx: 2251.7452981334623,
            fy: 2251.7452981334623,
            cx: 1266.0563454699845,
            cy: 1019.473197589182,
            width: 2464,
            height: 2064,
            distortion_coeffs: {
                __ndarray__: [-0.06045740914733812, 0.09041468949713277, 0.0, 0.0, 0.0],
            },
            distortion_model: 'opencv',
        },
        down_the_line: {
            fx: 2254.294935020753,
            fy: 2254.7937201764767,
            cx: 1199.7086220368585,
            cy: 1041.614418856588,
            width: 2464,
            height: 2064,
            distortion_coeffs: {
                __ndarray__: [-0.06145922695200645, 0.09253178022486916, 0.0, 0.0, 0.0],
            },
            distortion_model: 'opencv',
        },
        trail_front: {
            fx: 2909.9525909439026,
            fy: 2911.3273710256417,
            cx: 1234.543909841355,
            cy: 1027.420387807002,
            width: 2464,
            height: 2064,
            distortion_coeffs: {
                __ndarray__: [-0.05902035569964113, 0.07701969706575744, 0.0, 0.0, 0.0],
            },
            distortion_model: 'opencv',
        },
        back: {
            fx: 2253.998537187928,
            fy: 2253.998537187928,
            cx: 1239.4053837943004,
            cy: 979.8324934613644,
            width: 2464,
            height: 2064,
            distortion_coeffs: {
                __ndarray__: [-0.05944993956400006, 0.09048860115149147, 0.0, 0.0, 0.0],
            },
            distortion_model: 'opencv',
        },
    },
    extrinsics: {
        face_on: {
            __ndarray__: [
                [1.0, 0.0, 0.0, 0.0],
                [0.0, 1.0, 0.0, 0.0],
                [0.0, 0.0, 1.0, 0.0],
                [0.0, 0.0, 0.0, 1.0],
            ],
        },
        down_the_line: {
            __ndarray__: [
                [0.011722604322259134, 0.004701155906667541, -0.9999202366594273, 2.699826072494333],
                [0.016660312736577247, 0.9998492195296658, 0.004896139850735214, -0.07895282386604822],
                [0.9997924857326261, -0.016716379364555008, 0.011642514054837405, 2.34367441878251],
                [0.0, 0.0, 0.0, 1.0],
            ],
        },
        trail_front: {
            __ndarray__: [
                [0.7125908040761433, 0.10798560121761477, -0.6932196303306666, 2.129772235848747],
                [-0.40548366518339984, 0.8697326771685099, -0.28133266346930486, 0.8744893900827689],
                [0.5725358881463696, 0.4815643053580352, 0.6635499051235566, 2.224532548718657],
                [0.0, 0.0, 0.0, 1.0],
            ],
        },
        back: {
            __ndarray__: [
                [-0.9997509200940599, 0.014599075222612766, -0.01688089967167194, 0.025386835361667675],
                [-0.007067388836788336, 0.510360411534791, 0.859931568412899, -2.6097910496757457],
                [0.0211695485570713, 0.8598366806206184, -0.5101301136702344, 4.073243049465408],
                [0.0, 0.0, 0.0, 1.0],
            ],
        },
    },
    cameras_in_world: {
        face_on: {
            __ndarray__: [
                [0.0, -2.220446049250313e-16, -1.0, 2.3],
                [1.0, 2.220446049250313e-16, 0.0, 0.0],
                [2.220446049250313e-16, -1.0, 2.220446049250313e-16, 0.825],
                [0.0, 0.0, 0.0, 1.0],
            ],
        },
        down_the_line: {
            __ndarray__: [
                [0.9999202366594272, -0.004896139850735434, -0.011642514054837388, -0.37271102705444603],
                [0.011722604322259148, 0.016660312736577466, 0.9997924857326259, -2.373521686952259],
                [-0.004701155906667759, -0.9998492195296654, 0.016716379364555226, 0.7195736332741789],
                [0.0, 0.0, 0.0, 1.0],
            ],
        },
        trail_front: {
            __ndarray__: [
                [0.6932196303306665, 0.2813326634693047, -0.6635499051235566, 2.0536660103353097],
                [0.7125908040761431, -0.4054836651833996, 0.5725358881463696, -2.4366896654788905],
                [-0.10798560121761483, -0.8697326771685099, -0.48156430535803474, 2.886812205306893],
                [0.0, 0.0, 0.0, 1.0],
            ],
        },
        back: {
            __ndarray__: [
                [0.016880899671671945, -0.8599315684128993, 0.5101301136702345, -2.022554203028614],
                [-0.99975092009406, -0.007067388836788224, 0.021169548557071493, -0.07929261264013646],
                [-0.014599075222612994, -0.5103604115347906, -0.8598366806206186, 2.9957603732001257],
                [0.0, 0.0, 0.0, 1.0],
            ],
        },
    },
    face_on_in_global_frame: {
        translation: [2.3, 0, 0.825],
        rotation_euler_xyz: [0, 1.5707963267948966, -1.5707963267948966],
    },
};
