import _ from 'lodash';
import { type MouseEvent } from 'react';
import { Layout as LayoutIcon, Exclude as ComparisonIcon } from '@phosphor-icons/react';

import { typography } from '../../typography';

import * as css from './PropertyTrigger.css';

const availableIcons = {
    layout: { component: LayoutIcon, size: 15 },
    comparison: { component: ComparisonIcon, size: 14 },
};

interface IValue {
    label: string;
    icon?: 'layout' | 'comparison';
}

interface IPropertyTriggerProps {
    label: string;
    values: IValue[];
    onClick?: (event: MouseEvent) => void;
}

function Value({ label, icon }: IValue) {
    const Icon = icon && availableIcons[icon].component;
    return (
        <div className={css.value}>
            {Icon && (
                <span className={css.icon}>
                    <Icon size={availableIcons[icon].size} />
                </span>
            )}
            <span className={typography({ variant: 'h3' })}>{label}</span>
        </div>
    );
}

export function PropertyTrigger({ label = 'Label', values, onClick }: IPropertyTriggerProps) {
    const isClickable = typeof onClick === 'function';

    return (
        <div
            className={css.propertyTrigger}
            role={isClickable ? 'button' : undefined}
            onClick={(event) => onClick?.(event)}
        >
            <div className={css.label}>
                <span className={typography({ variant: 'h4' })}>{label}</span>
            </div>
            {_.map(values, (value) => (
                <Value key={value.label} label={value.label} icon={value.icon} />
            ))}
        </div>
    );
}
