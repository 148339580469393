export function FolderStar({
    width = 16,
    height,
    color = '#475569',
}: {
    width?: number;
    height?: number;
    color?: string;
}) {
    const w = width;
    const h = height ?? width;

    return (
        <svg viewBox="0 0 16 16" width={w} height={h} fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color }}>
            <path
                d="m14.818 10.486-1.407 1.162.428 1.732a.5.5 0 0 1-.738.55L11.5 12.99l-1.6.942a.5.5 0 0 1-.74-.551l.429-1.732-1.407-1.162a.5.5 0 0 1 .28-.883l1.864-.145.715-1.656a.5.5 0 0 1 .918 0l.715 1.656 1.865.145a.5.5 0 0 1 .28.883ZM8.035 13a.5.5 0 0 1-.5.5H2.461a.962.962 0 0 1-.961-.961V3.5a1 1 0 0 1 1-1h3.293a.991.991 0 0 1 .707.293L8.207 4.5H13.5a1 1 0 0 1 1 1v2a.5.5 0 0 1-1 0v-2h-11v7h5.035a.5.5 0 0 1 .5.5ZM2.5 4.5h4.293l-1-1H2.5v1Z"
                fill="currentColor"
            />
        </svg>
    );
}
