import _ from 'lodash';

import type { NumberRange } from '@common';
import UIComponent from '../../UIComponent';
import { ImplementationOf } from '../../UIComponent.types';

import * as css from './Page.css';

const MAX_MODULES_PER_PAGE = 4;

type Count = NumberRange<typeof MAX_MODULES_PER_PAGE, 1>;

export const Page: ImplementationOf<'page'> = ({ children, node }) => {
    const gridItems = _.take(children, MAX_MODULES_PER_PAGE);
    const count = gridItems.length as Count;

    return (
        <div className={css.grid({ numberofChildren: count })}>
            {_.map(gridItems, (child, index) => {
                const isLast = index === count - 1;
                const spanCount =
                    // The only situation that needs to be special cased is when
                    // there are 3 modules visible and the last one can stretch to fill.
                    count === 3 && isLast ? 2 : 1;
                return (
                    <div
                        key={`${node.id}_${child.currentNode.id}`}
                        className={css.item}
                        style={{ gridColumn: `span ${spanCount}` }}
                    >
                        <UIComponent {...child} />
                    </div>
                );
            })}
        </div>
    );
};
