/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Useful for optional function parameters or fields.
 * We do not return this type from functions because we never *explicitly* return `undefined`.
 * See {@link _.isNil } */
export type Nil = null | undefined;

/** Inverse of {@link Readonly<T>} */
export type Mutable<T> = {
    -readonly [P in keyof T]: T[P];
};

export type RedefineProperty<T, P extends keyof T, TRedefined> = Omit<T, P> & { [K in P]: TRedefined };
export type ReadonlyProperty<T, P extends keyof T> = Omit<T, P> & { readonly [K in P]: Readonly<T[K]> };
export type MutableProperty<T, P extends keyof T> = Omit<T, P> & { [K in P]: Mutable<T[K]> };
export type NullableProperty<T, P extends keyof T> = Omit<T, P> & { [K in P]: T[K] | null };
export type RequiredProperty<T, P extends keyof T> = Omit<T, P> & { [K in P]-?: T[K] };
export type PartialProperty<T, P extends keyof T> = Omit<T, P> & { [K in P]?: T[K] };

export function isType<T extends { type: string | number | symbol | object }, TType extends T['type']>(
    value: T,
    type: TType,
): value is Extract<T, { type: TType }>;
export function isType<T, TProp extends keyof T>(value: any, type: T[TProp], typeFieldName: TProp): value is T;
export function isType<T, TProp extends keyof T>(
    value: any,
    type: T[TProp],
    typeFieldName: TProp = 'type' as TProp,
): value is T {
    return value && typeFieldName && value[typeFieldName] === type;
}

export function assertTypeIf<T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    isValueOfGivenType: boolean,
): value is T {
    return isValueOfGivenType;
}

export function assertType<T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
): value is T {
    return true;
}

// Creates a union type of sequential numbers from Start to N+Start-1
// e.g. NumberRange<4, 1> = 1 | 2 | 3 | 4
// and NumberRange<3> = 0 | 1 | 2
export type NumberRange<
    N extends number,
    StartAt extends number = 0,
    Result extends Array<unknown> = [],
    Acc extends Array<unknown> = [],
> = Acc['length'] extends N
    ? Exclude<Result[number], undefined>
    : NumberRange<
          N,
          StartAt,
          [
              ...Result,
              StartAt extends number
                  ? Acc['length'] extends 0
                      ? StartAt
                      : [...Acc]['length'] extends infer I extends number
                        ? I
                        : never
                  : never,
          ],
          [...Acc, 1]
      >;
