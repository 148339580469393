// NOTE: This is the "Relay" Websocket handler.
// It handles sending and receiving local state between devices.

// We are using 'react-use-websocket', but are wrapping it here because of its thread blocking behavior
// see e.g. https://github.com/robtaussig/react-use-websocket/issues/234

import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import useWebSocket, { type SendMessage } from 'react-use-websocket';
import type { WebSocketMessage } from 'react-use-websocket/dist/lib/types';

import { debounce } from '../../utils/debounce';
import { UserSettingsStoreState } from '../userSettingsStore';

// NOTE: This is the payload we are sending to the Beast to keep the floor up to date.
export type SocketPayload = {
    activeLayout: UserSettingsStoreState['activeLayout'];
    userSettings: UserSettingsStoreState['userSettings'];
    playbackSettings: UserSettingsStoreState['playbackSettings'];
    activityClub: UserSettingsStoreState['activityClub'];
    activityTags: UserSettingsStoreState['activityTags'];
    activityId: UserSettingsStoreState['activityId'];
};

export const useRelaySocket = (serverUrl: string) => {
    const setSendMessage = useRelaySocketStore((state) => state.actions.setSenderFn);
    const onMessage = useRelaySocketStore((state) => state.actions.onMessage);
    const setIsConnected = useRelaySocketStore((state) => state.actions.setConnected);

    const onOpen = () => {
        setIsConnected(true);
        setSendMessage(sendJsonMessage);
    };

    const onClose = () => setIsConnected(false);

    const messageHandler = (message: MessageEvent) => {
        if (message.type === 'message' && typeof onMessage === 'function') {
            try {
                const data = JSON.parse(message.data) as SocketPayload;
                onMessage(data);
            } catch (e) {
                console.error('WebSocket data parsing error:', e);
                return;
            }
        }
    };

    const debouncedMessage = debounce(messageHandler, 100);

    const { sendJsonMessage } = useWebSocket(serverUrl, {
        onOpen: onOpen,
        onClose: onClose,
        onMessage: (message: MessageEvent) => {
            debouncedMessage(message);
        },
        filter: (message) => {
            const status = JSON.parse(message.data).type;

            if (status === 'BEAST_STATUS') {
                // Not forwarding BEAST_STATUS messages.
                return false;
            }

            return true;
        },
        // Should always reconnect
        shouldReconnect: () => true,
        reconnectAttempts: 1000, // ~ 30 minute reconnection window
        reconnectInterval: 2000, // Try reconnecting every two secs
    });
};

type relaySocketStore = {
    senderFn: SendMessage | null;
    isConnected: boolean;
    payload: SocketPayload | null;
    actions: {
        setSenderFn: (fn: SendMessage) => void;
        setConnected: (connected: boolean) => void;
        sendSyncMessage: (payload: SocketPayload) => void;
        onMessage: (payload: SocketPayload) => void;
    };
};

export const useRelaySocketStore = create<relaySocketStore>()(
    subscribeWithSelector((set, get) => ({
        senderFn: null,
        receiverFn: () => void 0,
        isConnected: false,
        payload: null,
        actions: {
            setSenderFn: (fn) => {
                set({ senderFn: fn });
            },

            setConnected: (connected) => set({ isConnected: connected }),
            sendSyncMessage: (payload) => {
                const senderFn = get().senderFn;
                if (typeof senderFn === 'function') {
                    senderFn(payload as unknown as WebSocketMessage);
                }
            },
            onMessage: (payload) => {
                set({ payload });
            },
        },
    })),
);
