// TODO: @common
import _ from 'lodash';
import { motion } from 'motion/react';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import cn from 'classnames';

// TODO: Switch out for @common/ui's typography
import { typography } from '../../styles/typography.css';

import * as css from './Switch.css';

type SwitchProps = {
    switchKey: string;
    items: { title: string; onClick: () => void; isSelected: boolean; isDisabled: boolean }[];
    backgroundColor?: string;
};

export function Switch({ switchKey, items, backgroundColor }: SwitchProps) {
    return (
        <div
            key={switchKey}
            className={css.root}
            style={assignInlineVars({
                [css.backgroundColor]: backgroundColor,
            })}
        >
            {_.map(items, (item) => {
                const selected = item.isSelected && !item.isDisabled;
                return (
                    <button
                        className={css.switchItem}
                        onClick={() => !item.isDisabled && item.onClick()}
                        disabled={item.isDisabled}
                        key={item.title}
                    >
                        <p
                            className={cn([
                                typography({
                                    size: 'h3',
                                    weight: 'bold',
                                    color: selected ? 'blue' : 'dark',
                                }),
                                css.switchText,
                            ])}
                        >
                            {item.title}
                        </p>
                        {item.isSelected ? (
                            <motion.div className={css.switchIndicator} layoutId={`${switchKey}-active`} />
                        ) : null}
                    </button>
                );
            })}
        </div>
    );
}
