import { Nil } from './type-utils';

export type StaticL10nID = keyof typeof l10ns;

// TODO: un-hardcode l10ns and load this from the database
const l10ns = {
    'generic_ui.cancel': { value: 'Cancel' },
    'generic_ui.save': { value: 'Save' },
    'generic_ui.add_tag': { value: 'Add Tag' },
    'generic_ui.edit_tags': { value: 'Toggle Editable' },
    'menu.user_settings': { value: 'User Settings' },
    'menu.activity_overview': { value: 'Activity Overview' },
    'menu.end_session': { value: 'End Session' },
    'menu.end_booth_session': { value: 'End Booth Session' },
    'menu.reboot': { value: 'Reboot' },
    'menu.actions': { value: 'Actions' },

    'device.kiosk': { value: 'Tablet' },
    'device.floor': { value: 'Floor Screen' },
    'axis.position.x': { value: 'Thrust' },
    'axis.position.y': { value: 'Sway' },
    'axis.position.z': { value: 'Lift' },
    'axis.position.w': { value: 'Shift' },
    'axis.angle.x': { value: 'Tilt' },
    'axis.angle.y': { value: 'Bend' },
    'axis.angle.z': { value: 'Rotation' },
    'axis.angle.w': { value: 'Angle' },
    'axis.position.x_long_name': { value: 'Thrust (X)' },
    'axis.position.y_long_name': { value: 'Sway (Y)' },
    'axis.position.z_long_name': { value: 'Lift (Z)' },
    'axis.position.w_long_name': { value: 'Shift (W)' },
    'axis.angle.x_long_name': { value: 'Tilt (X)' },
    'axis.angle.y_long_name': { value: 'Bend (Y)' },
    'axis.angle.z_long_name': { value: 'Rotation (Z)' },
    'axis.angle.w_long_name': { value: 'Angle (W)' },

    'swing_position.p1': { value: 'P1 — Setup' },
    'swing_position.p2': { value: 'P2 — Takeaway' },
    'swing_position.p3': { value: 'P3' },
    'swing_position.p4': { value: 'P4 — Top of Backswing' },
    'swing_position.p5': { value: 'P5' },
    'swing_position.p6': { value: 'P6' },
    'swing_position.p7': { value: 'P7 — Impact' },
    'swing_position.p8': { value: 'P8' },
    'swing_position.p9': { value: 'P9' },
    'swing_position.p10': { value: 'P10' },

    'category.metric.linear_displacement_from_p1': { value: 'Linear Displacement' },
    'category.metric.speed': { value: 'Speed' },
    'category.metric.linear_velocity': { value: 'Linear Velocity' },
    'category.metric.angular_position_absolute': { value: 'Angular Position' },
    'category.metric.angle': { value: 'Angle' },

    'layout_editor.title': { value: 'Layout Editor' },
    'layout_editor.save_button': { value: 'Save' },
    'layout_editor.cancel_button': { value: 'Cancel' },
    'layout_editor.clear_button': { value: 'Clear' },
    'layout_editor.create_module_button': { value: 'Create' },
    'layout_editor.sequences': { value: 'Sequences' },
    'layout_editor.key_parameter': { value: 'Key Parameter' },
    'layout_editor.panel.swing_foundations': { value: 'Swing Foundations' },
    'layout_editor.panel.module': { value: 'Custom Modules' },
    'layout_editor.panel.key': { value: 'Key Graphs' },
    'layout_editor.panel.linear_position': { value: 'Linear Position Graphs' },
    'layout_editor.panel.angular_position': { value: 'Angular Position Graphs' },
    'layout_editor.panel.linear_displacement_from_p1': { value: 'Linear Displacement Graphs' },
    'layout_editor.panel.angular_displacement_from_p1': { value: 'Angular Displacement Graphs' },
    'layout_editor.panel.linear_velocity': { value: 'Linear Velocity Graphs' },
    'layout_editor.panel.angular_velocity': { value: 'Angular Velocity Graphs' },
    'layout_editor.panel.linear_acceleration': { value: 'Linear Acceleration Graphs' },
    'layout_editor.panel.angular_acceleration': { value: 'Angular Acceleration Graphs' },
    'layout_editor.panel.angle': { value: 'Angle Graphs' },
    'layout_editor.panel.distance': { value: 'Distance Graphs' },
    'layout_editor.panel.speed': { value: 'Speed Graphs' },
    'layout_editor.panel.ratio': { value: 'Ratio Graphs' },

    'layout_editor.key_parameter.save_button': { value: 'Select' },
    'layout_editor.key_parameter.cancel_button': { value: 'Cancel' },
    'layout_editor.custom_module.save_button': { value: 'Done' },
    'layout_editor.custom_module.cancel_button': { value: 'Discard Changes' },
    'layout_editor.custom_module.add_button': { value: 'Add' },

    'layout_list_editor.title': { value: 'Select Layout' },
    'layout_list_editor.create_button': { value: 'New Layout' },
    'layout_list_editor.layout_name_placeholder': { value: 'Layout Name' },
    'layout_list_editor.confirm_create_button': { value: 'Save' }, // ←————————— Different in other languages
    'layout_list_editor.confirm_name_change_button': { value: 'Save' }, // ←——————↵
    'layout_list_editor.cancel_create_button': { value: 'Cancel' },
    'layout_list_editor.cancel_name_change_button': { value: 'Discard' },
    'layout_list_editor.delete_are_you_sure': { value: 'Are you sure you want to delete {layoutName}?' },
    'layout_list_editor.save_active_changes_are_you_sure': { value: 'Save changes to {layoutName}?' },

    'swing_foundations_editor.title': { value: 'Swing Foundations: Parameter Selection' },
    'swing_foundations_editor.select_all_button': { value: 'Select all' },
    'swing_foundations_editor.clear_all_button': { value: 'Clear all' },

    'activity_navigation.header': { value: 'Activity' },
    'activity_navigation.subheader': { value: 'Swings' },
    'activity_navigation.menu_label': { value: 'Actions' },
    'activity_navigation.item_mark_as_favorite': { value: 'Favorite' },
    'activity_navigation.item_remove_as_favorite': { value: 'Remove favorite' },
    'activity_navigation.item_mark_as_comparison': { value: 'Comparison' },
    'activity_navigation.item_remove_as_comparison': { value: 'Remove comparison' },
    'activity_navigation.item_delete': { value: 'Delete' },
    'layout_list_editor.delete_cancel_button': { value: 'Cancel' },
    'layout_list_editor.delete_confirm_button': { value: 'Delete' },

    'camera_angle.face_on': { value: 'Face on' },
    'camera_angle.down_the_line': { value: 'Down the line' },
    'camera_angle.trail_front': { value: 'Trail Front' },
    'camera_angle.back': { value: 'Back' },

    'playback_settings.active_camera_angle': { value: 'Active camera angle' },
    'playback_settings.replay_speed': { value: 'Replay speed' },

    'graph_type.fallback': { value: 'Graph' },
    'graph_type.key_graph': { value: 'Key Graph' },
    'graph_type.time_series_graph': { value: 'Time series Graph' },

    'module_type.custom': { value: 'Custom Module' },
    'module_type.swing_foundations': { value: 'Swing Foundations' },

    'error_message.no_data_available': { value: 'No data to display' },
    'error_message.no_data_yet': { value: 'Waiting for data' },
    'error_message.no_video_available': { value: 'No video available' },
} as const;
export function localize(id: StaticL10nID | Nil): string;
export function localize(id: StaticL10nID, defaultValue: string): string;
export function localize(id: StaticL10nID | Nil, defaultValue: string | null): string | null;
export function localize(id: StaticL10nID | Nil, defaultValue: string | Nil = '') {
    return (
        (id && l10ns[id]?.value) || (defaultValue && l10ns[defaultValue as StaticL10nID]?.value) || defaultValue || id
    );
}
export const getL10n = (l10n_id: StaticL10nID) => {
    const obj = l10ns[l10n_id];
    return obj ? { l10n_id, ...obj } : { l10n_id, value: '' };
};

for (const key in l10ns)
    if (key.length > 64)
        console.error(
            `L10n key "${key}" is ${key.length} characters long, which is longer than the maximum of 64 characters enforced by the database.`,
        );
